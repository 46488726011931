// Importar componentes y estilos necesarios
import Accordion from 'react-bootstrap/Accordion'; // Importa el componente de Acordion de react-bootstrap
import Card from 'react-bootstrap/Card'; // Importa el componente de Card de react-bootstrap
import { Typography } from '@mui/material';


// Componente Funcional FormularioResponsable que recibe props
const FormularioResponsable = ({ ListError, StyleDragArea, Style, CustomToggle, FormResponsable, OnChange, Accordion }) => {
    const handleChangethelepones = (e) => {
        if (e.target.value.length < 11) {
            OnChange(e);
        }
    }
    return (
        <div style={{ backgroundColor: 'white' }}>


            <Accordion.Collapse style={{}} eventKey="0" id="panelsStayOpen-collapseOne" aria-labelledby="panelsStayOpen-headingOne" >

                <Card.Body style={{ backgroundColor: 'white', padding: '2px' }}>
                    {/* Formulario para ingresar datos del responsable */}
                    <Typography mb='10px' mt='5px' textAlign='left' className='datoscomentarios' style={{ lineHeight: 'normal' }} >
                        A través de este contacto se notidicará tu compra y cualquier cambio en tu pedido.
                        {/* <a id="btnEditar" className="btn btn-primary btnAmarillo seleccion editar" onclick="editar()" >Editar</a> */}
                    </Typography>
                    <div style={{ padding: '0px 5px', textAlign: 'left', fontSize: '16px' }} className="row inputDatos">
                        {/* Input para Nombre */}
                        <div className="col-sm-4">
                            <label htmlFor="inNombre_responsable">Nombre(s)*</label>
                            <input style={{ border: ListError.find((element) => element.item === 'Nombre').error === true ? '3px solid rgb(239, 83, 80)' : '' }} defaultValue={FormResponsable.Nombre} type="text" name='Nombre' onChange={OnChange} className="form-control " id="inNombre_responsable" placeholder="Nombre(s)" />
                            {/* {
                            ListError.find((element)=> element.item==='Nombre').error===true
                            ?<>error</>
                            :<></>
                        } */}
                        </div>
                        {/* Input para Apellido Paterno */}
                        <div className="col-sm-4">
                            <label htmlFor="inApellidoPaterno_responsable">Apellido paterno*</label>
                            <input style={{ border: ListError.find((element) => element.item === 'ApellidoPaterno').error === true ? '3px solid rgb(239, 83, 80)' : '' }} type="text" defaultValue={FormResponsable.ApellidoPaterno} name='ApellidoPaterno' onChange={OnChange} className="form-control " id="inApellidoPaterno_responsable" placeholder="Apellido paterno" />
                        </div>
                        {/* Input para Apellido Materno */}
                        <div className="col-sm-4">
                            <label htmlFor="inApellidoMaterno_responsable">Apellido materno*</label>
                            <input style={{ border: ListError.find((element) => element.item === 'ApellidoMaterno').error === true ? '3px solid rgb(239, 83, 80)' : '' }} type="text" defaultValue={FormResponsable.ApellidoMaterno} name='ApellidoMaterno' onChange={OnChange} className="form-control " id="inApellidoMaterno_responsable" placeholder="Apellido materno" />
                        </div>
                        {/* Input para Teléfono 1 */}
                        <div className="col-sm-6">
                            <label htmlFor="TelefonoUno_responsable">Teléfono*</label>
                            <StyleDragArea><input style={{ border: ListError.find((element) => element.item === 'TelefonoPrimario').error === true ? '3px solid rgb(239, 83, 80)' : '' }} maxLength={5} value={FormResponsable.TelefonoPrimario} type="number" name='TelefonoPrimario' onChange={handleChangethelepones} className="form-control " id="TelefonoUno_responsable" placeholder="Teléfono" /></StyleDragArea>
                        </div>
                        {/* Input para Teléfono 2 */}
                        <div className="col-sm-6">
                            <label htmlFor="TelefonoDos_responsable">Teléfono 2*</label>
                            <StyleDragArea><input style={{ border: ListError.find((element) => element.item === 'TelefonoSecundario').error === true ? '3px solid rgb(239, 83, 80)' : '' }} maxLength="12" value={FormResponsable.TelefonoSecundario} type="number" name='TelefonoSecundario' onChange={handleChangethelepones} className="form-control " id="TelefonoDos_responsable" placeholder="Teléfono 2" /></StyleDragArea>
                        </div>
                        {/* Input para Correo Electrónico */}
                        <div className="col-sm-6">
                            <label htmlFor="CorreoElectronico_responsable">Correo electrónico*</label>
                            <input style={{ border: ListError.find((element) => element.item === 'Email').error === true ? '3px solid rgb(239, 83, 80)' : '' }} type="text" name='Email' defaultValue={FormResponsable.Email} onChange={OnChange} className="form-control " id="CorreoElectronico_responsable" placeholder="Correo@ejemplo.com.mx" />
                        </div>
                        {/* Input para Confirmación de Correo Electrónico */}
                        <div className="col-sm-6">
                            <label htmlFor="ConfirmaCorreoElectronico_responsable">Confirma tu correo electrónico*</label>
                            <input style={{ border: ListError.find((element) => element.item === 'EmailConfirmar').error === true ? '3px solid rgb(239, 83, 80)' : '' }} type="text" defaultValue={FormResponsable.EmailConfirmar} name='EmailConfirmar' onChange={OnChange} className="form-control " id="ConfirmaCorreoElectronico_responsable" placeholder="Confirma tu correo electrónico" />
                        </div>
                        {/* Botón para guardar */}
                        <div className="col-sm-12 mt-4" style={{ textAlign: 'right' }}>
                            <div>
                                <Style>
                                    <CustomToggle eventKey="1">Guardar</CustomToggle>
                                </Style>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Accordion.Collapse>
        </div>

    );
}

export default FormularioResponsable; // Exporta el componente FormularioResponsable
