// -----------Importar Componentes de Terceros------------------
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';

// -----------Importar Componentes Personalizados------------------
import BootstrapInput from '../../components/Button_Bootstrap'
import Buttonh from '../Button';
import Grid from '@mui/material/Grid';

const Buscar = (({ Placeholder, Value, OnChange }) => {

  return (
    <Box Grid container sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <Grid container >
        <Grid xs={12} sm={8}>
          <FormControl sx={{ width: '100%'}} variant="standard">
            <BootstrapInput onChange={OnChange} type='text' name='Nombre finado' placeholder={Placeholder} defaultValue={Value} id="bootstrap-input1" />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={1}></Grid>

        <Grid sx={{display:'flex',alignItems:'center', marginTop:{xs:'15px',sm:'0px'}}} xs={12} sm={3}>
          <Buttonh FontWeight='700 !important'  Text='Buscar' Size='100%' Color='#D14081' BorderColor='1px solid #D14081' ColorHover='white' Textcolor='#ffff' TextcolorHover='#D14081' Disabled={false} />
        </Grid>
      </Grid>

    </Box>
  );
})

export default Buscar;