import React from 'react';

// Importar el icono de marca de verificación desde Material-UI
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';



// Componente funcional StepExtra que muestra productos extras y permite agregarlos al carrito
const StepExtra = ({ OnClickShowAgregarCarritoExtra, OnClickShowAgregarCarritoExtras, ListProductosExtras, ListGruposExtras, ListCarritoExtras, Style }) => {
    React.useEffect(() => {
        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        };
        scrollToTop();
    }, []);
    return (
        <Box marginBottom={{xs:'170px',sm:'0px'}}>
            {/* Sección para mostrar los productos extras */}
            {
                // Verificar si hay grupos y productos extras disponibles
                ListGruposExtras.length > 0 && ListProductosExtras.length > 0
                    ?
                    // Si hay productos extras, mapear los grupos y mostrar los productos dentro de cada grupo
                    ListGruposExtras.map((Grupo, index) => (
                        <div key={index} style={{ height: '100%' }} className='row'>
                            {/* Título del grupo */}
                            <div className="col-sm-12 ">
                                {/* Título del grupo y el icono de marca de verificación si el grupo ha sido seleccionado */}
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                    <h3 style={{ fontSize: '24px', margin: '0px' }} className="subtitulo">{Grupo.nombre_grupo}</h3>
                                    <CheckCircleIcon sx={{ fontSize:'20px', color: '#b1366e', display: Grupo.select === true ? 'block' : 'none' }} />
                                </div>
                            </div>
                            {/* Mapear y mostrar los productos dentro del grupo actual */}
                            {
                                ListProductosExtras.filter((element) => (element.grupo === Grupo.grupo)).map((item) => (
                                    <div key={item.producto} style={{ marginBottom: '10px' }} className="col-sm-4" id={"div_" + item.producto}>
                                        <div style={{ height: '100%', display: 'flex', flexDirection: 'column', }}>
                                            {/* Imagen del producto */}
                                            <div style={{ cursor: 'pointer', flex: '0 0 auto' }} onClick={() => (OnClickShowAgregarCarritoExtra(item))} className={ListCarritoExtras.find(element => element.producto === item.producto) && Grupo.select === true ? "col-sm-12 mb-3 " : "col-sm-12 selectimg mb-3 "}>
                                                <img style={{objectFit:'cover'}} id={"img_" + item.producto} src={item.img[0]} className={ListCarritoExtras.find(element => element.producto === item.producto) && Grupo.select === true ? ' seleccionado  ImgExtras' : " ImgExtras "} alt={item.nombre} />
                                            </div>
                                            {/* Botón para agregar el producto al carrito */}
                                            <div style={{ marginTop: '10px', flex: '1' }} className="col-sm-12 text-center">
                                                <Style style={{ height: '100%' }}>
                                                    <Button sx={{ height: '100% !important', textTransform: 'none' }} className={ListCarritoExtras.find(element => element.producto === item.producto) && Grupo.select === true ? 'btn btnAmarilloChicos g' : "btn btnNoSeleccionado g"} id={"btn_" + item} onClick={() => (OnClickShowAgregarCarritoExtra(item))}>{item.nombre}</Button>
                                                </Style>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    ))
                    // Si no hay productos extras disponibles, no muestra nada
                    : <></>
            }
        </Box>
    );
}

// Exportar el componente StepExtra
export default StepExtra;
