import React from 'react';

const SelectCompartirRedes = ({ OnClickCompartirSeguimiento, Url, Host }) => {
  // Función para copiar el contenido del Host al portapapeles
  const copiarContenido = async () => {
    try {
      await navigator.clipboard.writeText(Host);
      console.log('Contenido copiado al portapapeles');
    } catch (err) {
      console.error('Error al copiar: ', err);
    }
  };

  // Función para compartir enlaces en redes sociales
  const shareLink = (platform) => {
    // Generar la URL de compartir concatenando la plataforma y el contenido del Host
    const shareUrl = `https://${platform}?text=${Host}`;
    // Llamar a la función de manejo de clic con la URL generada
    OnClickCompartirSeguimiento(shareUrl);
  };

  return (
    <>
      {/* Input para mostrar el Host */}
      <input value={Host} className="form-control" id="name" type="text" style={{ border: 'none' }} placeholder="www.columbafunerales.com.mx/obituario(nombrefinado)" disabled="disabled" />
      {/* Sección de botones para compartir en redes sociales */}
      <div className=" text-center" style={{ width:'100%', display: 'flex', justifyContent: 'space-around', fontSize: '9px', fontWeight: '500', margin:'0px !important' }}>
        {/* Botón para compartir en Facebook */}
        <div onClick={() => shareLink('facebook.com/sharer/sharer.php?u=')} className="col-2 pt-0" >
          <div className="sharefb mt-2 "></div>
          Facebook
        </div>
        {/* Botón para compartir en Twitter */}
        <div onClick={() => shareLink('twitter.com/intent/tweet?text=')} className="col-2 pt-0 " >
          <div className="sharetw mt-2 "></div>
          Twitter
        </div>
        {/* Botón para compartir en Whatsapp */}
        <div onClick={() => shareLink('web.whatsapp.com/send')} className="col-2  pt-0" >
          <div className="sharewhatsapp mt-2 "></div>
          Whatsapp
        </div>
        {/* Botón para copiar el contenido */}
        <div onClick={copiarContenido} className="col-2  pt-0" >
          <div className="sharecopiar mt-2"></div>
          Copiar
        </div>
      </div>
    </>
  );
};

export default SelectCompartirRedes;
