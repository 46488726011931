import styled from "styled-components";

const StyleDragArea = styled.div`

  
.card {
  height: 100%;
  border-radius: 15px;
  border-color: white;
}
.card-header {
  background-color: #ffec00 !important;
  padding: 22px;
  color: black;
  text-align: center;
  border: 0px;
}
.card-body {
  text-align: center;
}
.card-footer {
  text-align: center;
  border: 0px;
  background-color: white;
}
.card,
.card-footer {
  position: relative;
  overflow: hidden;
  background-color: transparent;
  transition: background-color 0.5s ease-in-out;
}

.card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background: linear-gradient( to bottom, #ffec00 0%, #ffec00  100% ) ;
  z-index: -1;
  transition: height 0.3s;
}

.card:hover::before {
  height: 100%;
}

.card:hover {
  background-color: transparent;
}

.btn_rosa {
  border-color: #D14081 ;
  background-color: #D14081;
  color: white;
}
.btn_rosa:hover {
  background-color: white;
  border: #d14081 1px solid;
  color: #d14081;

}

.btn_rosa:focus{
  color: white;
    background-color: #932C59;
    box-shadow: 0 0 0 1px rgb(255 39 136);
}

.set{
  font-weight: 900 !important;
  color: #374957 !important !important;
}
.subtitulo{
  color: #b0427e !important !important;
  font-size: 30px;
  font-weight: 900 !important;

}
.titulo{
  color: #b0427e !important !important;
  font-size: 30px;
  font-weight: 900 !important;
}
`;

export default StyleDragArea;