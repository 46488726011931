import React, { useState } from 'react';

// -----------Importar Componentes de Terceros------------------
import Carousel from 'better-react-carousel'
import Container from '@mui/material/Container';
import ImageListItem from '@mui/material/ImageListItem';
import CarouselElemento from './Carousel_Element';

// -----------Importar Archivos Estaticos------------------
import Flecha_Derecha from '../../assets/Imagenes/Flecha_Derecha.png'
import Flecha_Izquierda from '../../assets/Imagenes/Flecha_Izquierda.png'
import Agregar_Pasiva from '../../assets/Imagenes/Nueva_Pasiva.png'
import Agregar_Activa from '../../assets/Imagenes/Nueva_Activa.png'

import './styles.css'

const CarouselImagenes = (({ OnClickNueva, Columnas, Filas, Espacio, ContenedorWidth, ListImagen, OnClickNuevaMemoria, DataNuevaMemoria, Result, Search }) => {
    const [Active, setActive] = useState(true);


    const MyDot = ({ isActive }) => (
        <span
            style={{
                marginTop: '40px',
                display: 'inline-block',
                borderRadius: '50%',
                height: isActive ? '15px' : '8px',
                width: isActive ? '15px' : '8px',
                background: isActive ? '#F50087' : 'grey'
            }}
        ></span>
    )

    const MyArrowRight = () => (
        <span
        className='MyArrowRight'
            style={{
                position: 'absolute',
                top: `calc(50% - 15px)`,
                zIndex: '10',

                height: '30px',
                width: '30px',
                background: '#D14081',
                borderRadius: ' 50%',

                cursor: 'pointer',
                fontSize: '10px',
                WebkitTransition: 'opacity 1s',
                transition: 'opacity 1s',
                left: 'initial',
                right: '-20px',
            }}
        ><img className='ArrowRight' style={{ marginLeft: '35%', marginTop: '20%' }} width="10px" alt='MyArrowRight' src={Flecha_Derecha} /></span>
    )

    const MyArrowLeft = () => (
        <span className='MyArrowLeft'
            style={{
                position: 'absolute',
                top: `calc(50% - 15px)`,
                zIndex: '10',
                height: '30px',
                width: '30px',
                background: '#D14081',
                borderRadius: ' 50%',
                marginRight: '0%',
                cursor: 'pointer',
                fontSize: '10px',
                WebkitTransition: 'opacity 0.25s',
                transition: 'opacity 0.25s',

                left: '-20px',
                right: ' initial',
            }}
        ><img className='ArrowLeft' style={{ paddingTop: '15%', marginLeft: '20%', marginTop: '10%' }} width="15px" alt='MyArrowLeft' src={Flecha_Izquierda} /></span>
    )

    return (
        <Container maxWidth={ContenedorWidth}>
            <Carousel arrowLeft={MyArrowLeft} arrowRight={MyArrowRight} dot={MyDot} cols={Columnas} rows={Filas} gap={Espacio} loop={true} scrollSnap showDots mobileBreakpoint={150} responsiveLayout={[
                {
                    breakpoint: 1300,
                    cols: 5,
                    rows: 2,
                    gap: 10,
                    loop: true,
                },
                {
                    breakpoint: 1100,
                    cols: 4,
                    rows: 2,
                    gap: 10,
                    loop: true,
                },
                {
                    breakpoint: 850,
                    cols: 3,
                    rows: 2,
                    gap: 10,
                    loop: true,
                }, {
                    breakpoint: 450,
                    cols: 2,

                    rows: 3,
                    gap: 10,
                    loop: true,
                },

            ]} >
                <Carousel.Item onClick={OnClickNueva} >
                    <div style={{ cursor: 'pointer' }}>
                        <ImageListItem sx={{ margin: '0px' }} >
                            {
                                Active === true
                                    ? <img onClick={OnClickNueva} onMouseOver={() => { setActive(false); }} width="100%" style={{ aspectRatio: '1' }} alt='Agregar Pasiva' src={Agregar_Pasiva} />

                                    : <img onClick={OnClickNueva} onMouseOut={() => { setActive(true); }} width="100%" style={{ aspectRatio: '1' }} alt='Agregar Activa' src={Agregar_Activa} />
                            }
                        </ImageListItem>
                    </div>
                </Carousel.Item>
                {
                    ListImagen.filter(item => item.Caratula[Search].toLowerCase()
                        .indexOf(Result.toLowerCase()) > -1).map((imagen, index) => (

                            <Carousel.Item onClick={() => (OnClickNuevaMemoria(Object.assign(DataNuevaMemoria, { Folio: imagen.Conmemoracion.folio })))} DataNuevaMemoria={DataNuevaMemoria} key={index} >
                                <CarouselElemento OnClick={OnClickNuevaMemoria} DataNuevaMemoria={DataNuevaMemoria} Contenido={imagen} />
                            </Carousel.Item>
                        ))
                }
            </Carousel>
        </Container>
    );
})

export default CarouselImagenes