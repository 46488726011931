import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import Formulario from '../../assets/Imagenes/fi-rr-form.png'
import Copia from '../../assets/Imagenes/fi-rr-copy.png'
import call  from '../../assets/Imagenes/Call.png'
import Receipt from '../../assets/Imagenes/Receipt.png'



const InstruccionPagoTransferencia = () =>{
    return (
        <Box>
            <Box sx={{marginTop:'20px',display: 'flex',flexDirection:'row', alignItems: 'center' }}>
                <img src={Copia} height='35px' alt='shop'/> <Typography color='#3A3A3A' fontSize='16px' fontFamily='Itaca' display="inline" sx={{marginLeft:'15px'}}>Copia la  <Typography fontSize='16px' fontFamily='Itaca' display="inline" sx={{color:'#D14081'}}>  clabe interbancaria desde el botón </Typography> que se encuentra a un lado de la misma. </Typography>
            </Box>
            <Box sx={{marginTop:'20px',display: 'flex',flexDirection:'row', alignItems: 'flex-start;' }}>
                <img src={Formulario} height='35px' style={{marginTop:'5px'}}  alt='Barra'/> 
                <Typography fontSize='16px' color='#3A3A3A' fontFamily='Itaca' display="inline"  sx={{marginLeft:'15px'}}>
                    Llena el <Typography fontSize='16px' fontFamily='Itaca' display="inline" sx={{color:'#D14081'}}>formulario </Typography>con los siguientes datos:
                    <ul style={{margin:'0px 30px'}}>
                        <li>Nombre del destinatario: <Typography fontSize='16px' fontFamily='Itaca' display="inline" sx={{color:'#D14081'}}>“COLUMBA funerales”.</Typography> </li>
                        <li>CLABE</li>
                        <li>En concepto coloque el nombre del finado <Typography fontSize='16px' color='#3A3A3A' fontFamily='Itaca'>Ej. Concepto: José Luis N</Typography> </li>
                    </ul>
                </Typography>
            </Box>
            <Box sx={{marginTop:'20px',display: 'flex',flexDirection:'row', alignItems: 'center' }}>
                <img src={call} height='35px' alt='call'/> <Typography fontSize='16px' color='#3A3A3A' fontFamily='Itaca' display="inline"  sx={{marginLeft:'15px'}}>Para iniciar con el servicio, envía el comprobante al <Typography fontSize='16px' fontFamily='Itaca' display="inline" sx={{color:'#D14081'}}>000-000-0000.</Typography> </Typography>
            </Box>
            <Box sx={{marginTop:'20px',display: 'flex',flexDirection:'row', alignItems: 'center' }}>
                <img src={Receipt} height='35px' alt='Receipt'/> <Typography fontSize='16px' color='#3A3A3A' fontFamily='Itaca' display="inline" sx={{marginLeft:'15px'}}>El comprobante se enviará <Typography fontSize='16px' fontFamily='Itaca' display="inline" sx={{color:'#D14081'}}> al correo registrado.</Typography></Typography>
            </Box>
        </Box>
        
    );
}

export default InstruccionPagoTransferencia;