import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ImageListItem from '@mui/material/ImageListItem';

// Importar Componentes de Terceros
import PaymentsIcon from '@mui/icons-material/Payments';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CompareArrowsSharpIcon from '@mui/icons-material/CompareArrowsSharp';

import ImgHeader from '../../assets/Imagenes/Sets/Email_Confirmacion/header_Email_Confirmacion.png'
import ImgFooter from '../../assets/Imagenes/Sets/Email_Confirmacion/foother_Email_Confirmacion.png'
import ImgRedes from '../../assets/Imagenes/Sets/Email_Confirmacion/redes.png'






const EmailConfirmacion = ({ OnLoad, Estado, Municipio, Colonia, FormFinado, FormPago, ListCarritoExtras, ListProductos, ListCarrito, TotalServicioDespedida, SetNombreSeleccionado, ListTypo, TipoDespedida, SubTotalExtra, SubTotal, formatearComoMonedaMXN, CallNumber, Fecha, FolioPedidoCliente, NombreCliente, TotalPago, handleClick, Step_Pago, Step_Extra, Step_PersonalizaSet, FormActualizar }) => {


  let FechaDataTime = new Date(Fecha);

  const TipoPago = () => {
   
      return (
        <Box  sx={{ borderRadius: '5px', backgroundColor: '#FFEC00', width: '170px', padding: '2px', display: 'flex', justifyContent: 'space-evenly', alignItems:'center'}}>
          {
            FormPago.Tipo === '0'
              ?
              <>
                <CreditCardIcon sx={{ color: '#374957' }} />
                <Typography fontWeight= '700 !important'  fontSize='18px' color='#374957'>Crédito / Débito</Typography>
              </>
              :
              FormPago.Tipo === '1'
                ?
                <>
                  <PaymentsIcon sx={{ color: '#374957' }} />
                  <Typography fontWeight= '700 !important' fontSize='18px' color='#374957'>Efectivo</Typography>
                </>
                :
                FormPago.Tipo === '2'
                  ?
                  <>
                    <CompareArrowsSharpIcon sx={{ color: '#374957' }} />
                    <Typography fontWeight= '700 !important' fontSize='18px' color='#374957'>Trasferencia</Typography>
                  </>
                  :
                  <Typography fontWeight= '700 !important' fontSize='18px' color='#374957'>Tipo de pago no válido</Typography>
          }
        </Box>
      );
    
  
  }

  console.log(FormPago);

  return (
    <div id='imagen2' style={{ marginTop: '150px', width: '900px', height: '1222px' }}>
      <Box sx={{ height: '104px', width: '100%', backgroundColor: '#ffec00' }}>
        <ImageListItem>
          <img alt="imagen fondo" src={ImgHeader} />
          <Box sx={{ position: 'absolute', height: '100%', width: '100%', zIndex: 21, top: '0%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }} >
            <Box sx={{}}>
              <Box marginLeft='45%' marginRight='30px'>
                <Typography mt='1rem' mb='0rem' variant="h2" textAlign='right' sx={{ fontWeight: '600 !important', fontSize: '26px', color: '#D14081' }} className="textMarquesina">Confirmación de pago</Typography>
                <Typography mt='0rem' mb='1rem' variant="h2" textAlign='right' sx={{ fontWeight: '400 !important', fontSize: '20px', color: '#3F3F3F' }} className="textMarquesina">Número de folio #{FolioPedidoCliente} </Typography>
              </Box>
            </Box>
          </Box>
        </ImageListItem>
      </Box>


      <Box sx={{ width: '100%', height: '1042px' }} >

        <Box sx={{ display: 'flex', margin: '20px 30px 10px 30px' }}  >
          <Typography variant='h4' mr='3px' sx={{ color: '#777777', fontSize: '22px', fontWeight: '500 !important' }} >Hola</Typography>
          <Typography variant='h4' sx={{ color: '#D14081', fontSize: '25px', fontWeight: '500 !important' }} >@{NombreCliente} </Typography>
        </Box>
        <Box sx={{ margin: '0px 30px', width: '75%' }}>
          <Typography fontSize='18px' color="#777777" lineHeight={1}>
            Gracias por confiar en nosotros. Tu pago se ha confirmado, los detalles de tu
            pedido se indican a continuación.
          </Typography>
        </Box>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ margin: '40px 7.5px 0px 30px', width: '50%' }}>
            <Box sx={{ height: '100px', border: '3px solid  #FFEC00', borderRadius: '15px'}}>
              <Box margin='10px 10px 0px 10px' display='flex' justifyContent='space-between' alignItems='center' >
                <Typography variant='h4' sx={{ color: '#D14081', fontSize: '22px', fontWeight: '400 !important' }} >Método de pago</Typography>
                <TipoPago />
              </Box>
              <Box margin='10px 10px 0px 10px' display='flex' justifyContent='space-between'>
                <Typography variant='h4' sx={{ color: '#D14081', fontSize: '22px', fontWeight: '400 !important' }} >Total pagado:</Typography>
                <Typography variant='h4' sx={{ color: '#777777', fontSize: '22px', fontWeight: '400 !important' }} >{formatearComoMonedaMXN(TotalPago)}</Typography>

              </Box>
            </Box>
            <Box mt='15px' sx={{ border: '3px solid #D14081', borderRadius: '15px' }}>
              <Box mt='10px' ml='10px'>
                <Typography variant='h4' sx={{ color: '#D14081', fontSize: '22px', fontWeight: '400 !important' }} > Datos del comprador</Typography>
              </Box>
              <Box mt='3px' ml='10px' >
                <Typography variant='h5' sx={{ color: '#777777', fontSize: '18px', fontWeight: '400 !important' }}>Nombre: {NombreCliente}</Typography>
              </Box>
              <Box mt='3px' ml='10px' >
                <Typography variant='h5' sx={{ color: '#777777', fontSize: '18px', fontWeight: '400 !important' }}>Calle: {FormFinado.Referencias} </Typography>
                
              </Box>
              <Box mt='3px' ml='10px' sx={{ display: 'flex' }} >
                <Typography variant='h5' sx={{ color: '#777777', fontSize: '18px', fontWeight: '400 !important' }}>Ciudad: {Municipio.nombre}</Typography>
                <Typography ml='10px' variant='h5' sx={{ color: '#777777', fontSize: '18px', fontWeight: '400 !important' }}>Estado: {Estado.nombre}</Typography>
              </Box>
              <Box mt='3px' ml='10px' display='flex'>
                <Typography variant='h5' sx={{ color: '#777777', fontSize: '18px', fontWeight: '400 !important' }}>Colonia: {Colonia.nombre} </Typography>
                <Typography ml='10px' variant='h5' sx={{ color: '#777777', fontSize: '18px', fontWeight: '400 !important' }}>CP: {FormFinado.CodigoPostal}</Typography>
              </Box>
              {/*<Box mt='3px' ml='10px' display='flex'>
                <Typography variant='h5' sx={{ color: '#777777', fontSize: '18px', fontWeight: '400 !important' }}>Factura: </Typography>
                <Typography ml='10px' variant='h5' sx={{ color: '#777777', fontSize: '18px', fontWeight: '400 !important' }}>                00000000000000000000                </Typography>
              </Box>*/}
              <Box mt='3px' mb='10px' ml='10px' display='flex'>
                <Typography variant='h5' sx={{ color: '#777777', fontSize: '18px', fontWeight: '400 !important' }}>Fecha de emisión: {FechaDataTime.getDate()}/{FechaDataTime.getMonth() + 1}/{FechaDataTime.getFullYear()} </Typography>
              </Box> 
            </Box>


          </Box>
          <Box sx={{ margin: '40px 30px 0px 7.5px', width: '50%' }}>
            <Box sx={{ border: '3px solid #D14081', borderRadius: '15px', padding: '10px 15px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box display='flex'>
                  <Typography mr={2} fontSize='18px' display="inline" sx={{ color: '#777777', fontWeight: 'bold !important' }}>Servicio:</Typography>
                  <Box width='60%' >
                    <Typography fontSize='18px' lineHeight={1} width='100%' display="flex" sx={{ color: '#D14081', fontWeight: 'bold !important' }}>{SetNombreSeleccionado}, {ListTypo.find((element) => (element.id === TipoDespedida)).Nombre}</Typography>
                  </Box>
                </Box>
                <Typography fontSize='18px' display="inline" sx={{ fontWeight: 'bold !important' }}>{formatearComoMonedaMXN(TotalServicioDespedida)}</Typography>
              </Box>
              <Box >
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography fontSize='18px' width='100%' display="flex" sx={{ color: '#777777', fontWeight: 'bold !important' }}>Productos</Typography>
                  <Typography fontSize='18px' display="inline" sx={{ fontWeight: 'bold !important' }}>{formatearComoMonedaMXN(SubTotal)}</Typography>
                </Box>

                <Box>
                  <ul>
                    {
                      ListCarrito.map((element, index) => {
                        let cantidadASumar = 0;
                        const productoEncontrado = ListProductos.find((producto) => (producto.producto === element.producto && producto.grupo === element.grupo));
                        if (productoEncontrado && productoEncontrado.valor_default === 1) {
                        } else {
                          const productosEnMismoGrupo = ListProductos.filter((producto) => (producto.grupo === element.grupo && producto.valor_default === 1));
                          cantidadASumar = element.precio_venta - productosEnMismoGrupo[0].precio_venta;
                        }
                        return (
                          <li style={{ margin: '0px 0px 0px 30px' }} key={index}>
                            <div div className='col-md-12' style={{ margin: '0% 0%', display: 'flex', justifyContent: 'space-between' }} key={index}>
                              <Typography className={cantidadASumar !== 0 ? "c_rosa" : "c_negro"} display="inline" sx={{ fontSize: '14px' }}> {element.nombre_prodcto}</Typography>   <Typography className={cantidadASumar !== 0 ? "c_rosa" : "c_negro"} display="inline" sx={{ fontSize: '14px' }}>{formatearComoMonedaMXN(cantidadASumar !== 0 ? cantidadASumar : 0)}</Typography>
                            </div>
                          </li>

                        )
                      })
                    }
                  </ul>
                </Box>
              </Box>
              <Box >
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography fontSize='18px' width='100%' display="flex" sx={{ color: '#777777', fontWeight: 'bold !important' }}>Extras</Typography>
                  <Typography fontSize='18px' display="inline" sx={{ fontWeight: 'bold !important' }}>{formatearComoMonedaMXN(SubTotalExtra)}</Typography>
                </Box>
                <Box>
                  <ul>
                    {
                      ListCarritoExtras.map((element, index) => (
                        <li style={{ margin: '0px 0px 0px 30px' }} key={index}>
                          <div className='col-md-12' style={{ margin: '0% 0%', display: 'flex', justifyContent: 'space-between' }} key={index}>
                            <Typography display="inline" sx={{ fontSize: '14px' }}>{element.cantidad}  {element.nombre_prodcto}</Typography>   <Typography display="inline" sx={{ fontSize: '14px' }}>{formatearComoMonedaMXN(element.precio_venta * element.cantidad)}</Typography>
                          </div>
                        </li>

                      ))
                    }
                  </ul>
                </Box>
              </Box>
              <Box >
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography fontSize='18px' width='100%' display="flex" sx={{ color: '#777777', fontWeight: 'bold !important' }}>Servicios</Typography>
                  <Typography fontSize='18px' display="inline" sx={{ fontWeight: 'bold !important' }}>{formatearComoMonedaMXN(0)}</Typography>
                </Box>
                <Box>
                  <ul>
                    <li style={{ margin: '0px 0px 0px 30px' }}>
                      <div div className='col-md-12' style={{ margin: '0% 0%', display: 'flex', justifyContent: 'space-between' }} >
                        <Typography className="c_negro" display="inline" sx={{ fontSize: '14px' }}> Traslado de 30 km redondos</Typography>   <Typography className="c_negro" display="inline" sx={{ fontSize: '14px' }}>{formatearComoMonedaMXN(0)}</Typography>
                      </div>
                    </li>
                    <li style={{ margin: '0px 0px 0px 30px' }}>
                      <div div className='col-md-12' style={{ margin: '0% 0%', display: 'flex', justifyContent: 'space-between' }} >
                        <Typography className="c_negro" display="inline" sx={{ fontSize: '14px' }}> Trámites legales</Typography>   <Typography className="c_negro" display="inline" sx={{ fontSize: '14px' }}>{formatearComoMonedaMXN(0)}</Typography>
                      </div>
                    </li>
                    <li style={{ margin: '0px 0px 0px 30px' }}>
                      <div div className='col-md-12' style={{ margin: '0% 0%', display: 'flex', justifyContent: 'space-between' }} >
                        <Typography className="c_negro" display="inline" sx={{ fontSize: '14px' }}>Preservación</Typography>   <Typography className="c_negro" display="inline" sx={{ fontSize: '14px' }}>{formatearComoMonedaMXN(0)}</Typography>
                      </div>
                    </li>
                    <li style={{ margin: '0px 0px 0px 30px' }}>
                      <div div className='col-md-12' style={{ margin: '0% 0%', display: 'flex', justifyContent: 'space-between' }} >
                        <Typography className="c_negro" display="inline" sx={{ fontSize: '14px' }}> Conmemoración</Typography>   <Typography className="c_negro" display="inline" sx={{ fontSize: '14px' }}>{formatearComoMonedaMXN(0)}</Typography>
                      </div>
                    </li>
                    <li style={{ margin: '0px 0px 0px 30px' }}>
                      <div div className='col-md-12' style={{ margin: '0% 0%', display: 'flex', justifyContent: 'space-between' }} >
                        <Typography className="c_negro" display="inline" sx={{ fontSize: '14px' }}> Cremación</Typography>   <Typography className="c_negro" display="inline" sx={{ fontSize: '14px' }}>{formatearComoMonedaMXN(0)}</Typography>
                      </div>
                    </li>

                  </ul>
                </Box>
              </Box>
              <Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography fontSize='18px' width='100%' display="flex" sx={{ color: '#777777', fontWeight: 'bold !important' }}>Descuentos</Typography>
                  <Typography fontSize='18px' display="inline" sx={{ fontWeight: 'bold !important' }}>{formatearComoMonedaMXN(FormPago.Descuento)}</Typography>
                </Box>
                <Box>


                  {
                    FormPago.FolioCupon === ''
                      ? <></>
                      :
                      <ul>
                        <li style={{ margin: '0px 0px 0px 30px' }}>
                          <div div className='col-md-12' style={{ margin: '0% 0%', display: 'flex', justifyContent: 'space-between' }} >
                            <Typography className="c_rosa" display="inline" sx={{ fontSize: '12px' }}>{FormPago.FolioCupon}</Typography>   <Typography className="c_negro" display="inline" sx={{ fontSize: '12px' }}>-{formatearComoMonedaMXN(FormPago.Descuento)}</Typography>
                          </div>
                        </li>
                      </ul>
                  }
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box mb='10px' mt='35px' textAlign='center' >
          <Typography variant='h5' sx={{ color: '#D14081', fontSize: '22px', fontWeight: '700 !important' }} >
            Este correo electrónico te servirá como recibo.</Typography>
          <Typography color='#777777' fontSize='18px' display='inline'>
            Esta notificación fue enviada al correo electrónico ingresado en <Typography display='inline' color='#D14081'>Funeralescolumba.com.mx</Typography>
          </Typography>
          <Typography color='#777777' fontSize='18px'>
            Si necesitas alguna aclaración de dudas o servicio al cliente:
          </Typography>
          <Typography color='#D14081' fontSize='20px'>
            {CallNumber}
          </Typography>
        </Box>
        <Box textAlign='center' color='#777777' fontSize='18px'>
          <Typography mb={1}>Síguenos en nuestras redes sociales</Typography>
          <img width='20%' src={ImgRedes} onLoad={OnLoad} />
        </Box>
        <Box mt='20px' lineHeight={1} textAlign='center' >
          <Typography fontSize='11px' fontWeight='600 !important' >Este correo electrónico se genera automáticamente</Typography>
          <Typography fontSize='11px' fontWeight='600 !important'>Por favor, no respondas.</Typography>
        </Box>

      </Box>


      <Box sx={{ height: '56px', width: '100%', backgroundColor: '#ffec00' }}>
        <ImageListItem>
          <img alt="imagen fondo" src={ImgFooter} />
          <Box sx={{ position: 'absolute', height: '100%', width: '100%', zIndex: 21, top: '0%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }} >
            <Box sx={{}}>
              <Box marginLeft='50%' marginRight='15px' sx={{ display: 'flex', flexWrap: 'flex', justifyContent: 'space-between' }}>
                <Typography sx={{ fontSize: '14px', color: '#404040', fontFamily: 'Itaca ' }}  >
                  Ayuda |
                </Typography>

                <Typography sx={{ fontSize: '14px', color: '#404040', fontFamily: 'Itaca ' }}  >
                  FAQ |
                </Typography>

                <Typography sx={{ fontSize: '14px', color: '#404040', fontFamily: 'Itaca ' }}  >
                  Aviso de Privacidad |
                </Typography>

                <Typography sx={{ fontSize: '14px', color: '#404040', fontFamily: 'Itaca ' }}  >
                  Términos y condiciones |
                </Typography>

              </Box>
            </Box>
          </Box>
        </ImageListItem>
      </Box>
    </div>
  );
};

export default EmailConfirmacion;