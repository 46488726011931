// Importación de la función useNavigate desde react-router-dom para la navegación en la aplicación.
import { useNavigate } from 'react-router-dom'

// Importación de componentes de terceros desde Material-UI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Importación del servicio TodoDataService desde la ruta '../../hooks/services'
import TodoDataService from '../../hooks/services';

// Importación de imágenes y otros recursos
import Mask from '../../assets/Imagenes/Mask group.png';

import LogoFooter from '../../assets/Imagenes/LogoFooter.png';

// Definición de temas personalizados para estilos
let theme = createTheme();
let theme2 = createTheme();
let theme3 = createTheme();

// Configuración del tema 'theme' para el cuerpo del texto
theme.typography.body1 = {
  fontSize: '14px',
  fontWeight: '600 !important',
  '@media (max-width:1080px)': {
    fontSize: '12px',
    fontWeight: '600 !important',
  },
  '@media (max-width:920px)': {
    fontSize: '9px',
    fontWeight: '600 !important',
  },
  '@media (max-width:600px)': {
    fontSize: '6px',
    fontWeight: '600 !important',
  }
};

// Configuración del tema 'theme2' para el cuerpo del texto
theme2.typography.body2 = {
  fontSize: '18px',
  '@media (max-width:1600px)': {
    fontSize: '16px',
  },
  '@media (max-width:1300px)': {
    fontSize: '14px',
  },
  '@media (max-width:1200px)': {
    fontSize: '12px',
  },
  '@media (max-width:1000px)': {
    fontSize: '8px',
  },
  '@media (max-width:800px)': {
    fontSize: '6px',
  }
};

// Configuración del tema 'theme3' para el cuerpo del texto
theme3.typography.body1 = {
  fontSize: '20px',
  fontFamily: 'Itaca ',
  '@media (max-width:1660px)': {
    fontSize: '18px',
  },
  '@media (max-width:1545px)': {
    fontSize: '16px',
  },
  '@media (max-width:1420px)': {
    fontSize: '14px',
  },
  '@media (max-width:1310px)': {
    fontSize: '12px',
  },
  '@media (max-width:1190px)': {
    fontSize: '10px',
  },
  '@media (max-width:1080px)': {
    fontSize: '8px',
  },
  '@media (max-width:960px)': {
    fontSize: '6px',
  }
};

// Componente funcional FooterColumba
const FooterColumba = ({ ListLink, HideFoother }) => {
  // Uso del hook useNavigate para la navegación
  const navigate = useNavigate();
  
  // Función para manejar el clic en los enlaces
  const handleClick = (link) => {
    navigate(link);
  };

  // Renderización del componente
  return (
    <Box sx={{  display: { xs: HideFoother ? 'none' : 'block', sm: 'block' } }}>
      <Box sx={{ width: '100%', marginTop: '0%', backgroundColor: '#D14081', padding: '0px', display: 'flex', justifyConten: 'center' }}>
        <Box sx={{ width: { xs: '20%', md: '30%' }, backgroundColor: '#D14081', padding: '0px', margin: '0px' }}>
          <Box sx={{ height: '90%', display: 'grid', justifyContent: 'flex-end', alignContent: 'center' }}>
            <Button disableRipple onClick={() => (handleClick('/'))} sx={{ "&.MuiButtonBase-root:hover": { bgcolor: "transparent" } }}>
              <img height='95%' width='95%' src={LogoFooter} alt='Paloma' />
            </Button>
          </Box>
          <Box sx={{ height: '10%', display: 'flex', justifyContent: 'flex-end' }}>
            <Typography variant='body1' sx={{ bottom: { md: '0%', lg: '5%', xl: '10%' }, fontWeight: '600 ! important', fontSize: { xs: '4.5px', sm: '8px', md: '12px' }, paddingRight: '1%', color: '#F5F5F5', fontFamily: 'Itaca ' }}>
              Columba Derechos Reservados
            </Typography>
          </Box>
        </Box>
        <Box sx={{ padding: '0px', margin: '0px', display: 'flex', flexDirection: 'row-reverse', width: { xs: '80%', md: '55%' } }}>
          <ImageListItem sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px' }}>
            <img style={{ top: '0px' }} alt='Marca' src={Mask}></img>
            <Box sx={{ position: 'absolute', height: '100%', marginLeft: { xs: '35%', sm: '40%' }, width: '60%', bottom: '0%', zIndex: 23 }}>
              <Box sx={{ height: '25%', width: '100%' }}>
                <ThemeProvider theme={theme2}>
                  <Typography variant='body2' sx={{ fontWeight: '600 !important', paddingTop: {xs:'1%',sm:'5%'}, paddingRight: '15%', color: '#404040', fontFamily: 'Itaca ' }}>
                    Nuestra área de <Typography display="inline" variant='body2' sx={{ color: '#D14081', fontWeight: '600 !important' }}>COBERTURA GRATUITA</Typography> Incluye 50 km a la redonda.
                  </Typography>
                </ThemeProvider>
              </Box>
              <Box sx={{ height: '50%', width: '100%', display: 'grid', alignContent: 'center' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0%' }}>
                  <Button disableRipple onClick={() => (window.open('https://maps.app.goo.gl/sjDoKKnv3VzwjghC7', "_blank"))} startIcon={<RoomOutlinedIcon sx={{ fontSize: { xs: '11px !important', sm: '20px !important' } }} />} sx={{border:'2px solid black !important', textTransform: 'none', "&.MuiButtonBase-root:hover": { color: "#d14081", bgcolor: "transparent" }, width: { xs: '40%', sm: '35%' }, padding: { xs: '3px' }, borderRadius: '15px', '&:hover': { borderColor: '#404040' }, borderColor: '#404040', color: '#404040' }} variant="outlined">
                    <ThemeProvider theme={theme3}>
                      <Typography sx={{ fontWeight: '600 !important' }} variant='body1'>
                        ¿Cómo llegar?
                      </Typography>
                    </ThemeProvider>
                  </Button>
                  <Button disableRipple onClick={() => (window.open('tel:'+TodoDataService.GetNumberCall(), "_blank"))} startIcon={<LocalPhoneOutlinedIcon sx={{ fontSize: { xs: '11px !important', sm: '20px !important' } }} />} sx={{border:'2px solid black !important', textTransform: 'none', "&.MuiButtonBase-root:hover": { color: "#d14081", bgcolor: "transparent" }, width: { xs: '40%', sm: '35%' }, padding: { xs: '3px'}, borderRadius: '15px', '&:hover': { borderColor: '#404040' }, borderColor: '#404040', color: '#404040' }} variant="outlined">
                    <ThemeProvider theme={theme3}>
                      <Typography sx={{ fontWeight: '600 !important' }} variant='body1'>
                        Llamar
                      </Typography>
                    </ThemeProvider>
                  </Button>
                </Box>
                <Box sx={{ marginBottom: '3%' }}></Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0%' }}>
                  <Button disableRipple onClick={() => (window.open(TodoDataService.GetNumberPhone(), "_blank"))} startIcon={<WhatsAppIcon sx={{ fontSize: { xs: '11px !important', sm: '20px !important' } }} />} sx={{border:'2px solid black !important', textTransform: 'none', "&.MuiButtonBase-root:hover": { color: "#d14081", bgcolor: "transparent" }, width: { xs: '40%', sm: '35%' }, padding: { xs: '3px'}, borderRadius: '15px', '&:hover': { borderColor: '#404040' }, borderColor: '#404040', color: '#404040' }} variant="outlined">
                    <ThemeProvider theme={theme3}>
                      <Typography sx={{ fontWeight: '600 !important' }} variant='body1'>
                        Whatsapp
                      </Typography>
                    </ThemeProvider>
                  </Button>
                  <Button disableRipple onClick={() => (window.open("https://www.facebook.com/funeralescolumba?mibextid=uzlsIk", "_blank"))} startIcon={<FacebookOutlinedIcon sx={{ textTransform: 'none', fontSize: { xs: '11px !important', sm: '20px !important' } }} />} sx={{border:'2px solid black !important', textTransform: 'none', "&.MuiButtonBase-root:hover": { color: "#d14081", bgcolor: "transparent" }, width: { xs: '40%', sm: '35%' }, padding: { xs: '3px' }, borderRadius: '15px', '&:hover': { borderColor: '#404040' }, borderColor: '#404040', color: '#404040' }} variant="outlined">
                    <ThemeProvider theme={theme3}>
                      <Typography sx={{ fontWeight: '600 !important' }} variant='body1'>
                        Facebook
                      </Typography>
                    </ThemeProvider>
                  </Button>
                </Box>
              </Box>
              <Box sx={{height: '10%', marginBottom:'2%', width: '100%', display: 'grid', alignContent: 'center' }}>
              <ThemeProvider theme={theme2}>
                <Typography variant='body2' sx={{lineHeight:'normal !important', fontWeight: '600 !important', color: '#404040', fontFamily: 'Itaca ' }}>
                  Avenida Alfredo del Mazo #2601, Colonia La Magdalena, C.P. 50010, Toluca, Estado de México.
                </Typography>
              </ThemeProvider>
              </Box>
              <Box sx={{ height: '10%', width: '100%', display: 'flex', flexWrap: 'flex', justifyContent: 'space-between' }}>
                <ThemeProvider theme={theme}>
                  <Typography variant='body1' sx={{ color: '#404040', fontFamily: 'Itaca ' }}  >
                    Ayuda |
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={theme}>
                  <Typography variant='body1' sx={{ color: '#404040', fontFamily: 'Itaca ' }}  >
                    FAQ |
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={theme}>
                  <Typography variant='body1' sx={{ color: '#404040', fontFamily: 'Itaca ' }}  >
                    Aviso de Privacidad |
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={theme}>
                  <Typography variant='body1' sx={{ color: '#404040', fontFamily: 'Itaca ' }}  >
                    Términos y condiciones |
                  </Typography>
                </ThemeProvider>
              </Box>
            </Box>
          </ImageListItem>
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'block' }, backgroundColor: '#FFEC00', padding: '0px', margin: '0px', width: '15%' }}></Box>
      </Box>
    </Box>
  );
}

// Exportación del componente FooterColumba
export default FooterColumba;
