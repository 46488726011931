
// -----------Importar Componentes de Terceros------------------
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';

// -----------Importar Componentes Personalizados------------------
import BootstrapInput from '../../../components/Button_Bootstrap'


const StepMemoriaPrimero = ({OnChange,FormActualizar}) => {
    const handleChangethelepone = (e) =>{
        if(e.target.value.length < 11 )
        {
            OnChange(e);
        }
        
    } 
    return (
        <Box sx={{ flexGrow: 1, margin: '5% 0% 10px 0%' }}>
            <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 6, md: 12 }}
            >
                <Grid id='primer' item xs={12} >
                    <FormControl fullWidth variant="standard">
                        <BootstrapInput  required={true} onChange={OnChange} type='text' name='Nombre'  placeholder='Nombre' defaultValue={FormActualizar.Nombre} id="bootstrap-input1" />
                    </FormControl>
                </Grid>
                <Grid item xs={6} >
                    <FormControl  required={true} fullWidth variant="standard">
                        <BootstrapInput maxl onChange={handleChangethelepone} type='Number' name='Telefono' placeholder='Telefono' value={FormActualizar.Telefono} id="bootstrap-input2" />
                    </FormControl>
                </Grid>
                <Grid item xs={6} >
                    <FormControl fullWidth variant="standard">
                        <BootstrapInput required={true} onChange={OnChange} type='Email' name='Email' placeholder="Email" defaultValue={FormActualizar.Email} id="bootstrap-input3" />
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    );
}

export default StepMemoriaPrimero