//-------------Importar librerias----------------------------
import React, { useState } from 'react';

// -----------Importar Componentes de Terceros------------------
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';

// -----------Importar Componentes Personalizados------------------
import Buttonh from "../../../components/Button";

const ActionStepMemoria = ({SavingProcess, OnClickCropRemotoVideo, SetError, HandleNext, HandleBack, HandleReset, ActiveStep, StepsLength, FormActualizar, OnClickClose, Catalogo, ReturnMemoria }) => {
    const [ButtonCortar, setButtonCortar] = useState(true);

    const activate = FormActualizar.Nombre !== '' && FormActualizar.Telefono !== '' && FormActualizar.Email !== '' && ActiveStep === 0 ? false : true
    console.log(ActiveStep)
    const Validate = () => {
        if (ActiveStep === 0) {
            const email = FormActualizar.Email.toLowerCase()
            if (email.includes('@') && email.includes('.com')) {

                if (FormActualizar.Telefono.length === 10) {
                    HandleNext();
                }
                else {
                    SetError({ Error: 'Numero de telefono invalido' });

                }
            }
            else {
                SetError({ Error: 'Ingresa un correo electronico valido' });
            }
        }
        else {
            HandleNext();
        }
    };

    const ButtonContinuar = () => {
        let Disabled = FormActualizar.Mensaje !== '' ? FormActualizar.Mensaje !== 50 ? false : FormActualizar.mensajepersonalizado !== '' ? false : true : true;
        let DisabledVideo = FormActualizar.Tipo === '2' && !Disabled && FormActualizar[Catalogo[FormActualizar.Tipo].item] !== '' && FormActualizar.VideoCropMemoria === '' && ButtonCortar
        let DisabledButton = Disabled

        switch (FormActualizar.Tipo) {
            case '0':
                // DisabledButton=FormActualizar.Mensaje===''?true: FormActualizar.Mensaje===50 && FormActualizar.MensajeValidate===false ?true:  false
                break;
            case '1':
                DisabledButton = DisabledButton || FormActualizar[Catalogo[FormActualizar.Tipo].item] === ''
                break;
            case '2':
                DisabledButton = DisabledButton || (FormActualizar[Catalogo[FormActualizar.Tipo].item] === '' || FormActualizar.VideoCropMemoria === '')
                break;
            default:
                DisabledButton = true
                break;
        }
        // FormActualizar[Catalogo[FormActualizar.Tipo].item] === '' ? true : FormActualizar.Tipo === '2' && FormActualizar.VideoCropMemoria === '' ? true : FormActualizar.Mensaje===50 && FormActualizar.MensajeValidate===false ?true:  false
        return (
            <Buttonh FontWeight='600 !important' FontSize='16px' Type="reset" Disabled={DisabledVideo ? false : DisabledButton} Text={DisabledVideo ? 'Recortar' : 'Continuar'} Size='100%' Color='#D14081' BorderColor='2px solid #D14081 !important' OnClick={DisabledVideo ? () => { OnClickCropRemotoVideo(); setButtonCortar(false); } : HandleNext} ColorHover='white' Textcolor='#FFFFFF' TextcolorHover='#D14081' />
        );
    }

    console.log(FormActualizar.Telefono.length);

    return (
        <Container maxWidth='sm' sx={{ padding: { xs: '0px 0px ', sm: '0px 16px' } }}>
              {
                SavingProcess
                    ?
                    <Stack sx={{marginBottom:'3%', paddingTop: '3%', width: '100%', color: 'grey.500' }} spacing={2}>
                        <LinearProgress sx={{ backgroundColor: 'rgba(209, 64, 129, .3)', '& .MuiLinearProgress-bar': { backgroundColor: '#D14081' } }} size={100} color="secondary" />
                    </Stack>
                    :
                    <></>
            }
            {
                ActiveStep + 1 === StepsLength
                    ?
                    <Grid container sx={{ display: 'flex', flexDirection: { xs: 'column-reverse', sm: 'row' }, padding: '8px' }}>
                        <Grid xs={12} sm={7}></Grid>
                        <Grid xs={12} sm={2.5} sx={{ alignContent: 'center' }}>
                            <Link color='#D14081' sx={{ width: '100%', marginRight: '15px', fontSize: '16px', fontFamily: 'Itaca ' }} component="button" variant="body2" onClick={() => { HandleReset(); ReturnMemoria(); }}>
                                Editar
                            </Link>
                        </Grid>
                        <Grid xs={12} sm={2.5}>
                            <Buttonh Type="submit" Disabled={FormActualizar.VideoMemoriaFinal === '' ? true : FormActualizar[Catalogo[FormActualizar.Tipo].itemV] === '' ? true : SavingProcess ? true : false} Text='Continuar' Size='100%' Color='#D14081' BorderColor='2px solid #D14081 !important' ColorHover='white' Textcolor='#FFFFFF' TextcolorHover='#D14081' />
                        </Grid>
                    </Grid>
                    :
                    <>
                        {
                            ActiveStep === 0
                                ?
                                <Box sx={{ display: 'flex', padding: { xs: '8px', sm: '0px' }, width: '100%', margin: '0% 0% 0% 0%', justifyContent: 'center' }}>
                                    <Box width={{ xs: '100%', sm: '200px' }}>
                                        <Buttonh FontWeight='600 !important' FontSize='16px' Type="button" Disabled={activate} Text='Continuar' Size='100%' Color='#D14081' BorderColor='2px solid #D14081 !important' OnClick={Validate} ColorHover='white' Textcolor='#FFFFFF' TextcolorHover='#D14081' />
                                    </Box>
                                </Box>
                                :
                                <Grid container sx={{ display: 'flex', flexDirection: { xs: 'column-reverse', sm: 'row' }, padding: '8px' }}>
                                    <Grid xs={12} sm={7}></Grid>


                                    <Grid xs={12} sm={2} sx={{ alignContent: 'center' }}>
                                        <Link color='#D14081' sx={{ width: '100%', marginRight: '15px', fontWeight: '600 !important', fontSize: '16px', fontFamily: 'Itaca ' }} type="reset" component="button" variant="body2" onClick={ActiveStep === 2 ? () => { HandleBack(); ReturnMemoria(); } : HandleBack}>
                                            Regresar
                                        </Link>
                                    </Grid>
                                    <Grid xs={12} sm={3}>
                                        {
                                            ActiveStep === 2
                                                ?
                                                <ButtonContinuar />
                                                :
                                                <Buttonh FontWeight='600 !important' FontSize='16px' Type="reset" Disabled={FormActualizar.Tipo === '' ? true : false} Text='Continuar' Size='100%' Color='#D14081' BorderColor='2px solid #D14081 !important' OnClick={HandleNext} ColorHover='white' Textcolor='#FFFFFF' TextcolorHover='#D14081' />
                                        }
                                    </Grid>
                                </Grid>
                        }



                    </>
            }

          
        </Container>

    )

}


export default ActionStepMemoria