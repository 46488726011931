//-------------Importar librerias----------------------------
import React from "react";

// -----------Importar Componentes de Terceros------------------
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

// -----------Importar Componentes Personalizados------------------
import SelectMui from "../../../components/Select_Mui";


const StepConmemoracionSegundo = ({ Action, OnChange, FormActualizar, MensajePersonalizado, Crops, ListMensajes }) => {

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid sx={{display:'flex', justifyContent:'center'}}
                container
                columns={{ xs: 4, sm: 6, md: 12 }}
            >
                <Grid  xs={5} >
                    {
                        FormActualizar.picture === ''
                            ?
                            <></>
                            : Crops
                    }

                </Grid>
                <Grid  xs={7} md={6} >
                    <Box display='flex' alignItems='center' flexDirection='column' >
                        <Box sx={{width: '100%', padding: '3% 3% 3% 0%', borderRadius: '20px' }}>
                            <ol>
                                <li style={{ color: '#D14081' }}>
                                    <Typography fontSize='20px' fontFamily='Itaca ' display="inline" >Ajusta la foto al marco, </Typography> <Typography color='#3A3A3A' fontSize='18px' fontFamily='Itaca ' display="inline">o gira la imagen para ajustar la foto de forma vertical.</Typography>
                                </li>
                                <li style={{ color: '#D14081' }}>
                                    <Typography fontSize='18px' fontFamily='Itaca '  >
                                        Selecciona un mensaje o crea uno.
                                    </Typography>
                                </li>
                            </ol>
                        </Box>

                        <Box sx={{ width:{xs:'100%',md:'96%'} , margint: {xs:'0%',md:'0% 0% 0% 4%'}, borderRadius: '0px' }}>
                            <SelectMui MensajePersonalizado={MensajePersonalizado} defaultselect={FormActualizar} OnChange={OnChange} name='mensaje' data={ListMensajes} error='Debe de seleccionar alguno' requiere={true} />
                        </Box>

                        <Box sx={{  display: "flex", paddingTop: '2%', width: '100%', justifyContent: 'flex-end' }}>
                            {Action}
                        </Box>
                    </Box>
                </Grid>

            </Grid>
        </Box>
    );
}




export default StepConmemoracionSegundo