// -----------Importar Componentes de Terceros------------------
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


// -----------Importar Componentes Personalizados------------------
import RadioGroupImagen from '../../../components/RadioGroup-Imagen';


// -----------Importar Archivos Estaticos------------------
import Icon_Text_Activo from '../../../assets/Imagenes/texto_activo.png'
import Icon_Text_Inactivo from '../../../assets/Imagenes/texto_inactivo.png'
import Icon_Imagen_Activo from '../../../assets/Imagenes/imagen_activo.png'
import Icon_imagen_Inactivo from '../../../assets/Imagenes/imagen_inactivo.png'
import Icon_Video_Activo from '../../../assets/Imagenes/video_activo.png'
import Icon_Video_Inactivo from '../../../assets/Imagenes/video_inactivo.png'


const StepMemoriaSegundo =({OnChange,DefaultValue}) => {
    // const ContentRadion = [{ Id: 0, IconPasivo: Icon_Text_Inactivo, IconActivo: Icon_Text_Activo ,Disabled:FormActualizar.VideoMemoriaTexto===''?false:true}, { Id: 1, IconPasivo: Icon_imagen_Inactivo, IconActivo: Icon_Imagen_Activo,Disabled:FormActualizar.VideoMemoriaFotografia===''?false:true}, { Id: 2, IconPasivo: Icon_Video_Inactivo, IconActivo: Icon_Video_Activo,Disabled:FormActualizar.VideoMemoriaVideo===''?false:true }]
    const ContentRadion = [{ Id: 0, IconPasivo: Icon_Text_Inactivo, IconActivo: Icon_Text_Activo ,Disabled:false}, { Id: 1, IconPasivo: Icon_imagen_Inactivo, IconActivo: Icon_Imagen_Activo,Disabled:false}, { Id: 2, IconPasivo: Icon_Video_Inactivo, IconActivo: Icon_Video_Activo,Disabled:false }]


    return(
        <Box textAlign='center' sx={{marginTop:'2%'}}>
            <Typography  sx={{width:'100%'}}  fontSize='20px' fontFamily='Itaca ' display="inline" >Selecciona </Typography> <Typography color='#D14081' fontSize='20px' fontFamily='Itaca ' display="inline">el tipo de memoria que quieres compartir.</Typography>
            <RadioGroupImagen Top='10px' DefaultValue={DefaultValue} OnChange={OnChange} Name='Tipo' Width='sm' Origen='Row' JustifyContent='space-between' Content={ContentRadion} />
        </Box>
    );
}

export default StepMemoriaSegundo;