//-------------Importar librerias----------------------------
import { useState, useRef } from "react";
import * as htmlToImage from 'html-to-image';
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';

// -----------Importar Componentes de Terceros------------------
import Box from '@mui/material/Box';
import ImageListItem from '@mui/material/ImageListItem';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';

// -----------Importar Componentes Personalizados------------------
import TarjetaMensaje from "../../../components/TarjetaMensaje";
import MaskFoto_169 from '../../../assets/Imagenes/MaskImagen16_9.png'
import MaskFoto_1 from '../../../assets/Imagenes/MaskImagen1_1.png'




const StepMemoriaCuarto = ({ Sexo, FormActualizar, InstrumentalFile, OnchangeVideoMemoria, ListMensajes }) => {
  const [ImagenMensaje, setImagenMensaje] = useState(0);
  const [ImagenMensajeS, setImagenMensajeS] = useState(0);
  const [VideoMensajeUrl, setVideoMensajeUrl] = useState('');


  console.log(FormActualizar.Tipo)

  const elementRef = useRef(null);
  const elementRefImagen = useRef(null);
  const MensajeSecundario = useRef(null);

  const Ffmpeg = createFFmpeg({
    log: true
  })


  const htmlToImageConvert = async (item) => {
    const dataUrl = await htmlToImage.toPng(item.current);

    // download image
    console.log(dataUrl)
    setImagenMensaje(dataUrl)



    if (FormActualizar.Tipo === '1') {

      const dataUrl2 = await htmlToImage.toPng(MensajeSecundario.current, { cacheBust: false })
      setImagenMensajeS(dataUrl2)
      await VideoMensaje(dataUrl, dataUrl2)
    }
    else {
      await VideoMensaje(dataUrl)
    }
  };


  const b64ToBlob = async (b64) => {
    const blob = await fetch(b64);
    return await blob.blob();
  };


  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        resolve(reader.result.split(','));
        // "data:image/jpg;base64,    =sdCXDSAsadsadsa"
      };
    });
  };


  const VideoMensaje = async (item, item2) => {
    await Ffmpeg.load();

    Ffmpeg.FS(
      'writeFile',
      InstrumentalFile.name,
      await fetchFile(InstrumentalFile),
    )


    let ImagenMensajeBlod = await b64ToBlob(item)
    console.log(ImagenMensajeBlod)
    let ImagenMensajeFile = new File([ImagenMensajeBlod], 'ImagenMensajeFile1.png', {
      type: "image/png",
    })

    Ffmpeg.FS(
      'writeFile',
      ImagenMensajeFile.name,
      await fetchFile(ImagenMensajeFile),
    )

    console.log(ImagenMensajeFile);



    if (FormActualizar.Tipo === '0') {
      OnchangeVideoMemoria.Function(item.split(','), 'formMemoria', 'ImagenMemoriaFinal');
      await Ffmpeg.run("-framerate", "1/30", "-i", ImagenMensajeFile.name, "-i", InstrumentalFile.name, "-c:v", "libx264", "-t", "30", "-pix_fmt", "yuv420p", "-vf", "scale=1920:1080", "out.mp4");
    }
    if (FormActualizar.Tipo === '1') {
      OnchangeVideoMemoria.Function(item2.split(','), 'formMemoria', 'ImagenMemoriaFinal');
      console.log(item2)
      let ImagenMensajeSBlod = await b64ToBlob(item2)
      console.log(ImagenMensajeSBlod)
      let ImagenMensajeSFile = new File([ImagenMensajeSBlod], 'ImagenMensajeFile2.png', {
        type: "image/png",
      })

      Ffmpeg.FS(
        'writeFile',
        ImagenMensajeSFile.name,
        await fetchFile(ImagenMensajeSFile),
      )

      if (FormActualizar.AspectRation === '1') {
        await Ffmpeg.run("-framerate", "1/15", "-i", "ImagenMensajeFile%d.png", "-i", InstrumentalFile.name, "-c:v", "libx264", "-t", "30", "-pix_fmt", "yuv420p", "-vf", "scale=1920:1080", "out.mp4");
      }
      else {
        await Ffmpeg.run("-framerate", "1/15", "-i", "ImagenMensajeFile%d.png", "-i", InstrumentalFile.name, "-c:v", "libx264", "-t", "30", "-pix_fmt", "yuv420p", "-vf", "scale=1920:1080", "out.mp4");

      }

    }


    if (FormActualizar.Tipo === '2') {
      OnchangeVideoMemoria.Function(item.split(','), 'formMemoria', 'ImagenMemoriaFinal');
      Ffmpeg.FS(
        'writeFile',
        FormActualizar.VideoCropMemoria.name,
        await fetchFile(FormActualizar.VideoCropMemoria),
      );


      await Ffmpeg.run("-framerate", "1/15", "-i", ImagenMensajeFile.name, "-i", InstrumentalFile.name, "-c:v", "libx264", "-t", "15", "-pix_fmt", "yuv420p", "-s", "640:360", "-vf", "scale=640:360", '-filter:v', "fps=30", "out2.mp4");

      const data = await Ffmpeg.FS('readFile', "out2.mp4");

      let VideoImagenBlod = new Blob([data.buffer], { type: "video/mp4" })
      let VideoImagenFile = new File([VideoImagenBlod,], 'VideoTrimFile2.mp4', {
        type: "video/mp4",
      })


      console.log(VideoImagenFile)


      Ffmpeg.FS(
        'writeFile',
        VideoImagenFile.name,
        await fetchFile(VideoImagenFile),
      );

      await Ffmpeg.run("-i", "VideoTrimFile1.mp4", "-i", "VideoTrimFile2.mp4", "-filter_complex", "[0:v] [0:a] [1:v] [1:a] concat=n=2:v=1:a=1 [v] [a]", "-map", "[v]", "-map", "[a]", "out10.mp4")

      await Ffmpeg.run(
        '-i',
        'out10.mp4',
        '-i',
        InstrumentalFile.name,
        '-vcodec',
        'copy',
        '-acodec',
        'copy',
        '-map',
        '0:0',
        '-map',
        '1:0',
        "out.mp4"
      );

    }

    const data = Ffmpeg.FS('readFile', 'out.mp4');
    const VideoBlob = new Blob([data.buffer], { type: "video/mp4" })
    const url = URL.createObjectURL(
      VideoBlob,
    );


    setVideoMensajeUrl(url);

    OnchangeVideoMemoria.Function(await blobToBase64(VideoBlob), 'formMemoria', 'VideoMemoriaFinal');
  };



  console.log(FormActualizar)
  return (



    <div>
      {
        FormActualizar.Tipo === '0'
          ?

          <>



            {
              VideoMensajeUrl === ''
                ?
                <Box sx={{ width: '90%', marginLeft: '5%' }}>
                  <Stack sx={{ paddingTop: '5%', width: '100%', color: 'grey.500' }} spacing={2}>
                    <LinearProgress sx={{ backgroundColor: 'rgba(209, 64, 129, .3)', '& .MuiLinearProgress-bar': { backgroundColor: '#D14081' } }} size={100} color="secondary" />
                  </Stack>
                  <Box marginTop='50000%' width='1920px' sx={{ display: ImagenMensaje !== 0 ? 'none' : 'block' }}>
                    < TarjetaMensaje OnLoad={() => { htmlToImageConvert(elementRef); }} Nombre={FormActualizar.Nombre} Mensaje={FormActualizar.Mensaje === 50 ? FormActualizar.mensajepersonalizado : ListMensajes.find(data => data.value === FormActualizar.Mensaje).label} Sexo={Sexo} Width={Sexo} ElementRef={elementRef} FontSize={80} />
                  </Box>
                </Box>
                :
                <>
                  <video style={{ width: '100%', marginTop: '5%', borderRadius: '15px' }} controls>
                    <source src={VideoMensajeUrl} type="video/mp4" />
                  </video>
                </>

            }
          </>

          : <></>
      }


      {
        FormActualizar.Tipo === '1'
          ? <>

            {
              VideoMensajeUrl === ''
                ?
                <>
                  
                  <Stack sx={{ paddingTop: '5%', width: '100%', color: 'grey.500', display: ImagenMensajeS !== '' ? 'block' : 'none' }} spacing={2}>
                    <LinearProgress sx={{ backgroundColor: 'rgba(209, 64, 129, .3)', '& .MuiLinearProgress-bar': { backgroundColor: '#D14081' } }} size={100} color="secondary" />
                  </Stack>


                  <Box marginTop='50000%' width='1920px' sx={{ justifyContent: 'center', display: ImagenMensaje !== 0 ? 'none' : 'block', padding: FormActualizar.AspectRation === '1' ? '5% 0% 0% 0%' : '5% 0% 0% 0%' }}>
                    {
                      ImagenMensaje !== 0
                        ? <></>
                        :
                        <div key='imagen' ref={elementRefImagen} sx={{ width: '100%' }} >
                          <ImageListItem sx={{ width: FormActualizar.AspectRation === '1' ? '56.25%' : '100%', marginLeft: FormActualizar.AspectRation === '1' ? '21.9%' : '0%' }}>
                            <img alt="Mensajefotoalter" style={{ filter: 'sepia(0.9)', borderRadius: FormActualizar.AspectRation === '1' ? '0px' : '15px' }} src={FormActualizar.ImagenCropMemoria}></img>
                            <Box sx={{ position: 'absolute', width: '100%', zIndex: 21, bottom: '0%' }} >
                              <ImageListItem  >
                                <img alt="Mensajefoto" onLoad={() => { htmlToImageConvert(elementRefImagen); }} src={FormActualizar.AspectRation === '1' ? MaskFoto_1 : MaskFoto_169}>
                                </img>
                                <Box sx={{ position: 'absolute', width: '100%', top: FormActualizar.AspectRation === '1' ? '87%' : '80%', zIndex: 23, display: 'flex', flexDirection: 'row' }}>
                                  <Box sx={{ width: '26%' }}></Box>
                                  <Box sx={{ width: '64%' }}>

                                    {
                                      FormActualizar.AspectRation === '1'
                                        ?
                                        <Typography variant='body1' sx={{ marginTop: '4%', color: '#717171', fontFamily: 'Itaca ', lineHeight: 'normal', textAlign: 'center', fontSize: '35px' }}>
                                          {FormActualizar.Mensaje === 50 ? FormActualizar.mensajepersonalizado : ListMensajes.find(data => data.value === FormActualizar.Mensaje).label}
                                        </Typography>
                                        :
                                          <Typography sx={{ marginTop: '4%', color: '#717171', fontFamily: 'Itaca ', textAlign: 'center', fontSize: '50px' }}>
                                            {FormActualizar.Mensaje === 50 ? FormActualizar.mensajepersonalizado : ListMensajes.find(data => data.value === FormActualizar.Mensaje).label}
                                          </Typography>
                                    }
                                  </Box>
                                </Box>
                              </ImageListItem>
                            </Box>
                          </ImageListItem>
                        </div>
                    }


                    {
                      ImagenMensaje === 0
                        ?
                        <Box width='1920px'>< TarjetaMensaje Nombre={FormActualizar.Nombre} Mensaje={FormActualizar.Mensaje === 50 ? FormActualizar.mensajepersonalizado : ListMensajes.find(data => data.value === FormActualizar.Mensaje).label} Sexo={Sexo} Width={FormActualizar.AspectRation === '1' ? '7%' : '12%'} ElementRef={MensajeSecundario} FontSize={FormActualizar.AspectRation === '1' ? 70 : 80} /></Box>
                        : <></>
                    }

                  </Box>
                </>

                : <video style={{ width: '100%', marginTop: '5%', borderRadius: '15px' }} controls>
                  <source src={VideoMensajeUrl} type="video/mp4" />
                </video>
            }
          </>

          : <></>
      }
      {
        FormActualizar.Tipo === '2'
          ?

          VideoMensajeUrl === ''
            ?
            <>
             

              <Stack sx={{ paddingTop: '5%', width: '100%', color: 'grey.500'  }} spacing={2}>
                <LinearProgress sx={{ backgroundColor: 'rgba(209, 64, 129, .3)', '& .MuiLinearProgress-bar': { backgroundColor: '#D14081' } }} size={100} color="secondary" />
              </Stack>

              <Box marginTop='50000%' sx={{ width: '1920px', display: ImagenMensaje !== 0 ? 'none' : 'block' }}>
                < TarjetaMensaje OnLoad={() => { htmlToImageConvert(elementRef); }} Nombre={FormActualizar.Nombre} Mensaje={FormActualizar.Mensaje === 50 ? FormActualizar.mensajepersonalizado : ListMensajes.find(data => data.value === FormActualizar.Mensaje).label} Sexo={Sexo} Width='20%' ElementRef={elementRef} FontSize={80} />
              </Box>
            </>

            : <video style={{ width: '100%', marginTop: '5%', borderRadius: '15px' }} controls>
              <source src={VideoMensajeUrl} type="video/mp4" />
            </video>

          : <></>
      }

    </div>

  );
}

export default StepMemoriaCuarto