
import React, { useEffect, useRef, useState } from 'react';

// -----------Importar Componentes de Terceros------------------
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import ContentCutIcon from '@mui/icons-material/ContentCut';
// import 'toolcool-range-slider/dist/plugins/tcrs-generated-labels.min.js';
// import 'toolcool-range-slider';
// import 'toolcool-range-slider/dist/plugins/tcrs-storage.min.js';
import 'nouislider/distribute/nouislider.css';

import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

import './styles.css'

// import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';
let Ffmpeg;
const VideoCrop = ({ Source, OnChange, Width,CropRemotoVideo=false }) => {
  const [videoDuration, setVideoDuration] = useState(0);
  const [endTime, setEndTime] = useState(15);
  const [startTime, setStartTime] = useState(0);
  const [videoSrc, setVideoSrc] = useState(URL.createObjectURL(Source));
  const [videoFileValue, setVideoFileValue] = useState(Source);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [videoTrimmedUrl, setVideoTrimmedUrl] = useState('');
  const [trimming, settrimming] = useState(false);
  
  
  // const rangeSliderElement = rangeSlider(element);



  const videoRef = useRef();


  // let Ffmpeg = createFFmpeg({
  //   log: true
  // })

  const loadScript = (src) => {
    return new Promise((onFulfilled, _) => {
      const script = document.createElement('script');
      let loaded;
      script.async = 'async';
      script.defer = 'defer';
      script.setAttribute('src', src);
      script.onreadystatechange = script.onload = () => {
        if (!loaded) {
          onFulfilled(script);
        }
        loaded = true;
      };
      script.onerror = function () {
        console.log('Script failed to load');
      };
      document.getElementsByTagName('head')[0].appendChild(script);
    });
  };


  useEffect(() => {
    //Load the ffmpeg script
    loadScript(
      'https://cdn.jsdelivr.net/npm/@ffmpeg/ffmpeg@0.11.2/dist/ffmpeg.min.js',
    ).then(() => {
      if (typeof window !== 'undefined') {
        // creates a ffmpeg instance.
        Ffmpeg = window.FFmpeg.createFFmpeg({ log: true });
        //Load ffmpeg.wasm-core script
        Ffmpeg.load();
        //Set true that the script is loaded
        setIsScriptLoaded(true);
      }
    });
  }, []);


  //Convert the time obtained from the video to HH:MM:SS format
  const convertToHHMMSS = (val) => {
    const secNum = parseInt(val, 10);
    let hours = Math.floor(secNum / 3600);
    let minutes = Math.floor((secNum - hours * 3600) / 60);
    let seconds = secNum - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    let time;
    // only mm:ss
    if (hours === '00') {
      time = minutes + ':' + seconds;
    } else {
      time = hours + ':' + minutes + ':' + seconds;
    }
    return time;
  };






  useEffect(() => {
    // UploadInstrumental();
    if(CropRemotoVideo===true)
      {
        handleTrim();
      }
  }, [CropRemotoVideo]);

  //Get the duration of the video using videoRef
  useEffect(() => {
    if (videoRef && videoRef.current) {
      const currentVideo = videoRef.current;
      currentVideo.onloadedmetadata = () => {
        setVideoDuration(currentVideo.duration);
        // setEndTime(currentVideo.duration);
      };
    }
  }, [videoSrc]);


  //Trim functionality of the video
  const handleTrim = async () => {

    // try {
    //   await Ffmpeg.load();
    // } catch (error) {
    //   alert('Borrar cache de navegador')
    // }


    settrimming(true);
    const { name, type } = videoFileValue;
    //Write video to memory
    Ffmpeg.FS(
      'writeFile',
      name,
      await window.FFmpeg.fetchFile(videoFileValue),
    );





    const videoFileType = type.split('/')[1];
    //Run the ffmpeg command to trim video
    await Ffmpeg.run(
      '-i',
      name,
      '-ss',
      `${convertToHHMMSS(startTime)}`,
      '-to',
      `${convertToHHMMSS(endTime)}`,
      '-acodec',
      'copy',
      '-vcodec',
      'copy',
      `out.${videoFileType}`,
    );


    await Ffmpeg.run("-i", "out.mp4", "-s", "640:360", "-filter:v", "fps=30,setdar=16/9", "out2.mp4")

    await Ffmpeg.run("-i", "out2.mp4", "-filter_complex", "colorchannelmixer=.393:.769:.189:0:.349:.686:.168:0:.272:.534:.131", "-pix_fmt", "yuv420p", "out3.mp4")

    let data = '';
    try {
      data =  Ffmpeg.FS('readFile', `out3.${videoFileType}`);
    } catch (error) {
      alert('Codigo de encryptacion encode incopatible, carge otro video.')
    }

    let VideoTrimBlod = new Blob([data.buffer], { type: videoFileValue.type })
    let VideoTrimFile = new File([VideoTrimBlod,], 'VideoTrimFile1.mp4', {
      type: videoFileValue.type,
    })

    const url1 = URL.createObjectURL(
      VideoTrimBlod
    );


    setVideoTrimmedUrl(url1);
    settrimming(false);
    OnChange.Function(VideoTrimFile, OnChange.Key, OnChange.Name);

  };




  const sliderRef = useRef();

  // useEffect(() => {

  //   const slider = sliderRef.current;

  //   const onChange = (evt) => {
  //     setStartTime(Math.round(evt.detail.value1));
  //     setEndTime(Math.round(evt.detail.value1) + 15)

  //     videoRef.current.currentTime = evt.detail.value1;
  //     console.log(startTime)


  //   };

  //   slider?.addEventListener('change', onChange);

  //   return () => {
  //     slider?.removeEventListener('change', onChange);
  //   };
  // });


  const prueba = e => {
    console.log(e);
    setStartTime(e[0]);
    setEndTime(e[1])
    videoRef.current.currentTime = e[0];
  }



  return (
    <div className="App">

      {videoSrc.length ? (
        <>
          {
            videoTrimmedUrl === ''
              ?
              <>
                <video controls style={{ width: '100%', borderRadius: '15px', marginBottom: '2%' }} src={videoSrc} ref={videoRef} >
                  <source src={videoSrc} type={videoFileValue.type} />
                </video>



                {
                  trimming === false
                    ? <>
                      <RangeSlider  step="1"
                        min='0'
                        onInput={prueba}
                        thumbsDisabled={[true,true]}
                        defaultValue={[startTime,endTime]}
                        max={videoDuration} />
                    
                      <Stack sx={{ marginTop: '15px', }} justifyContent='space-around' direction="row" spacing={1}>
                        <Chip className='time' sx={{margin:'0px', color: '#FB7EC4', background: '#dbdbdb', borderRadius:'5px'}} label={'Start duration: ' + convertToHHMMSS(startTime)} />
                        {/* <IconButton sx={{margin:'0px !important'}} disabled={trimming} type='reset' onClick={handleTrim}>
                          <ContentCutIcon sx={{ color: (theme) => '#F50087' }} />
                        </IconButton> */}
                        <Chip className='time' sx={{margin:'0px !important', color: '#FB7EC4', background: '#dbdbdb', borderRadius:'5px' }} label={'End duration: ' + convertToHHMMSS(endTime)} />
                      </Stack>
                    </>


                    : <Stack sx={{ paddingTop: '5%', width: '100%', color: 'grey.500' }} spacing={2}>
                      <LinearProgress sx={{ backgroundColor: 'rgba(209, 64, 129, .3)', '& .MuiLinearProgress-bar': { backgroundColor: '#D14081' } }} size={100} color="secondary" />
                    </Stack>
                }


              </>
              : <></>
          }

          {videoTrimmedUrl && (


            <video style={{ width: '100%', borderRadius: '15px' }} controls>
              <source src={videoTrimmedUrl} type={videoFileValue.type} />
            </video>

          )}
        </>
      ) : (
        ''
      )}
    </div>
  );
}

export default VideoCrop