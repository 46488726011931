//-------------Importar librerias----------------------------
import React, { Component } from "react";
import axios from "axios";
import TodoDataService from '../../hooks/services'
import withRouter from "../../routes/withRouter";

// -----------Importar Componentes de Terceros------------------
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';

// -----------Importar Componentes Personalizados------------------
import Buscar from '../../components/Buscar';
import CarouselImagenes from '../../components/Carousel';
import Crop from "../../components/Crop";
import ModalBaseMui from "../../components/Modal_Mui";
import Buttonh from "../../components/Button";
import BootstrapInput from "../../components/Button_Bootstrap";
import HorizontalLinearStepper from '../../components/Stepper'
import StepMemoriaPrimero from "./Memoria/Step_Memoria_Primero";
import StepMemoriaSegundo from "./Memoria/Step_Memoria_Segundo";
import ActionStepMemoria from "./Memoria/Action_Step_Memoria";
import StepMemoriaTercer from "./Memoria/Step_Memoria_Tercero";
import StepMemoriaCuarto from "./Memoria/Step_Memoria_Cuarto";
import ActionStepConmemoracion from "./Conmemoracion/Action_Step_Conmemoracion";
import StepConmemoracionPrimero from "./Conmemoracion/Step_Conmemoracion_Primero";
import StepConmemoracionSegundo from "./Conmemoracion/Step_Conmemoracion_Segundo";
import StepConmemoracionTercero from "./Conmemoracion/Step_Conmemoracion_Tercero";
import VideoCrop from "../../components/Crop_Video";
import ContentCompartir from "./Compartir";
import AlertMui from "../../components/Alert_Mui";


// -----------Importar Archivos Estaticos------------------
import Instrumental from '../../assets/Audio/instrumental.aac'
import InstrumentalConmemoracion from '../../assets/Audio/Instrumental Comemoración.mp3'


import ImagenFolio from "../../assets/Imagenes/Seguimiento/folio_col.png"


// -------------Valores Constantes-------------------------------
const ListMensajes = [
  { value: 0, label: '“Nos separó la muerte, pero el amor nos mantiene unidos”.' },
  { value: 1, label: '“Quererte fue fácil, pero olvidarte será imposible”.' },
  { value: 2, label: '“Tus valores y enseñanzas viven en nosotros”.' },
  { value: 50, label: 'Escribir mensaje' }
]
const UrlToken = TodoDataService.GetHostRestApi() + '/usuario/login/';
const datalogin = { email: "apicolumba@funeralescolumba.com.mx", password: "5M#j7^hBRXY#5Y!m" }
const UrlPedidodCliente = TodoDataService.GetHostRestApi() + '/PedidoCliente/';
const UrlConmemoraciones = TodoDataService.GetHostRestApi() + '/Conmemoraciones/';
const UrlMemorias = TodoDataService.GetHostRestApi() + '/Conmemoraciones/memorias/';
const UlrConmemoracionCaratula = TodoDataService.GetHostRestApi() + '/conmemoraciones/caratula/';
const UrlPostConmemoracion = TodoDataService.GetHostRestApi() + '/Conmemoraciones/'
const UrlPostMemoria = TodoDataService.GetHostRestApi() + '/Conmemoraciones/memorias';
const UrlValidarConmemoracion = TodoDataService.GetHostRestApi() + '/conmemoraciones/'



class Conmemoraciones extends Component {
  state = {
    RemotoVideo:true,
    CropRemotoVideo:false,
    SavingProcess:false,
    Compartir: false,
    Parametro: '',
    Buscar: '',
    Token: '',
    ShowConsultaFolio: false,
    DataConmemoracion: '',
    InstrumentalFile: '',
    ImagenCropMemoria: '',
    DataNuevaMemoria: {Conmemoracion: '', Caratula:''},
    ListCatalogo: { 0: { item: 'Mensaje', itemV: 'VideoMemoriaTexto' }, 1: { item: 'Fotografia', itemV: 'VideoMemoriaFotografia' }, 2: { item: 'Video', itemV: 'VideoMemoriaVideo' } },
    ActiveStep: 0,
    ActiveStepMemoria: 0,
    validated: true,
    OpenModal: false,
    OpenConmemoracion: false,
    OpenModalCompartir: false,
    OpenMemoria: false,
    NuevaMemoria: false,
    MensajePersonalizado: false,
    MensajePersonalizadoMemoria: false,
    ValidatePedidoClienteFolio: false,
    Steps: [],
    result: '',
    data: [],
    form: {
      picture: '',
      mensaje: '',
      mensajepersonalizado: '',
      PedidoClienteFolio: '',
      VideoConmemoracion: '',
    },
    formMemoria: {
      ImagenCropMemoria: '',
      Tipo: '',
      Nombre: '',
      Telefono: '',
      Email: '',
      Mensaje: '',
      mensajepersonalizado: '',
      MensajeValidate: false,
      Video: '',
      VideoCropMemoria: '',
      Fotografia: '',
      AspectRation: '1',
      VideoMemoriaFinal: '',
      ImagenMemoriaFinal: '',
    },
    DataFinado: '',
    formCompartir: {
      Url: '',
      SelectCompartir: '',
    },
    Error: '',
    ListHeadersStep: [
      { id: 0, Label: 'Nueva memoria', Complect: false },
      { id: 1, Label: 'Selecciona tipo de memoria', Complect: false },
      { id: 2, Label: 'Personaliza', Complect: false },
      { id: 3, Label: 'Vista Previa', Complect: false },
    ]
  }


  onChange = async e => {
    // e.persist();
    await this.setState({ Buscar: e.target.value });
  }

  CompletarStep = () => {
    let NewListHeadersStep = this.state.ListHeadersStep.slice();
    NewListHeadersStep[this.state.ActiveStepMemoria].Complect = true;
    this.setState({ ListHeadersStep: NewListHeadersStep });
  }


  OnClickBarraProgreso = (item) => {
    this.setState({ ActiveStepMemoria: item });
  }


  GetPeticionToken = async () => {
    let result = '';
    await axios.post(UrlToken, datalogin).then(response => {
      this.setState({ Token: 'Bearer ' + response.data.token })
      result = 'Bearer ' + response.data.token
    }).catch(error => {
      console.log(error);
    });

    return result
  }


  handleChangeSingle = async (item, Key, Name) => {
    await this.setState({
      [Key]: {
        ...this.state[Key],
        [Name]: item
      }
    })
  }

  UploadInstrumental = async () => {
    let Blob = ''
    await fetch(Instrumental)
      .then(res => res.blob())
      .then((myBlob) => {
        Blob = myBlob
      });

    let InstrumentalFiles = new File([Blob], 'instrumental.82a0f5e36fb45d6dba2a.aac', {
      type: Blob.type,
    })
    await this.setState({ InstrumentalFile: InstrumentalFiles });
  }

  handleChange = async (e,Alert='') => {
    // e.persist();

    if (e.target.type === 'file') {
      if (e.target.files[0]) {
        const Archivo = e.target.files[0]
        if (Archivo.size > 3000000) {
          alert('supero el limite permitido');
          e.target.value = "";
        } else {
          await this.setState({
            form: {
              ...this.state.form,
              [e.target.name]: e.target.files[0]
            }
          });
          Alert();
        }
      }

    }
    else {
      await this.setState({
        form: {
          ...this.state.form,
          [e.target.name]: e.target.value
        }
      });
    }
  }


  handleChangeMemoria = async (e) => {
    // e.persist();

    if (e.target.type === 'file') {
      const Archivo = e.target.files[0]
      if (Archivo.size > 60000000) {
        alert('supero el limite permitido');
        e.target.value = "";
      } else {
        let nombArchivo = Archivo.name;
        let extension = nombArchivo.split(".").slice(-1);
        extension = extension[0];
        let extensiones = ["jpg", "png", "jpeg"];

        if (extension === 'mp4') {
          if (e.target.name === 'Video') {
            await this.setState({
              formMemoria: {
                ...this.state.formMemoria,
                [e.target.name]: e.target.files[0]
              }
            });
          }
          else {
            alert("Extensión NO permitida");

          }
        }
        else {
          if (extensiones.indexOf(extension) === -1) {
            alert("Extensión NO permitida");
          }
          else {
            if (e.target.name === 'Video') {
              alert("Extensión NO permitida");
            }
            else {
              await this.setState({
                formMemoria: {
                  ...this.state.formMemoria,
                  [e.target.name]: e.target.files[0]
                }
              });
            }

          }
        }
      }

    }
    else {
      await this.setState({
        formMemoria: {
          ...this.state.formMemoria,
          [e.target.name]: e.target.value
        }
      });
    }
  }


  handleChangeselect = async (item, name) => {
    await this.setState({
      form: {
        ...this.state.form,
        [name]: item
      }
    });
  }

  handleChangeCompartir = async (e) => {
    await this.setState({
      formCompartir: {
        ...this.state.formCompartir,
        [e.target.name]: e.target.value
      }
    });
  }

  SetMensajePersonalizado = (item, key) => {
    this.setState({ [key]: item });
  };


  ModalMemoria = () => {
    this.setState({
      RemotoVideo:false,
      CropRemotoVideo:false,
    SavingProcess:false,
      ActiveStepMemoria: 0, Error: '', OpenMemoria: !this.state.OpenMemoria, formMemoria: {
        ImagenCropMemoria: '',
        Tipo: '',
        Nombre: '',
        Telefono: '',
        Email: '',
        Mensaje: '',
        MensajeValidate: false,
        mensajepersonalizado: '',
        Video: '',
        VideoCropMemoria: '',
        Fotografia: '',
        AspectRation: '1',
        VideoMemoriaFinal: '',
        ImagenMemoriaFinal: '',
      },
      ListHeadersStep: [
        { id: 0, Label: 'Nueva memoria', Complect: false },
        { id: 1, Label: 'Selecciona tipo de memoria', Complect: false },
        { id: 2, Label: 'Personaliza', Complect: false },
        { id: 3, Label: 'Vista Previa', Complect: false },
      ]
    });
  };

  ModalMemoriaClose = () => {
    this.setState({
      RemotoVideo:true,
      CropRemotoVideo:true,
    SavingProcess:false,
      ActiveStepMemoria: 0, Error: '', OpenMemoria: !this.state.OpenMemoria, MensajePersonalizadoMemoria: !this.state.MensajePersonalizadoMemoria, formMemoria: {
        ImagenCropMemoria: '',
        Tipo: '',
        Nombre: '',
        Telefono: '',
        Email: '',
        Mensaje: '',
        MensajeValidate: false,
        mensajepersonalizado: '',
        Video: '',
        VideoCropMemoria: '',
        Fotografia: '',
        AspectRation: '',
        VideoMemoriaFinal: '',
        ImagenMemoriaFinal: ''
      },
      ListHeadersStep: [
        { id: 0, Label: 'Nueva memoria', Complect: false },
        { id: 1, Label: 'Selecciona tipo de memoria', Complect: false },
        { id: 2, Label: 'Personaliza', Complect: false },
        { id: 3, Label: 'Vista Previa', Complect: false },
      ]
    });
  };
  ShowConmemoracion = () => {
    this.setState({
      RemotoVideo:true,
      ValidatePedidoClienteFolio: false,
      form: {
        picture: '',
        mensaje: '',
        mensajepersonalizado: '',
        PedidoClienteFolio: '',
        VideoConmemoracion: '',
      },
      Error: ''
    });
  }

  handleNext = (key) => {
    this.CompletarStep();
    this.setState({ [key]: this.state[key] + 1, Error: '' });
  };

  handleBack = (key) => {
    this.setState({ [key]: this.state[key] - 1, Error: '' });
  };

  handleReset = (key) => {
    this.setState({
      [key]: 0, Error: '', 
      // form: {
      //   picture: '',
      //   mensaje: '',
      //   mensajepersonalizado: '',
      //   PedidoClienteFolio: this.state.form.PedidoClienteFolio,
      //   VideoConmemoracion: '',
      // },
    });
  };

  HandleRegresar = () => {
    this.setState({
      form: {
        picture: '',
        mensaje: '',
        mensajepersonalizado: '',
        PedidoClienteFolio: this.state.form.PedidoClienteFolio,
        VideoConmemoracion: '',
      }
    });
  };


  ShowNuevaMemoria = () => {
    this.setState({ NuevaMemoria: !this.state.NuevaMemoria.data })
  }


  setData = (data) => {
    this.setState(
      data
    );
  }

  ReturnMemoria = async () => {
    await this.setState({
      CropRemotoVideo:false,
    SavingProcess:false,
      MensajePersonalizadoMemoria: false,
      formMemoria: {
        ...this.state.formMemoria,
        Mensaje: '',
        mensajepersonalizado: '',
        Video: '',
        Fotografia: '',
        MensajeValidate: false
      }
    });
  }

  ShowModalCompartir = () => {
    this.setState({ OpenModalCompartir: !this.state.OpenModalCompartir ,formCompartir:{Url: '',
    SelectCompartir: '',}})
  }

  CompartirUrl = () => {
    this.CompartirSeguimiento(this.state.formCompartir.SelectCompartir);
    this.setState({ OpenModalCompartir: !this.state.OpenModalCompartir, formCompartir: { Url: '', SelectCompartir: '' } })
  }

  PeticionConmemoraciones = async () => {
    TodoDataService.PeticionGetComemoraciones({ NameKey: 'DataConmemoracion', Token: await this.GetPeticionToken(), Url1: UrlConmemoraciones, Url2: UlrConmemoracionCaratula, Save: this.setData });
  }

  CompartirConmemoracion = async (folio) => {
    let DataNuevaMemoria = { Token: await this.GetPeticionToken(), Url3: UrlMemorias, Refresh: this.setData, Close: this.ShowNuevaMemoria, Folio: folio, Url1: UrlConmemoraciones, Url2: UlrConmemoracionCaratula, }
    TodoDataService.GetNuevaMemoriaCompartir(DataNuevaMemoria);
  }


  CompartirSeguimiento = (item) => {
    window.open(item, "_blank")
  }

  componentDidMount() {
    this.UploadInstrumental();
    if (this.props.params.id) {
      this.CompartirConmemoracion(this.props.params.id);
    }
    else {
      this.PeticionConmemoraciones();
    }
  }




  CloseConmemoracion = () => {
    this.setState(
      {
        RemotoVideo:true,
        CropRemotoVideo:false,
        Compartir: false,
        Parametro: '',
        Buscar: '',
        ShowConsultaFolio: false,
        InstrumentalFile: '',
        ImagenCropMemoria: '',
        DataNuevaMemoria: {Conmemoracion: '', Caratula:''},
        ListCatalogo: { 0: { item: 'Mensaje', itemV: 'VideoMemoriaTexto' }, 1: { item: 'Fotografia', itemV: 'VideoMemoriaFotografia' }, 2: { item: 'Video', itemV: 'VideoMemoriaVideo' } },
        ActiveStep: 0,
        ActiveStepMemoria: 0,
        validated: true,
        OpenModal: false,
        OpenConmemoracion: false,
        OpenModalCompartir: false,
        OpenMemoria: false,
        NuevaMemoria: false,
        MensajePersonalizado: false,
        MensajePersonalizadoMemoria: false,
        ValidatePedidoClienteFolio: false,
        Steps: [],
        result: '',
        data: [],
        form: {
          picture: '',
          mensaje: '',
          mensajepersonalizado: '',
          PedidoClienteFolio: '',
          VideoConmemoracion: '',
        },
        formMemoria: {
          ImagenCropMemoria: '',
          Tipo: '',
          Nombre: '',
          Telefono: '',
          Email: '',
          Mensaje: '',
          mensajepersonalizado: '',
          MensajeValidate: false,
          Video: '',
          VideoCropMemoria: '',
          Fotografia: '',
          AspectRation: '1',
          VideoMemoriaFinal: '',
          ImagenMemoriaFinal: '',
        },
        DataFinado: '',
        formCompartir: {
          Url: '',
          SelectCompartir: '',
        },
        Error: '',
        ListHeadersStep: [
          { id: 0, Label: 'Nueva memoria', Complect: false },
          { id: 1, Label: 'Selecciona tipo de memoria', Complect: false },
          { id: 2, Label: 'Personaliza', Complect: false },
          { id: 3, Label: 'Vista Previa', Complect: false },
        ]
      }
    )
  }


  render() {
    console.log(this.state)
    const { form } = this.state
    const StepAction = <ActionStepConmemoracion Compartir={this.state.Compartir} Conmemoracion={this.state.DataNuevaMemoria.Conmemoracion===''?false:true} DataConmemoracion={this.state.DataConmemoracion===''?false:true}   OnClickCloseConmemoracion={this.CloseConmemoracion} OnClickRegresar={this.HandleRegresar} Origen='Nueva Conmemoracion' OnClickCompartir={this.ShowModalCompartir} OnClickConmemoracion={this.ShowConmemoracion} OnClickCancelar={() => { this.setState({ ValidatePedidoClienteFolio: false }) }} FormActualizar={this.state.form} HandleNext={() => { (this.handleNext('ActiveStep')) }} HandleBack={() => { this.handleBack('ActiveStep') }} HandleReset={() => { this.handleReset('ActiveStep') }} ActiveStep={this.state.ActiveStep} OnClickMemoria={this.ModalMemoria} StepsLength={3} />
    const StepActionNewMemoria = <ActionStepConmemoracion Compartir={this.state.Compartir} Conmemoracion={this.state.DataNuevaMemoria.Conmemoracion===''?false:true} DataConmemoracion={this.state.DataConmemoracion===''?false:true}  OnClickCloseConmemoracion={this.CloseConmemoracion}  Origen='Nueva Memoria' OnClickCompartir={this.ShowModalCompartir} OnClickConmemoracion={this.ShowConmemoracion} OnClickCancelar={() => { this.setState({ ValidatePedidoClienteFolio: false }) }} FormActualizar={this.state.form} HandleNext={() => { (this.handleNext('ActiveStep')) }} HandleBack={() => { this.handleBack('ActiveStep') }} HandleReset={() => { this.handleReset('ActiveStep') }} ActiveStep={2} OnClickMemoria={this.ModalMemoria} StepsLength={3} />

    const StepActionMemoria = <ActionStepMemoria SavingProcess={this.state.SavingProcess} OnClickCropRemotoVideo={()=>(this.setState({CropRemotoVideo:true}))} SetError={this.setData} ReturnMemoria={this.ReturnMemoria} FormActualizar={this.state.formMemoria} HandleNext={() => { (this.handleNext('ActiveStepMemoria')) }} HandleBack={() => { this.handleBack('ActiveStepMemoria') }} HandleReset={() => { this.handleReset('ActiveStepMemoria') }} OnClickClose={this.ModalMemoriaClose} ActiveStep={this.state.ActiveStepMemoria} StepsLength={4} Catalogo={this.state.ListCatalogo} />;
    return (
      <Container sx={{ marginTop: '48px', padding: '0px 16px !important', maxWidth: '1112px !important' }} >

        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', }} width="100%" >
          <Grid container>
            <Grid sm={6} xs={12} >
              <Typography mb='0px' variant='h2' sx={{ fontFamily: 'Itaca ', fontSize: '40px', color: '#D14081', fontWeight: '600 !important' }} gutterBottom> {this.state.ValidatePedidoClienteFolio === false ? 'Conmemoración' : this.state.NuevaMemoria === false ? 'Nueva Conmemoración' : 'Conmemoracion'}</Typography>
              <Typography sx={{ fontFamily: 'Itaca', color: '#3A3A3A', fontSize: '15px' }} variant="body" align="justify" gutterBottom>
                Un espacio para recordar y celebrar la vida de tu ser querido con fotografías, videos y mensajes.
              </Typography>
            </Grid>
            <Grid sm={6} xs={12} >
              {this.state.ValidatePedidoClienteFolio === true || this.state.NuevaMemoria !== false || this.props.params.id ? <></> : <Buscar Placeholder='Nombre del finado' Value={this.state.Buscar} OnChange={this.onChange} />}
            </Grid>
          </Grid>
        </Box>


        {
          this.state.OpenMemoria === true
            ?
            <ModalBaseMui SavingProcess={()=>(this.setState({SavingProcess:true}))} ActionDirection={this.state.ActiveStepMemoria > 0 ? 'flex-end' : 'center'} Title='Nueva Memoria' TitleWeight={400} OnClickClose={this.ModalMemoriaClose} Open={this.state.OpenMemoria} FullScreen={false} FullWidth={true} MaxWidth='745px' OnclickAction={TodoDataService.PostMemoria}
              OnclickActionData={{
                Memoria: { folio: this.state.DataNuevaMemoria.Conmemoracion.folio, nombreCompleto: this.state.formMemoria.Nombre, telefono: this.state.formMemoria.Telefono, email: this.state.formMemoria.Email, mensaje: 'este es una prueba', video: this.state.formMemoria.VideoMemoriaFinal[1], extencionVideo: this.state.formMemoria.VideoMemoriaFinal[0], foto: this.state.formMemoria.ImagenMemoriaFinal[1], extencionFoto: this.state.formMemoria.ImagenMemoriaFinal[0] },
                Token: this.state.Token, Refresh: this.handleChangeSingle, Url: UrlPostMemoria, Close: this.ModalMemoriaClose, SetError: this.setData
              }}
              ContentModal={
                <Container maxWidth='sm'>

                  <HorizontalLinearStepper Steps={this.state.ListHeadersStep} OnClickBarraProgreso={this.OnClickBarraProgreso}
                    ListContent={
                      [
                        <StepMemoriaPrimero OnChange={this.handleChangeMemoria} FormActualizar={this.state.formMemoria} />,
                        <StepMemoriaSegundo OnChange={this.handleChangeMemoria} DefaultValue={this.state.formMemoria.Tipo} />,
                        <StepMemoriaTercer HandleChangeSingle={this.handleChangeSingle} MensajePersonalizado={{ key: 'MensajePersonalizadoMemoria', function: this.SetMensajePersonalizado, MensajePersonalizado: this.state.MensajePersonalizadoMemoria }} OnChange={this.handleChangeMemoria} FormActualizar={this.state.formMemoria} Crops={<Crop Height='250px' Imagen={this.state.formMemoria.Fotografia} OnChange={{ Function: this.handleChangeSingle, Key: 'formMemoria', Name: 'ImagenCropMemoria' }} SetAspectRationMemoria={{ Function: this.handleChangeSingle, Key: 'formMemoria', Name: 'AspectRation' }} AspectRatio='' />} CropVideo={<VideoCrop CropRemotoVideo={this.state.CropRemotoVideo} InstrumentalFile={this.state.InstrumentalFile} OnChange={{ Function: this.handleChangeSingle, Key: 'formMemoria', Name: 'VideoCropMemoria' }} Source={this.state.formMemoria.Video} />} ListMensajes={ListMensajes} />,
                        <StepMemoriaCuarto  Sexo={this.state.DataNuevaMemoria.Caratula.sexo} FotografiaCrop={this.state.ImagenCropMemoria} FormActualizar={this.state.formMemoria} InstrumentalFile={this.state.InstrumentalFile} OnchangeVideoMemoria={{ Function: this.handleChangeSingle, Key: 'formMemoria' }} ListMensajes={ListMensajes} />
                      ]}
                    NoLabel={false} ActiveStep={this.state.ActiveStepMemoria} />

                  {
                    this.state.Error === ''
                      ? <></>
                      :
                      <Box mt='10px'>
                        <AlertMui Text={this.state.Error} Time={5000} Type="error" OnChange={() => this.setState({ Error: '' })} />
                      </Box>
                  }
                </Container>
              } Action={StepActionMemoria} />
            :
            <></>
        }

        {
          this.state.OpenModalCompartir === true
            ?
            <ModalBaseMui Title='Compartir' TitleWeight="400" OnClickClose={this.ShowModalCompartir} Open={this.state.OpenModalCompartir} FullScreen={false} FullWidth={true} MaxWidth='545px' NoLabel={false}
              ContentModal={<ContentCompartir OnChange={this.handleChangeCompartir} CompartirSeguimiento={this.CompartirSeguimiento} DefaultValue={TodoDataService.GetHost() + 'Conmemoraciones/' + this.state.DataNuevaMemoria.Conmemoracion.folio} FormActualizar={this.state.formCompartir} OnClickCompartir={this.CompartirUrl} />}
            />
            :
            <></>
        }


        <ModalBaseMui Origen='Nueva Conmemoracion' TitleWeight={400} Title='Nueva conmemoración' OnclickAction={TodoDataService.ValidarFolioCliente} OnclickActionData={{ Token: this.state.Token, Url: UlrConmemoracionCaratula, Url2: UrlPedidodCliente, Url3: UrlValidarConmemoracion, Data: form, Refresh: this.setData, OnClickClose: () => (this.setState({ OpenConmemoracion: false })) }} Open={this.state.OpenConmemoracion} FullScreen={false} OnClickClose={() => (this.setState({ OpenConmemoracion: false }))} FullWidth={true} MaxWidth='sm'
          ContentModal={

            this.state.ShowConsultaFolio === false
              ?
              <Box sx={{ width: { xs: 'auto%', sm: '80%' }, display: 'flex', flexDirection: 'column', margin: { xs: '3% 16px', sm: '3% 10%' } }}>
                <Typography width='100%' align="center" sx={{ margin: '0% 0% 8% 0%', fontFamily: 'Itaca', fontSize: '20px', color: '#3A3A3A' }} variant="body" >
                  Un espacio para recordar y celebrar la vida de tu ser querido con fotografías, videos y mensajes
                </Typography>
                <FormControl required fullWidth variant="standard" >
                  <BootstrapInput type='number' onChange={this.handleChange} name='PedidoClienteFolio' placeholder="Numero de folio*" defaultValue="" id="bootstrap-input3" />
                </FormControl>
                <Link  onClick={() => (this.setState({ ShowConsultaFolio: true,Error:'' }))} align="left" color='#D14081' sx={{marginTop:'5px', fontSize: '15px', fontFamily: 'Itaca ', '&:hover': { color: '#D95F96' } }} component="button" variant="body2" >
                  ¿Dónde encuentro mi No. de folio?
                </Link>
                {
                  this.state.Error === ''
                    ? <></>
                    :
                    <Box mt='10px'>
                      <AlertMui Text={this.state.Error} Time={5000} Type="error" OnChange={() => this.setState({ Error: '' })} />
                    </Box>
                }
              </Box>
              :
              <>
                <img src={ImagenFolio} alt="Imagen en el Pop-up" className="img-fluid" />
              </>
          }

          Action={
            <Box sx={{ margin: { xs: '0% 8px 5% 8px', sm: '0% 20% 5% 20%' }, width: { xs: '100%', sm: '80%' } }}>
              {
                this.state.ShowConsultaFolio === false
                ?
                <Buttonh FontWeight='600 !important' FontSize='16px' Type='submit' Text='Crear nueva conmemoración' Size='100%' Color='#fbeb04' BorderColor='2px solid #fbeb04' ColorHover='white' Textcolor='#000000' TextcolorHover='#575757' />
                :
                <Buttonh OnClick={() => (this.setState({ ShowConsultaFolio: false,Error:'' }))} FontWeight='600 !important' FontSize='16px' Type='reset' Text='Regresar' Size='100%' Color='#fbeb04' BorderColor='2px solid #fbeb04' ColorHover='white' Textcolor='#000000' TextcolorHover='#575757' />
              }
            </Box>
          }
        />


        {
          this.state.ValidatePedidoClienteFolio === true
            ?
            [
              { Label: 'Nueva Conmemoracion', Content: < StepConmemoracionPrimero FormActualizar={this.state.form} OnChange={this.handleChange} Action={StepAction} /> },
              { Label: 'Nueva Conmemoracion', Content: < StepConmemoracionSegundo MensajePersonalizado={{ key: 'MensajePersonalizado', function: this.SetMensajePersonalizado, MensajePersonalizado: this.state.MensajePersonalizado }} FormActualizar={this.state.form} OnChange={this.handleChange} Action={StepAction} Crops={<Crop Height='300px' Imagen={form.picture} OnChange={{ Function: this.handleChangeSingle, Key: 'form', Name: 'ImagenCrop' }} AspectRatio={16 / 9} />} ListMensajes={ListMensajes} /> },
              { Label: 'Nueva Conmemoracion', Content: < StepConmemoracionTercero Compartir={this.state.Compartir} RemotoVideo={this.state.RemotoVideo} Song={InstrumentalConmemoracion} Origen='Nueva Conmemoracion' OnclickAction={TodoDataService.peticionPost} DataAction={{ Token: this.state.Token, Url: UrlPostConmemoracion, PedidoCliente: this.state.PedidoCliente, SetError: this.setData, Refresh:()=>{this.PeticionConmemoraciones(); this.ShowConmemoracion ();}   }} DataFinado={this.state.DataFinado} FormActualizar={this.state.form} InstrumentalFile={this.state.InstrumentalFile} Action={StepAction} ContadorMemoria={this.state.formMemoria.ContadorMemoria} OnchangeVideoConmemoracion={{ Function: this.handleChangeSingle, Key: 'form', Name: 'VideoConmemoracion' }} DataMemoria={this.state.formMemoria} ListMensajes={ListMensajes} /> },
            ][this.state.ActiveStep].Content

            :
            this.state.DataConmemoracion !== ''
              ?
              this.state.NuevaMemoria === false
                ?
                <CarouselImagenes Result={this.state.Buscar} Search='nombreCompleto' ListImagen={this.state.DataConmemoracion} OnClickNueva={() => { this.setState({ OpenConmemoracion: true }); this.ShowConmemoracion(); }} ContenedorWidth='lg' Columnas={5} Filas={2} Espacio={10} OnClickNuevaMemoria={TodoDataService.GetNuevaMemoria} DataNuevaMemoria={{ Token: this.state.Token, Url: UrlMemorias, Refresh: this.setData, Close: this.ShowNuevaMemoria }} />
                : < StepConmemoracionTercero Compartir={this.state.Compartir} RemotoVideo={this.state.RemotoVideo} Song={InstrumentalConmemoracion} Origen='Nueva Memoria' DataNuevaMemoria={this.state.DataNuevaMemoria} OnclickAction={TodoDataService.peticionPost} DataAction={{ Token: this.state.Token, Url: UrlPostConmemoracion, PedidoCliente: this.state.PedidoCliente, SetError: this.setData }} DataFinado={this.state.DataNuevaMemoria.Caratula} FormActualizar={this.state.form} InstrumentalFile={this.state.InstrumentalFile} Action={StepActionNewMemoria} ContadorMemoria={this.state.formMemoria.ContadorMemoria} OnchangeVideoConmemoracion={{ Function: this.handleChangeSingle, Key: 'form', Name: 'VideoConmemoracion' }} DataMemoria={this.state.formMemoria} ListMensajes={ListMensajes} />
              : <div style={{ marginTop: '100%' }}></div>
        }

      </Container>

    );
  }
}

export default withRouter(Conmemoraciones);

