import React, { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
const AlertMui =({Text,OnChange='',Type='success',Time=2000}) => {
  const [showAlert, setShowAlert] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowAlert(false);
      if(OnChange!=='')
        {
         OnChange(); 
        }
      
    }, Time);

    return () => clearTimeout(timeout);
  }, []);

  return (
    showAlert && (
      <Alert sx={{padding:'0px',display:'flex', justifyContent:'center',width:'100%', border:'solid 1px', '& .MuiAlert-message':{padding:'3px'}, borderColor:Type==='success'?'rgb(76, 175, 80)':'rgb(239, 83, 80)'}}  icon={false} severity={Type}>
        <Typography padding={0} >{Text}</Typography>
      </Alert>
    )
  );
}

export default AlertMui;