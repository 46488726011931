import React, { useState } from 'react';

// -----------Importar Componentes de Terceros------------------
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// -------------Valores Constantes-------------------------------
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));




const ModalBaseMui = ({SavingProcess='',TitleAling,TitleWeight='700', OnclickAction, OnclickActionData, OnChange, OnClickClose, Open, FullScreen, MaxWidth, FullWidth, Scroll, Title, ContentModal, Action,Origen,ActionDirection='flex-end'}) => {

    const [validated, setValidated] = useState('false');

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();


        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            console.log('algo anda mal')
        }


        if (form.checkValidity() === true) {
            if(SavingProcess!=='')
                {
                    SavingProcess();
                }
               OnclickAction(OnclickActionData);
        }
        setValidated('');

    };


    return (
        <BootstrapDialog PaperProps={{ sx:{width:'100%',margin:'8px', padding:'0px',maxWidth:MaxWidth, border: '2px solid #F50087',borderRadius:'15px'}}} onClose={OnClickClose} aria-labelledby="customized-dialog-title responsive-dialog-title" open={Open} scroll={Scroll} maxWidth={MaxWidth} fullWidth={FullWidth} fullScreen={FullScreen}>
            <Box component="form"  validated={validated} onSubmit={handleSubmit} sx={{ mt: 0 }}>

                <IconButton
                    aria-label="close"
                    onClick={OnClickClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => '#F50087',
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <DialogTitle sx={{ fontWeight: "bold" }} textAlign={TitleAling} id="customized-dialog-title responsive-dialog-title">

                    <Typography width='100%' align="center" sx={{ fontFamily: 'Itaca ', fontSize: '28px', color: '#D14081', fontWeight:TitleWeight+' !important'}} variant="body" > {Title} </Typography>

                </DialogTitle>
                <DialogContent sx={{padding:'0px !important'}}>
                    {ContentModal}
                </DialogContent>
                <DialogActions sx={{display:'flex', justifyContent:{xs:'center',sm:ActionDirection}}}>
                    {Action}
                </DialogActions>
            </Box>
        </BootstrapDialog>
    );

}



export default ModalBaseMui;


ModalBaseMui.defaultProps = {
	TitleAling: "center"
};

