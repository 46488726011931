import React, { useEffect, useRef } from 'react';

const AudioPlayer = ({Source, isPlaying, volume, currentTime,  }) => {
  const audioRef = useRef(null);

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    audioRef.current.volume = volume;
  }, [volume]);

  useEffect(() => {
    audioRef.current.currentTime = audioRef.current.currentTime + currentTime;
  }, [currentTime]);



  return (
    <div>
      <audio ref={audioRef}  >
        <source src={Source} type="audio/mpeg"  />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

export default AudioPlayer;
