import styled from "styled-components";

const StyleDragArea = styled.div`

div{
  height: 100%
}


.btnRosa,.btnRosaGrande {
    background-color: #D14081;
    color: white !important;
    background-color: #D14081 !important;
    text-align: center;
    border-radius: 5px;
    font-weight: bold;
  }
  .btnRosa:hover,.btnRosaGrande:hover {
    background-color: white !important;
    color: #D14081 !important;
    border-radius: 5px;
    border-color: #D14081;
    transform: scale(1.1);
  }


  .btnAmarilloChico {
    background-color:#ffec00 !important;
    border-radius: 5px;
    font-size:22px !important;
    border: 1px solid #ffec00 !important;
    color: black;
    font-weight: 600 !important;
    width: 115px;
  }
  .btnAmarilloChico:hover {
    background-color: white !important;
    border: 1px solid #ffec00 !important;
    color: #484848 !important;
  }
  

  .btnAmarilloChicos {
    background-color:#ffec00 !important;
    border-radius: 5px;
    font-size:16px !important;
    border: 2px solid #ffec00 !important;
    color: #484848;
    font-weight: 500 !important;
    width: 100% !important;
  }
  .btnAmarilloChicos:hover {
    background-color: white !important;
    border: 2px solid #ffec00 !important;
    color: #484848 !important;
  }

  .btnAmarilloChicos:disabled {
    background-color: #c8cacb !important;
    border: 1px solid #c8cacb !important;
    color: #484848 !important;

  }
  

  .guardar{
    width: 200px !important;
  }

  @media (max-width: 576px) {
    .guardar{
      width: 100% !important;
      
    }
  }

  .editar{
    padding:0px !important;
    width: 80px !important;
  }

  .btnNoSeleccionado {
    background-color: white;
    border-radius: 5px;
    border: 1px solid white;
    color: #484848;
    font-size:16px !important
    font-weight: 500 !important;
    width: 100% !important;
}

  .g {
    width: 100% !important;
  }

  .btnNoSeleccionado:hover {
    background-color: white;
    border: 2px solid #ffec00;
    color: black;
  }

  .btn:focus {
    // outline: 0;
    // box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
}

.btn-rosa{
  background-color:white;
  color: var(--color-rosa);
  border: 1px solid var(--color-rosa);
 
}

.btnAmarillo,.btnAmarilloGrande {
  padding: 16px 24px;
  margin: 8px;
  border-radius: 40px;
  text-align: center;
  font-size: 22px;
  font-weight: 400;
  text-transform: uppercase;
  background: #ffeb00;
  color: #000;
  border: none;
  cursor: pointer;
}

.btnAmarillo:hover,.btnAmarilloGrande:hover {
  background-color: var(--color-amarilo);
  color: #fff;
  border: none;
  transition: 0.3s ease;
}
.btn_rosa {
  border-color: #D14081 !important ;
  background-color: #D14081 !important;
  color: white;
}
.btn_rosa:hover {
  background-color: white !important;
  border: #d14081 1px solid;
  color: #d14081;

}
  
.editar{
  width: 80px;height: 25px; padding: 6px;border-radius: 5px;
}

.datosbuttom{
  font-size: 15px !important;
  font-weight: 600 !important;
}

@media (max-width: 576px) {
  .validar{
    width: 150px !important;
    // text-align: end !important;

  }
}

@media (max-width: 576px) {
  .alingpago{
    text-align: center;
  }
}

`;


export default StyleDragArea;