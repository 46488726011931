//-------------Importar librerias----------------------------
import React from 'react';
import { useNavigate } from 'react-router-dom'

// -----------Importar Componentes de Terceros------------------
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';

// -----------Importar Componentes Personalizados------------------
import Buttonh from "../../../components/Button";



const ActionStepConmemoracion = ({Compartir, Conmemoracion,DataConmemoracion, OnClickRemotoVideo, OnClickCloseConmemoracion, OnClickRegresar, HandleNext, HandleBack, HandleReset, ActiveStep, StepsLength, FormActualizar, OnClickCancelar, OnClickConmemoracion, OnClickMemoria, OnClickCompartir, Origen }) => {


    // Navegación
    const navigate = useNavigate()
    const handleClick = (link) => {
        navigate(link)
    }

    return (
        ActiveStep + 1 === StepsLength
            ?
            <Box sx={{ width: '100%' }}>
                {
                    Origen === 'Nueva Memoria'
                        ?
                        <Grid sx={{ display: 'flex', alignItems: 'center' }} container columns={{ xs: 6, sm: 12, md: 12 }}>
                            <Grid sx={{ padding: { xs: '2% 0%', sm: '2%' } }} xs={6}>
                                <Buttonh Disabled={Compartir && Conmemoracion===false?true:false } FontWeight='600 !important' FontSize='16px' Text='Agregar Memoria' OnClick={OnClickMemoria} Size='100%' Color='#D14081' BorderColor='2px solid #D14081 !important' ColorHover='white' Textcolor='#FFFFFF' TextcolorHover='#D14081' />
                            </Grid>
                            <Grid sx={{ padding: { xs: '2% 0%', sm: '2%' } }} xs={6}>
                                <Button onClick={OnClickCompartir} variant='contained' sx={{ fontSize: '16px', fontWeight: '600 !important', textTransform: 'none', fontFamily: 'Itaca ', border: '2px solid #D14081 !important', height: '40px', borderRadius: '5px', width: '100%', background: '#D14081', color: '#FFFFFF', '&:hover': { backgroundColor: 'white', color: '#D14081', border: '2px solid #D14081 !important' }, }} endIcon={<ShareOutlinedIcon />}>
                                    Compartir
                                </Button>
                            </Grid>
                        </Grid>
                        :
                        <></>
                }
                {
                    Origen === 'Nueva Conmemoracion'
                        ?
                        <Grid container columns={{ xs: 6, sm: 12, md: 12 }} >

                            <Grid sx={{ padding: { xs: '2% 0%', sm: '2%' } }} xs={6}>
                                <Buttonh FontWeight='600 !important' FontSize='16px' Text='Editar' OnClick={HandleReset} Size='100%' Color='#fbeb04' ColorHover='white' Textcolor='#000000' BorderColor='2px solid #fbeb04 !important' TextcolorHover='#575757' />
                            </Grid>
                            <Grid sx={{ padding: { xs: '2% 0%', sm: '2%' } }} xs={6}>
                                <Buttonh FontWeight='600 !important' Disabled={FormActualizar.VideoConmemoracion === '' ? true : false} FontSize='16px' Text='Publicar' Type='submit' Size='100%' Color='#fbeb04' ColorHover='white' Textcolor='#000000' BorderColor='2px solid #fbeb04 !important' TextcolorHover='#575757' />
                            </Grid>
                        </Grid>
                        :
                        <></>
                }
                <Grid container columns={{ xs: 6, sm: 12, md: 12 }} >
                    <Grid sx={{ display: 'flex', justifyContent: 'center', padding: { xs: '2% 0%', sm: '2%' } }} xs={12}>
                        {/* <Link color='#909090' sx={{ width: '100%', fontSize: '16px', fontFamily: 'Itaca ', fontWeight: '600 !important' }} type='reset' component="button" variant="body2" onClick={()=>{OnClickCloseConmemoracion(); handleClick('/Conmemoraciones');}}>
                            Salir
                        </Link> */}
                        <Buttonh FontWeight='600 !important' FontSize='16px' Text='Salir' Size='100%' Color={Origen === 'Nueva Conmemoracion' ? '#fbeb04' : '#D14081'} OnClick={() => { OnClickCloseConmemoracion(); handleClick('/Conmemoraciones'); }} ColorHover='white' Textcolor={Origen === 'Nueva Conmemoracion' ? '#000000' : 'white'} BorderColor={Origen === 'Nueva Conmemoracion' ? '2px solid #fbeb04 !important' : '2px solid #D14081 !important'} TextcolorHover={Origen === 'Nueva Conmemoracion' ? '#575757' : '#D14081'} />

                    </Grid>
                </Grid>


            </Box>
            :
            ActiveStep === 0
                ?
                <Grid container sx={{ flexWrap: { xs: 'wrap-reverse', sm: '' }, alignItems: 'center', padding: '0px !mportant' }}>
                    <Grid xs={4} sm={6.5} ></Grid>
                    <Grid xs={3} sm={1.5} display={{ xs: 'none', sm: 'block' }}>
                        <Link color='#909090' sx={{ width: '100%', fontSize: '16px', fontFamily: 'Itaca ', fontWeight: '600 !important' }} component="button" variant="body2" onClick={OnClickCancelar}>
                            Cancelar
                        </Link>
                    </Grid>
                    <Grid xs={5} sm={2} display={{ xs: 'none', sm: 'block' }}>
                        <Link disabled={FormActualizar.picture === '' ? false : true} color='#D14081' sx={{ fontWeight: '600 !important', padding: '0px', marginRight: '0px', fontSize: '16px', fontFamily: 'Itaca ' }} component="button" variant="body2" onClick={HandleNext}>
                            Omitir por ahora
                        </Link>
                    </Grid>
                    <Grid xs={12} sm={2} display={{ xs: 'flex', sm: 'none' }} justifyContent='center'>
                        <Link color='#909090' sx={{  fontSize: '16px', fontFamily: 'Itaca ', fontWeight: '600 !important' }} component="button" variant="body2" onClick={OnClickCancelar}>
                            Cancelar
                        </Link>
                        <Link disabled={FormActualizar.picture === '' ? false : true} color='#D14081' sx={{ fontWeight: '600 !important', padding: '5px 10px', marginRight: '0px', fontSize: '16px', fontFamily: 'Itaca ' }} component="button" variant="body2" onClick={HandleNext}>
                            Omitir por ahora
                        </Link>
                    </Grid>
                    <Grid xs={12} sm={2}>
                        <Buttonh FontWeight='600 !important' FontSize='16px' Disabled={FormActualizar.picture === '' && ActiveStep === 0 ? true : false} Text='Siguiente' Size='100%' BorderColor='2px solid #fbeb04 !important' Color='#fbeb04' OnClick={HandleNext} ColorHover='white' Textcolor='#271F2F' TextcolorHover='#575757' />
                    </Grid>
                </Grid>
                :
                <Grid container sx={{ flexWrap: { xs: 'wrap-reverse', sm: '', marginTop: '2%' }, alignItems: 'center', padding: '0px' }}>
                    <Grid xs={12} sm={6} ></Grid>
                    <Grid xs={12} sm={3} sx={{ display: 'flex', justifyContent: 'center' }} >
                        <Link color='#D14081' sx={{ fontWeight: '600 !important', marginRight: { xs: '0px', sm: '15px' }, fontSize: '16px', fontFamily: 'Itaca ' }} component="button" variant="body2" onClick={HandleBack}>
                            Regresar
                        </Link>
                    </Grid>
                    <Grid xs={12} sm={3} sx={{ padding: { xs: '2% 0%', sm: '2%' }, margin: '0% 0% 0% 0%' }} >
                        <Buttonh FontWeight='600 !important' FontSize='16px' Disabled={FormActualizar.picture === '' && ActiveStep === 0 ? true : FormActualizar.mensaje === '' ? true : FormActualizar.mensaje === 50 ? FormActualizar.mensajepersonalizado !== '' ? false : true : false} Text='Siguiente' Size='100%' Color='#fbeb04' OnClick={HandleNext} BorderColor='2px solid #fbeb04 !important' ColorHover='white' Textcolor='#000000' TextcolorHover='#575757' />
                    </Grid>
                </Grid>

    )

}


export default ActionStepConmemoracion