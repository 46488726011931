// Importar componentes y estilos necesarios
import Accordion from 'react-bootstrap/Accordion'; // Importa el componente de Acordion de react-bootstrap
import Card from 'react-bootstrap/Card'; // Importa el componente de Card de react-bootstrap
import Checkbox from '@mui/material/Checkbox';
import { Typography } from '@mui/material';

// Componente Funcional FormularioFinado que recibe props
const FormularioFinado = ({ ListError, OnChange, FormFinado, OnChangeCobertura, ListEstados, ListMunicipios, ListColonias, StyleDragArea, Style, CustomToggles }) => {
    return (
        // Sección del acordeón que se despliega al activarse
        <Accordion.Collapse eventKey="1" id="panelsStayOpen-collapseTwo" aria-labelledby="panelsStayOpen-headingTwo" >
            <Card.Body style={{ backgroundColor: 'white', padding: '2px' }}>
                <Typography textAlign='left' mt='5px' mb={2} className='datoscomentarios' style={{ lineHeight: 'normal' }} >
                    Información relevante para iniciar tu servicio
                    funerario
                </Typography>

                {/* Formulario para ingresar datos del finado */}
                <div style={{ padding: '0px 5px', textAlign: 'left', fontSize: '16px' }} className="row mt-0 inputDatos">
                    <div className="col-sm-4">
                        {/* Input para ingresar nombre */}
                        <label htmlFor="inNombre_finado">Nombre(s)*</label>
                        <input style={{ border: ListError.find((element) => element.item === 'NombreFinado').error === true ? '3px solid rgb(239, 83, 80)' : '' }} onChange={OnChange} defaultValue={FormFinado.NombreFinado} name='NombreFinado' type="text" className="form-control" id="inNombre_finado" placeholder="Nombre(s)" />
                    </div>
                    {/* Se repite para Apellido Paterno y Materno */}
                    <div className="col-sm-4">
                        <label htmlFor="inApellidoPaterno_finado">Apellido paterno*</label>
                        <input style={{ border: ListError.find((element) => element.item === 'ApellidoPaternoFinado').error === true ? '3px solid rgb(239, 83, 80)' : '' }} onChange={OnChange} defaultValue={FormFinado.ApellidoPaternoFinado} name='ApellidoPaternoFinado' type="text" className="form-control" id="inApellidoPaterno_finado" placeholder="Apellido paterno" />
                    </div>
                    <div className="col-sm-4">
                        <label htmlFor="inApellidoMaterno_finado">Apellido materno*</label>
                        <input style={{ border: ListError.find((element) => element.item === 'ApellidoMaternoFinado').error === true ? '3px solid rgb(239, 83, 80)' : '' }} onChange={OnChange} defaultValue={FormFinado.ApellidoMaternoFinado} name='ApellidoMaternoFinado' type="text" className="form-control" id="inApellidoMaterno_finado" placeholder="Apellido materno" />
                    </div>
                    {/* Selector de género */}
                    <div className="col-sm-6">
                        <label htmlFor="inGenero_finado">Género*</label>
                        <select style={{ border: ListError.find((element) => element.item === 'Gernero').error === true ? '3px solid rgb(239, 83, 80)' : '' }} onChange={OnChange} defaultValue={FormFinado.Gernero} name='Gernero' className="form-control" id="inGenero_finado">
                            <option selected={FormFinado.Gernero === '' ? true : false} value="" >Selecciona</option>
                            <option selected={FormFinado.Gernero === 'H' ? true : false} value="H">Hombre</option>
                            <option selected={FormFinado.Gernero === 'M' ? true : false} value="M">Mujer</option>
                        </select>
                    </div>
                    {/* Input para fecha de nacimiento */}
                    <div onChange={OnChange} className="col-sm-6">
                        <label htmlFor="inFechaNacimiento_finado">Fecha de nacimiento*</label>
                        <input style={{ border: ListError.find((element) => element.item === 'FechaNacimiento').error === true ? '3px solid rgb(239, 83, 80)' : '' }} name='FechaNacimiento' defaultValue={FormFinado.FechaNacimiento} type="date" className="form-control" id="inFechaNacimiento_finado" placeholder="Fecha de nacimiento" />
                    </div>

                    {/* Input para nombre del lugar */}
                    <div className="col-sm-12">
                        <label htmlFor="inNombreLugar_finado">Nombre del lugar*</label>
                        <input style={{ border: ListError.find((element) => element.item === 'NombreLugar').error === true ? '3px solid rgb(239, 83, 80)' : '' }} onChange={OnChange} defaultValue={FormFinado.NombreLugar} name='NombreLugar' type="text" className="form-control" id="inNombreLugar_finado" placeholder="Nombre del lugar" />
                    </div>
                    {/* Input para dirección */}
                    <div className="col-sm-12">
                        <label htmlFor="inDireccion_finado">Dirección*</label>
                        <input style={{ border: ListError.find((element) => element.item === 'Direccion').error === true ? '3px solid rgb(239, 83, 80)' : '' }} onChange={OnChange} defaultValue={FormFinado.Direccion} name='Direccion' type="text" className="form-control" id="inDireccion_finado" placeholder="Dirección" />
                    </div>
                    {/* Inputs para Código Postal, Estado, Municipio y Colonia */}
                    <div className="col-sm-6">
                        <label htmlFor="inEstado">Estado*</label>
                        <select style={{ border: ListError.find((element) => element.item === 'Estado').error === true ? '3px solid rgb(239, 83, 80)' : '' }} id="inEstado" name='Estado' className="form-control" onChange={OnChangeCobertura} >
                            <option value='' selected={FormFinado.Estado === '' ? true : false}>Selecciona</option>
                            {/* Mapeo de opciones de estado */}
                            {
                                ListEstados.map((element) => (<option selected={FormFinado.Estado === element.estadoId.trim() ? true : false} key={element.estadoId.trim()} value={element.estadoId.trim()} >{element.nombre.trim()}</option>))
                            }
                        </select>
                    </div>
                    {/* Se repite para Municipio y Colonia */}
                    <div className="col-sm-6">
                        <label htmlFor="inMunicipio">Municipio*</label>
                        <select style={{ border: ListError.find((element) => element.item === 'Municipio').error === true ? '3px solid rgb(239, 83, 80)' : '' }} id="inMunicipio" name='Municipio' className="form-control" onChange={OnChangeCobertura} >
                            <option value="" selected={FormFinado.Municipio === '' ? true : false}>Selecciona</option>

                            {
                                ListMunicipios.length > 0
                                    ? ListMunicipios.map((element) => (<option selected={FormFinado.Municipio === element.municipioId.trim() ? true : false} key={element.municipioId.trim()} value={element.municipioId.trim()} >{element.nombre.trim()}</option>))
                                    : <></>
                            }
                        </select>
                    </div>
                    <div className="col-sm-6">
                        <label htmlFor="inColonia">Colonia*</label>
                        <select style={{ border: ListError.find((element) => element.item === 'Colonia').error === true ? '3px solid rgb(239, 83, 80)' : '' }} name='Colonia' id="inColonia" className="form-control" onChange={OnChangeCobertura} >
                            <option value="" selected={FormFinado.Colonia === '' ? true : false}>Selecciona</option>
                            {
                                ListColonias.length > 0
                                    ? ListColonias.map((element) => (<option selected={FormFinado.Colonia === element.asentamientoId.trim() ? true : false} key={element.asentamientoId.trim()} value={element.asentamientoId.trim()} >{element.nombre.trim()}</option>))
                                    : <></>
                            }
                        </select>
                    </div>
                    <div className="col-sm-6">
                        <label htmlFor="inCodigoPostal_finado">Código postal*</label>
                        <input type="text" value={FormFinado.CodigoPostal} className="form-control" id="inCodigoPostal_finado" placeholder="Código postal" disabled />
                    </div>

                    {/* Área de texto para referencias */}
                    <div className="col-sm-12">
                        <label htmlFor="inReferencias_finado">Referencias</label>
                        <StyleDragArea>
                            <textarea onChange={OnChange} id="inReferencias_finado" className="form-control" defaultValue={FormFinado.Referencias} name="Referencias" rows="4" cols="50" ></textarea>

                        </StyleDragArea>
                    </div>
                    {/* Checkbox para conmemoración */}
                    <div className="col-sm-6 mt-4">
                        <label htmlFor="inCrearConmemoracion_finado">Crear conmemoración*</label>
                        <label id="textRight" htmlFor="inCrearConmemoracion_finado" style={{ display: 'flex', alignItems: 'center' }} >
                            {/* <input checked={FormFinado.Conmemoracion} onChange={OnChange} name='Conmemoracion' value={true} type="checkbox" id="inCrearConmemoracion_finado" /> */}
                            <Checkbox sx={{ display: 'flex', alignItems: 'center', padding: '0px', color: '#FFEC00', '&.Mui-checked': { color: '#FFEC00' } }} value={true} onChange={OnChange} checked={FormFinado.Conmemoracion} name='Conmemoracion' id="inCrearConmemoracion_finado" />
                            <h6 className="h6Condiciones" style={{ margin: '0px' }} >Acepto</h6>
                        </label>
                    </div>
                    {/* Checkboxes para aceptar términos y condiciones */}
                    <div className=" col-sm-12 mt-1" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div>
                            <Checkbox sx={{ display: 'flex', alignItems: 'center', padding: '0px', color: ListError.find((element) => element.item === 'Terminos').error === true ? '#EF5350' : '#FFEC00', '&.Mui-checked': { color: '#FFEC00' } }} onChange={OnChange} checked={FormFinado.UsoInformacion} name='UsoInformacion' id="inAceptoInformacion_finado" />
                        </div>
                        <div>
                            <h6 className="h6Condiciones">
                                Acepto el uso de mi información personal y la recolección de la información para uso operacional de acuerdo al
                                <a className="linkA" > Aviso de Privacidad</a>
                            </h6>
                        </div>
                    </div>
                    <div className="col-sm-12 mt-1" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div>
                            <Checkbox sx={{ display: 'flex', alignItems: 'center', padding: '0px', color: ListError.find((element) => element.item === 'Terminos').error === true ? '#EF5350' : '#FFEC00', '&.Mui-checked': { color: '#FFEC00' } }} onChange={OnChange} checked={FormFinado.Terminos} className='custom-checkbox' name='Terminos' type="checkbox" id="inAceptoInformacion_finado" />
                        </div>
                        <div>
                            <h6 className="h6Condiciones">
                                Estoy de acuerdo con los términos establecidos en el
                                <a className="linkA" > contrato</a>
                            </h6>
                        </div>

                    </div>
                    {/* Botón para proceder al pago */}
                    <div className="col-sm-12 mt-1" style={{ textAlign: 'right' }}>
                        <div>
                            <Style>
                                <CustomToggles eventKey="0">Proceder al pago</CustomToggles>
                            </Style>
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Accordion.Collapse>
    );
}

export default FormularioFinado; // Exporta el componente FormularioFinado
