import * as React from 'react';

// -----------Importar Componentes de Terceros------------------
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CreateSvgIcon from '../Icons_Svg/index'


// -----------Importar Componentes Personalizados------------------
import icono_flujo_pasivo from '../../assets/Imagenes/Icono_Flujo_Pasivo.png'
import icono_flujo_activo from '../../assets/Imagenes/Icono_Flujo_Activo.png'


const HorizontalLinearStepper = ({ ListContent = [], OnClickBarraProgreso, Steps, NoLabel, ActiveStep, Action, FontSize, VisualOnly = false }) => {

  if (VisualOnly === true) {
    return (
      <Box sx={{ width: '100%' }}>

        {
          NoLabel === true
            ? <></>
            :
            <Stepper alternativeLabel activeStep={ActiveStep} sx={{
              "& .MuiStepConnector-line": {
                borderTopWidth: "4px",
                marginTop: '10px',
                borderColor: "#fbeb04",
                borderTopStyle: 'dotted'
              }, "& .MuiStepLabel-alternativeLabel .Mui-active": { color: '#F50087' }, "& .MuiStepLabel-alternativeLabel": { fontSize: FontSize }
            }} >
              {Steps.map((step, index) => {
                const stepProps = {};
                const labelProps = {};

                return (
                  <Step key={index} {...stepProps} >
                    {/* <StepLabel   {...labelProps} icon={ActiveStep + 1 <= index ? <CreateSvgIcon sx={{ fontSize: '15px', }} Imagen={icono_flujo_pasivo} FontSizeIconsW='35px' FontSizeIconsH='45px' /> : <CreateSvgIcon Imagen={icono_flujo_activo} FontSizeIconsW='40px' FontSizeIconsH='50px' />}> <Box sx={{color:ActiveStep + 1 <= index ?'rgba(0, 0, 0, 0.6)':' #F50087', fontSize: { xs: '8px', sm: FontSize } }} >{step.Label}</Box></StepLabel> */}
                    <StepLabel   {...labelProps} icon={ <CreateSvgIcon Imagen={icono_flujo_activo} FontSizeIconsW='35px' FontSizeIconsH='45px' />}> <Box sx={{color:ActiveStep + 1 <= index ?'rgba(0, 0, 0, 0.6)':' #F50087', fontSize: { xs: '8px', sm: FontSize } }} >{step.Label}</Box></StepLabel>

                  </Step>
                );
              })}
            </Stepper>
        }
      </Box>
    )
  }
  else {
    return (
      <Box sx={{ width: '100%' }}>

        {
          NoLabel === true
            ? <></>
            :
            <Stepper alternativeLabel activeStep={ActiveStep} sx={{
              "& .MuiStepConnector-line": {
                borderTopWidth: "4px",
                marginTop: '10px',
                borderColor: "#fbeb04",
                borderTopStyle: 'dotted',
              }, "& .MuiStepLabel-alternativeLabel .Mui-active": { color: '#F50087' }, "& .MuiStepLabel-alternativeLabel": { fontSize: FontSize, color: '#F50087' }
            }} >
              {Steps.map((step, index) => {
                const stepProps = {};
                const labelProps = {};
                let steper;
                if (step.Complect === true) {
                  steper = <StepLabel onClick={() => { OnClickBarraProgreso(index); }} sx={{ cursor: 'pointer !important' }}  {...labelProps} icon={<CreateSvgIcon Imagen={icono_flujo_activo} FontSizeIconsW='35px' FontSizeIconsH='45px' />}> <Box sx={{ fontSize: { xs: '9px', sm: FontSize } ,color: '#F50087' }} >{step.Label}</Box></StepLabel>
                }
                else {
                  if (Steps[index - 1] === undefined) {
                    steper = <StepLabel onClick={() => { OnClickBarraProgreso(index); }} sx={{ cursor: 'pointer !important' }}  {...labelProps} icon={<CreateSvgIcon Imagen={icono_flujo_activo} FontSizeIconsW='35px' FontSizeIconsH='45px' />}> <Box sx={{ fontSize: { xs: '9px', sm: FontSize },color: '#F50087'  }} >{step.Label}</Box></StepLabel>

                  }
                  else {
                    if (Steps[index - 1].Complect === true) {
                      steper = <StepLabel onClick={() => { OnClickBarraProgreso(index); }} sx={{ cursor: 'pointer !important' }}  {...labelProps} icon={<CreateSvgIcon Imagen={icono_flujo_activo} FontSizeIconsW='35px' FontSizeIconsH='45px' />}> <Box sx={{ fontSize: { xs: '9px', sm: FontSize },color: '#F50087'  }} >{step.Label}</Box></StepLabel>
                    }
                    else {
                      steper = <StepLabel sx={{ cursor: 'pointer'}}  {...labelProps} icon={<CreateSvgIcon sx={{ fontSize: '15px', }} Imagen={icono_flujo_pasivo} FontSizeIconsW='35px' FontSizeIconsH='45px' />}> <Box sx={{ fontSize: { xs: '9px', sm: FontSize },color:"rgba(0, 0, 0, 0.87)"}} >{step.Label}</Box></StepLabel>
                    }
                  }
                }

                return (
                  <Step sx={{padding:'0px'}} key={index} {...stepProps} >
                    {steper}
                  </Step>
                );
              })}
            </Stepper>
        }

        {ActiveStep < ListContent.length
          ? ListContent[ActiveStep]
          : <></>
        }
        {
          Action
        }
      </Box>
    );
  }





}

HorizontalLinearStepper.defaultProps = {
  FontSize: '16px'
}
export default HorizontalLinearStepper;