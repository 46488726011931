import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import './styles.css';

const CarruselProductos = ({ListImagen,AspectRation}) => {


  return (
    <Carousel width='100%'autoPlay={true}  showStatus={false} showArrows={false}>
      {
        ListImagen.map((item,index)=>(
        <div>
            <img style={{aspectRatio:AspectRation, borderRadius:'10px', border:'none',objectFit:'cover'}} key={index} alt={'Carrusel Productos0'+index} src={item} />
        </div>
      ))
      }
      
        
    </Carousel>
  );
}

export default CarruselProductos;