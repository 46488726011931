import * as React from 'react';
import Box from '@mui/material/Box';


const  CreateSvgIcon = ({ Imagen, FontSizeIconsW,FontSizeIconsH }) => {
  return (
    <Box
      sx={{
        '& > :not(style)': {
          m: 0,
        },
      }}
    >
      <img src={Imagen} alt='icono' height={FontSizeIconsH}  width={FontSizeIconsW} ></img>

    </Box>
  );
}

CreateSvgIcon.defaultProps = {  
  FontSizeIconsW: '40px' ,
  FontSizeIconsH: '40px' 

}

export default  CreateSvgIcon