// -----------Importar Componentes de Terceros---------------------
import Typography from '@mui/material/Typography';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


const CarritoMovil = ({ StyleDragArea, FormatearComoMonedaMXN, SetNombreSeleccionado, FormActualizar, Step_PersonalizaSet, Step_Extra, ListTypo, Step_Pago, SubTotal, SubTotalExtra, StepLenght, HandleNext,Desplegar, setDesplegar}) => {
    return (
        <div className="col-sm-12">

            <div style={{ padding: '5px' }} id="divVelacion">
                <h5 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '15px', marginBottom:'0px' }}>
                    Sets seleccionado: &nbsp; &nbsp;  <IconButton sx={{padding:'5px !important'}} onClick={() => (setDesplegar(!Desplegar))} size="small"><KeyboardArrowDownIcon sx={{ fontSize: '25px', color: '#D14081', rotate: Desplegar ? '180deg' : '0deg' }} /></IconButton>
                </h5>
                <Typography variant='h6' sx={{ fontSize: '20px !important' }}>
                    {SetNombreSeleccionado}  {FormActualizar.TipoDespedida === '' ? '' : ListTypo.find((element) => (element.id === FormActualizar.TipoDespedida)).Nombre}
                </Typography>
            </div>
            {
                Desplegar === true
                    ?
                    <>
                        <div id="divCarrito" className="mt-4 text-start">
                            {
                                Step_PersonalizaSet.ListCarrito.length > 0
                                    ?
                                    <>
                                        <h6>Productos incluidos</h6>
                                        <ul className='ListSeviciosIncluidos'>
                                            {
                                                Step_PersonalizaSet.ListCarrito.map((element, index) => {
                                                    let cantidadASumar = 0;
                                                    const productoEncontrado = Step_PersonalizaSet.ListProductos.find((producto) => (producto.producto === element.producto && producto.grupo === element.grupo));
                                                    if (productoEncontrado && productoEncontrado.valor_default === 1) {
                                                    } else {
                                                        const productosEnMismoGrupo = Step_PersonalizaSet.ListProductos.filter((producto) => (producto.grupo === element.grupo && producto.valor_default === 1));
                                                        cantidadASumar = element.precio_venta - productosEnMismoGrupo[0].precio_venta;
                                                    }
                                                    return (
                                                        <li key={index}>
                                                            <div className="row">
                                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="col-sm-12">
                                                                    <spam className={cantidadASumar !== 0 ? "c_rosa" : "c_negro"}>- 
                                                                        {element.nombre_prodcto}
                                                                    </spam>
                                                                    {
                                                                        cantidadASumar !== 0
                                                                            ?
                                                                            <button disabled={Step_Pago.FormPago.PedidoCliente !== '' ? true : false} type="button" onClick={() => (Step_PersonalizaSet.EliminarCarrito(element))} style={{ display: 'flex', alignItems: 'center', padding: 0, borderColor: 'white', verticalAlign: 'bottom' }} className="btn btn-rosa elimina">
                                                                                <Typography fontSize='12px'>{cantidadASumar !== 0 ? `+${FormatearComoMonedaMXN(cantidadASumar)}` : ""}</Typography> <DeleteOutlineOutlinedIcon />
                                                                            </button>
                                                                            : <></>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                        <h6>Servicios incluidos</h6>
                                        <ul className='ListSeviciosIncluidos'>
                                            <li>- Traslado de 30 km redondos</li>
                                            <li>- Trámites legales</li>
                                            <li>- Preservación</li>
                                            <li>- Conmemoración</li>
                                            <li>- Cremación</li>
                                        </ul>
                                        <div className="mt-3" style={{ display: 'flex', justifyContent: 'space-between' }} >
                                            <h6>
                                                Subtotal:
                                            </h6>
                                            <h6>{FormatearComoMonedaMXN(SubTotal)}</h6>
                                        </div>
                                    </>
                                    : <></>
                            }
                        </div>
                        {
                            Step_Extra.ListCarritoExtras.length > 0
                                ?
                                <div id="divCarrito_extras" className="mt-4 text-start">
                                    <>
                                        <h6>Productos Extra</h6>
                                        <ul className='ListSeviciosIncluidos'>
                                            {
                                                Step_Extra.ListCarritoExtras.map((element, index) => {
                                                    return (
                                                        <li>
                                                            <div className="row">
                                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="col-sm-12">
                                                                    <spam className="c_rosa">
                                                                        {`[${element.cantidad}] ${element.nombre_prodcto}`}
                                                                    </spam>
                                                                    <button disabled={Step_Pago.FormPago.PedidoCliente !== '' ? true : false} type="button" onClick={() => (Step_Extra.EliminarCarritoExtra(element))} className="btn btn-rosa elimina" style={{ display: 'flex', alignItems: 'center', padding: 0, borderColor: 'white', verticalAlign: 'bottom' }}>
                                                                        <Typography fontSize='12px'>{`+${FormatearComoMonedaMXN(element.precio_venta * element.cantidad)}`}</Typography> <DeleteOutlineOutlinedIcon />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                        <div className="mt-3" style={{ display: 'flex', justifyContent: 'space-between' }} >
                                            <h6 >Subtotal:</h6><h6>{FormatearComoMonedaMXN(SubTotalExtra)}</h6>
                                        </div>

                                    </>
                                </div>
                                :
                                <></>
                        }

                        {
                            Step_Pago.FormPago.Descuento > 0
                                ?
                                <div id="divCarrito_extras" className="mt-4 text-start">
                                    <>
                                        <h6>Descuentos</h6>
                                        <ul className='ListSeviciosIncluidos'>
                                            <li>- {Step_Pago.FormPago.FolioCupon}</li>
                                        </ul>
                                        <div className="mt-3" style={{ display: 'flex', justifyContent: 'space-between' }} >
                                            <h6>Subtotal: </h6><h6>-{FormatearComoMonedaMXN(Step_Pago.FormPago.Descuento)}</h6>
                                        </div>
                                    </>
                                </div>
                                :
                                <></>
                        }
                    </>
                    : <></>
            }



            <div id="divTotal" style={{ display: 'flex', justifyContent: ' space-between' }} >
                <p className='mb-0'>Total:</p>
                <p className='mb-0' > {FormatearComoMonedaMXN(SubTotal + SubTotalExtra - Step_Pago.FormPago.Descuento)}</p>
            </div>
            {
                StepLenght > 1
                    ?
                    <></>
                    :
                    Step_PersonalizaSet.ListGrupos.filter((element) => (element.select === false)).length > 0 || Step_PersonalizaSet.ListGrupos.length === 0
                        ?
                        <></>
                        :
                        <StyleDragArea>
                            <button disabled={Step_PersonalizaSet.ListCarrito.length > 0 ? false : true} style={{ width: '100%', display: 'block' }} id="btnSiguiente" onClick={() => { Step_Extra.ListProductosExtras.length === 0 ? Step_Extra.CargarProductosExtras() : HandleNext('StepLenght') }} className="btn btnAmarilloChico g mt-1" >Siguiente</button>
                        </StyleDragArea>
            }

        </div>
    );
}

export default CarritoMovil;


