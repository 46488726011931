//-------------Importar librerias----------------------------
import React, { useEffect, useState,useRef } from 'react';
import axios from 'axios';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

// -----------Importar Componentes de Terceros------------------
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/system/Unstable_Grid';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

// -----------Importar Componentes Personalizados------------------
import InstruccionPagoTransferencia from '../Instrucciones_PagoTransferencia';

// -----------Importar Archivos estatiscos------------------
import BBVA from '../../assets/Imagenes/Sets/BBVA.png'
import SANTANDER from '../../assets/Imagenes/Sets/Santander.png'
import CITIBANAMEX from '../../assets/Imagenes/Sets/Citibanamex.png'



const PagoTransferencia = ({ ContentRadion, RadioGroupImagen, PedidoCliente }) => {
    const [DataTrasferencia, setDataTrasferencia] = useState('')
    const [PdfPago, setPdfPago] = useState('')
    const contentRef = useRef(null);

    useEffect(() => {
        const GenerarTransferencia = async () => {
            const url = "https://sandbox-api.openpay.mx/v1/msciz3cejztuuxk9q4jk/charges";
            const username = "sk_e66dc1bf28c342429c826ec5eb4e55d0";
            const password = "";

            const chargeRequest = {
                'method': 'bank_account',
                'amount': 100, // Cantidad a cobrar en centavos (100 pesos)
                'currency': 'MXN', // Moneda
                'description': 'Cargo con banco',
                'order_id': `oid-${PedidoCliente.folio}`,
                'customer': {
                    'name': PedidoCliente.contactoCliente.nombre,
                    'last_name': PedidoCliente.contactoCliente.apPaterno + " " + PedidoCliente.contactoCliente.apMaterno,
                    'phone_number': PedidoCliente.contactoCliente.celular,
                    'email': PedidoCliente.contactoCliente.email,
                },
            };

            const headers = {
                Authorization: "Basic " + btoa(username + ":" + password),
                // 'Authorization': 'Bearer ' + username,
                "Content-Type": "application/json",
            };


            await axios.post(url, chargeRequest, {
                headers: headers,
            })
                .then((response) => {
                    setDataTrasferencia(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        if (DataTrasferencia === '') {
            GenerarTransferencia();
        }

    }, []);

    const copiarContenido = async (item) => {
        try {
            await navigator.clipboard.writeText(item);
            console.log('Contenido copiado al portapapeles');
        } catch (err) {
            console.error('Error al copiar: ', err);
        }
    };


    
    const generatePdf = () => {
        const input = document.getElementById('imagen');
        let maxWidth = 210; // Ancho de la página en mm (A4)
        const maxHeight = 297; // Largo de la página en mm (A4)

        html2canvas(input)
            .then((canvas) => {
                let imgWidth = canvas.width;
                let imgHeight = canvas.height;

                // Redimensionar la imagen si el alto es mayor que el largo de una hoja A4
                if (imgHeight > maxHeight) {
                    const ratio = maxHeight / imgHeight;
                    imgWidth *= ratio;
                    imgHeight *= ratio;
                    maxWidth = imgWidth < 210 ? imgWidth : 210
                }

                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();

                // Establecer márgenes en mm (A4)
                const marginLeft = 10;
                const marginTop = 10;
                const marginRight = 10;
                const marginBottom = 10;

                console.log(maxWidth)

                pdf.addImage(imgData, 'PNG', marginLeft, marginTop, maxWidth - marginLeft - marginRight, imgHeight -50 - marginTop - marginBottom);

                pdf.setProperties({
                    title: "MyTitle.pdf"
                });
                setPdfPago(pdf)
                
            })
            .catch((error) => {
                console.error('Error generating PDF:', error);
            });
    };


    const OnChangePago = (e) => {
        if (e.target.value === '0') { 
            window.open( `https://web.whatsapp.com/send?text=${'Clave Interbancaria:'+DataTrasferencia.payment_method.name}`, "_blank");
         }
        if (e.target.value === '1') { PdfPago.save('converted.pdf'); }
        if (e.target.value === '2') {
            window.open('mailto:' + "?subject=Contratacion%20de%20servicio&body=Clave%20Interbancaria:%20"+DataTrasferencia.payment_method.name, "_blank");
            //  + encodeURIComponent(Email) + "?subject=Contratacion%20de%20servicio&body=Estoy%20interesado%20en%20contratar"
         }
        if (e.target.value === '3') {
            window.open(PdfPago.output('bloburl'));
        }

    }

    console.log(DataTrasferencia);

    return (

        <Box>

            <div id="imagen" ref={contentRef} >
                <Typography variant='h4' sx={{ color: '#D14081', fontSize: '25px', fontWeight: '600 !important' }}> Instrucciones para realizar el pago</Typography>


                <InstruccionPagoTransferencia />
                {
                    DataTrasferencia !== ''
                        ?
                        < Box sx={{ height:{xs:'100%',sm:'258px'}, padding: '0px !important', margin: '24px 15px 24px 15px !important', border: 'solid 2px grey', borderRadius: '10px', boxShadow: PdfPago === '' ? 'none' : '5px 5px 0px 3px #D14081' }}>

                            <Grid container sx={{ margin: '10px 0px' }} >
                                <Grid xs={12} sm={2}></Grid>
                                <Grid container rowSpacing={3} xs={12} textAlign='center' sm={8}>
                                    <Grid xs={12} sm={5}><Typography >Banco</Typography></Grid>
                                    <Grid xs={12} sm={5} ><Typography >Clabe interbancaria</Typography></Grid>
                                    <Grid xs={12} sm={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'center', sm: 'flex-start' } }}>
                                        <img alt='LOGO SANTANDER' src={SANTANDER} />
                                    </Grid>
                                    <Grid xs={12} sm={7} sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'space-evenly', sm: 'space-between' } }}>
                                        <Typography>{DataTrasferencia.payment_method.name}</Typography>
                                        <IconButton onClick={() => (copiarContenido(DataTrasferencia.payment_method.name))} sx={{ backgroundColor: '#D14081', color: 'white' }}><ContentCopyIcon /></IconButton>
                                    </Grid>
                                    <Grid xs={12} sm={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'center', sm: 'flex-start' } }}>
                                        <img alt='LOGO BBVA'  src={BBVA} />
                                    </Grid>
                                    <Grid xs={12} sm={7} sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'space-evenly', sm: 'space-between' } }}>
                                        <Typography>{DataTrasferencia.payment_method.name}</Typography>
                                        <IconButton onClick={() => (copiarContenido(DataTrasferencia.payment_method.name))} sx={{ backgroundColor: '#D14081', color: 'white' }}><ContentCopyIcon /></IconButton>
                                    </Grid>
                                    <Grid xs={12} sm={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'center', sm: 'flex-start' } }}>
                                        <img alt='LOGO CITIBANAMEX' onLoad={generatePdf} src={CITIBANAMEX} />
                                    </Grid>
                                    <Grid xs={12} sm={7} sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'space-evenly', sm: 'space-between' } }}>
                                        <Typography>{DataTrasferencia.payment_method.name}</Typography>
                                        <IconButton onClick={() => (copiarContenido(DataTrasferencia.payment_method.name))} sx={{ backgroundColor: '#D14081', color: 'white' }} ><ContentCopyIcon /></IconButton>
                                    </Grid>
                                </Grid>


                                <Grid xs={12} sm={2}></Grid>
                            </Grid>
                        </Box>

                        :
                        <></>
                }
            </div>

            <Box mb='24px'>
            <RadioGroupImagen WidthImagen='50%' Origen='Row' Name='TipoPdf' Width='md' OnChange={OnChangePago} JustifyContent='space-between' Content={ContentRadion} />

            </Box>


        </Box>
    )
}

export default PagoTransferencia;