import React from 'react';

// -----------Importar Componentes de Terceros------------------
import ImageListItem from '@mui/material/ImageListItem';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styled from "styled-components";
import Button from '@mui/material/Button';

// -----------Importar Archivos Estaticos------------------
import Paloma_Blanca from '../../../assets/Imagenes/Paloma_blanca.png'


const CarouselElemento = ({ OnClick, Contenido, DataNuevaMemoria }) => {

  const StyleDragArea = styled.div` 
    .containers {
        position: relative;
        width: 100%;
        margin:0%
        pading:0%
      }
      
      .image {
        display: block;
        width: 100%;
        height: auto;
      }
      
      .overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: hidden;
        width: 100%;
        height: 100%;
        opacity: 0; /* La superposición comienza invisible */
        background-color: #FFEC00;
        border-radius: 20px;
        transition: opacity 1s; /* Transición para el efecto de fadeIn */
    }
      
      .containers:hover .overlay {
        opacity: 1;
        bottom: 0;
        height: 100%;
        margin:0%
        pading:0%
      }
    `;

  return (

      <StyleDragArea>
        <div style={{cursor:'pointer'}} onClick={() => (OnClick(DataNuevaMemoria, Contenido.Caratula, Contenido.Conmemoracion.folio))}  className='containers'>
          <ImageListItem sx={{ border: '2px solid #F50087', borderRadius: '23px' }}>
            <img width="100%" style={{ borderRadius: '20px', aspectRatio: '1', filter: 'sepia(0.9)' }} alt='Caratula conmemoracion' src={Contenido.Caratula.foto} />
          </ImageListItem>

          <div className='overlay' >
            <Box height='40%' width="100%" >
              <ImageListItem sx={{ marginTop: '5%', marginLeft: '27.5%', width: '45%' }} >
                <img src={Paloma_Blanca} width='50%' alt='icono paloma blanca' />
              </ImageListItem>

            </Box>
            <Box textAlign='center' height='30%' width="100%" > <Typography sx={{ fontFamily: 'Itaca ', fontWeight: '600 !important', lineHeight:'normal',  fontSize: { xs: '14px', sm: '24px', md: '23px', lg: Contenido.Caratula.nombreCompleto.length > 24 ? '16px' : '20px' }, paddingLeft: 1, paddingRight: 1 }} variant="h6" gutterBottom> {Contenido.Caratula.nombreCompleto}</Typography> </Box>

            <Box align='center' height='30%' width="100%" ><Typography sx={{ fontFamily: 'Itaca ', fontWeight: '600 !important', paddingTop: '5%', paddingLeft: 1, fontSize: { xs: '18px', xl: '16px' }, paddingRight: 1, color: '#D14081' }} variant="h6" gutterBottom>{Contenido.Caratula.agnoNacimiento} - {Contenido.Caratula.agnoDefuncion}</Typography> </Box>
          </div>
        </div>

      </StyleDragArea>


    // </Button>

  );

}

export default CarouselElemento;