
// -----------Importar Componentes de Terceros------------------
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

// -----------Importar Componentes Personalizados------------------
import Buttonh from "../../../components/Button";
import BootstrapInput from "../../../components/Button_Bootstrap";
import RadioGroupImagen from '../../../components/RadioGroup-Imagen';

// -----------Importar Archivos Estaticos------------------
import Facebook from "../../../assets/Imagenes/Facebook.png"
import FacebookBN from "../../../assets/Imagenes/FacebookBN.png"
import Whatsapp from "../../../assets/Imagenes/Whatsapp.png"
import WhatsappBN from "../../../assets/Imagenes/WhatsappBN.png"
import Twitter from "../../../assets/Imagenes/Twiter.png"
import TwitterBN from "../../../assets/Imagenes/TwiterBN.png"

import Email from "../../../assets/Imagenes/Email.png"
import EmailBN from "../../../assets/Imagenes/EmailBN.png"


// -------------Valores Constantes-------------------------------
   

const ContentCompartir = ({OnChange,DefaultValue,FormActualizar,OnClickCompartir}) => {

 const ContentRadion = [
        { Id:'https://www.facebook.com/sharer/sharer.php?u='+DefaultValue, IconPasivo:FacebookBN , IconActivo:Facebook , Disabled: false },
        { Id:'https://web.whatsapp.com/send?text='+DefaultValue, IconPasivo:WhatsappBN , IconActivo: Whatsapp, Disabled: false },
        { Id: 'https://twitter.com/intent/tweet?text='+DefaultValue, IconPasivo:TwitterBN , IconActivo: Twitter, Disabled: false },
        { Id: 'mailto:?subject=Compartir%20Conmemoracion&body='+DefaultValue, IconPasivo:EmailBN , IconActivo: Email, Disabled: false },
        
    ]

    console.log(FormActualizar)

    return (
        <Container maxWidth='sm'>

            <Box textAlign='center' sx={{ marginBottom: '3%'}}>
                <Container maxWidth='xs' sx={{padding:{xs: '0px',sm:'0px '} }}>
                    <RadioGroupImagen Origen='Row' Top='0px' ReverseSelect={true} WidthImagen='75%'  OnChange={OnChange} Name='SelectCompartir' Width='sm' JustifyContent='center' Content={ContentRadion} />
                </Container>
                
            </Box>
            <FormControl fullWidth variant="standard">
                <BootstrapInput disabled defaultValue={DefaultValue}   Color='pink' required={true} type='text' name='Url' placeholder={FormActualizar.Url===''?'Url':FormActualizar.Url} id="bootstrap-input1" />
            </FormControl>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '3%' }}>
                <Box sx={{ width: '30%' }}>
                    <Buttonh OnClick={OnClickCompartir} FontSize='14px' Disabled={FormActualizar.SelectCompartir===''?true:false} Text='Compartir' Size='100%' Color='#fbeb04' ColorHover='white' Textcolor='#000000' TextcolorHover='#575757' BorderColor='2px solid #ffec00 !important' />
                </Box>
            </Box>
        </Container>

    )
}

export default ContentCompartir;
