import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import Shop from '../../assets/Imagenes/Shop.png'
import Barra from '../../assets/Imagenes/Bara.png'
import Money from '../../assets/Imagenes/Money.png'
import call  from '../../assets/Imagenes/Call.png'
import Receipt from '../../assets/Imagenes/Receipt.png'



const InstruccionPagoEfectivo = () =>{
    return (
        <Box>
            <Box sx={{marginTop:'15px',display: 'flex',flexDirection:'row', alignItems: 'center' }}>
                <img src={Shop} height='35px' alt='shop'/> <Typography fontSize='16px' fontFamily='Itaca' display="inline" sx={{marginLeft:'15px'}}>Acuda cualquier  <Typography fontSize='16px' fontFamily='Itaca' display="inline" sx={{color:'#D14081'}}> tienda afiliada o banco </Typography> de su conveniencia. </Typography>
            </Box>
            <Box sx={{marginTop:'15px',display: 'flex',flexDirection:'row', alignItems: 'center' }}>
                <img src={Barra} height='35px' alt='Barra'/> <Typography fontSize='16px' fontFamily='Itaca' display="inline"  sx={{marginLeft:'15px'}}>Entregue el <Typography fontSize='16px' fontFamily='Itaca' display="inline" sx={{color:'#D14081'}}>código de barra o dicte número </Typography>según se encuentre. </Typography>
            </Box>
            <Box sx={{marginTop:'15px',display: 'flex',flexDirection:'row', alignItems: 'center' }}>
                <img src={Money} height='35px' alt='Money'/> <Typography fontSize='16px' fontFamily='Itaca' display="inline"  sx={{marginLeft:'15px'}}>Efectúe el pago en tienda con <Typography fontSize='16px' fontFamily='Itaca' display="inline" sx={{color:'#D14081'}}>efectivo o tarjeta.</Typography> </Typography>
            </Box>
            <Box sx={{marginTop:'15px',display: 'flex',flexDirection:'row', alignItems: 'center' }}>
                <img src={call} height='35px' alt='call'/> <Typography fontSize='16px' fontFamily='Itaca' display="inline"  sx={{marginLeft:'15px'}}>Para iniciar con su servicio, comuníquese al <Typography fontSize='16px' fontFamily='Itaca' display="inline" sx={{color:'#D14081'}}>000-000-0000.</Typography> </Typography>
            </Box>
            <Box sx={{marginTop:'15px',display: 'flex',flexDirection:'row', alignItems: 'center' }}>
                <img src={Receipt} height='35px' alt='Receipt'/> <Typography fontSize='16px' fontFamily='Itaca' display="inline" sx={{marginLeft:'15px'}}>Conserve su <Typography fontSize='16px' fontFamily='Itaca' display="inline" sx={{color:'#D14081'}}>comprobante.</Typography></Typography>
            </Box>
        </Box>
        
    );
}

export default InstruccionPagoEfectivo;