import React, { useState } from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';


const RadioGroupImagenFormaPago = ({item,value,WidthImagen}) => {
    const [hovered, setHovered] = useState(false);

    return(
        <FormControlLabel disabled={item.Disabled} sx={{ width:'32.5%', borderRadius: '10px', border: hovered? ' solid 2px #FFEC00' :value === item.Id ? ' solid 2px #FFEC00': 'none', backgroundColor: value === item.Id ? '#ffffff' : hovered?'#ffffff':'#819B9F', margin: '0px 0px 10px 0px', display: 'flex', justifyContent: 'center'}} key={item.Id} value={item.Id} control={<Radio onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} checkedIcon={<img src={item.IconActivo} alt="paypal activo" style={{ width: WidthImagen }} />} icon={<img src={hovered === true ? item.IconActivo : item.IconPasivo} alt="paypal pasivo" style={{ width: WidthImagen }} />} />} label='' />
    );
}
    

export default RadioGroupImagenFormaPago