import React, { useState, useRef, useEffect } from 'react'
import Cropper from 'react-easy-crop'

// -----------Importar Componentes de Terceros------------------
import IconButton from '@mui/material/IconButton';
import SyncIcon from '@mui/icons-material/Sync';
import CropFreeIcon from '@mui/icons-material/CropFree';
import Typography from '@mui/material/Typography';

// -----------Importar Archivos Estaticos------------------
import getCroppedImg from './cropImage'

// -----------Importar Archivos CSS------------------
import './styles.css'


const Crop = ({ Imagen, AspectRatio, OnChange, Height, SetAspectRationMemoria }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [aspectRatio, setAspectRatio] = useState(0)
  const [showZoomHint, setShowZoomHint] = useState(false)
  const [showMultiTouchHint, setShowMultiTouchHint] = useState(false)
  const [removeTouchAction, setRemoveTouchAction] = useState(false)
  const zoomTimeoutRef = useRef()
  const touchTimeoutRef = useRef()
  const [rotation, setRotation] = useState(0)
  const [imagen, setImagen] = useState('')
  const [croppedImage, setCroppedImage] = useState(null)


  useEffect(() => {
    clearTimeout(zoomTimeoutRef.current)
    setAspectRatio(AspectRatio === '' ? 1 : AspectRatio)
    clearTimeout(touchTimeoutRef.current)
    setImagen(URL.createObjectURL(Imagen))
  }, [])

  const onCropComplete = async (croppedArea, croppedAreaPixels) => {
    showCroppedImage(croppedAreaPixels);
  }

  const onWheelRequest = (e) => {
    // require the CTRL/⌘ key to be able to zoom with wheel
    if (e.ctrlKey || e.metaKey) {
      setShowZoomHint(false)
      return true
    }
    setShowZoomHint(true)
    clearTimeout(zoomTimeoutRef.current)
    zoomTimeoutRef.current = setTimeout(() => setShowZoomHint(false), 2000)
    return false
  }
  const onTouchRequest = (e) => {
    // require 2 fingers to be able to interact with the image
    if (e.touches.length > 1) {
      setShowMultiTouchHint(false)
      setRemoveTouchAction(true)
      return true
    }
    setShowMultiTouchHint(true)
    setRemoveTouchAction(false)
    clearTimeout(touchTimeoutRef.current)
    touchTimeoutRef.current = setTimeout(
      () => setShowMultiTouchHint(false),
      2000
    )
    return false
  }

  const showCroppedImage = async (croppedAreaPixelss) => {
    try {
      const croppedImage = await getCroppedImg(
        imagen,
        croppedAreaPixelss,
        rotation
      )
      setCroppedImage(croppedImage)
      if (croppedImage !== null) {
        OnChange.Function(URL.createObjectURL(croppedImage), OnChange.Key, OnChange.Name);
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <div className="Main" >
      <div style={{ height: Height, borderRadius:'5px' }} className="crop-container">
        <Cropper
        className='reactEasyCrop_CropArea'
        style={{borderRadius:'20px'}}
          image={imagen}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          aspect={aspectRatio}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          onWheelRequest={onWheelRequest}
          onTouchRequest={onTouchRequest}
          classes={
            removeTouchAction && { containerClassName: 'removeTouchAction' }
          }
        />
        {showZoomHint && (
          <div className="zoom-hint">
            <p>Use ⌘ + scroll (or ctrl + scroll) to zoom the image</p>
          </div>
        )}
        {showMultiTouchHint && (
          <div className="touch-hint">
            <p>Use 2 fingers to interact with the image</p>
          </div>
        )}
      </div>
      <div style={{padding: '10px' }} className="controls">
        <IconButton sx={{marginBottom: '18%'}} className='bottonGirar'  onClick={() => { setRotation(rotation === 270 ? 0 : rotation + 90) }} aria-label="Girar" size="large">
          <SyncIcon sx={{ position: 'absolute', zIndex: 3, width: '35px', height: '35px', color: (theme) => '#F50087' }} />
          <Typography></Typography>
        </IconButton>
        {
          AspectRatio === ''
            ?
            <>
              <IconButton onClick={() => { setAspectRatio(1); SetAspectRationMemoria.Function('1', SetAspectRationMemoria.Key, SetAspectRationMemoria.Name); }} aria-label="1" size="large">
                <CropFreeIcon sx={{ position: 'absolute', zIndex: 3, width: '90%', height: '90%', color: (theme) => '#F50087' }} />
                <Typography sx={{fontSize:'10px', color: '#D14081' ,padding:'2px'}}>1:1</Typography>
              </IconButton>
              <IconButton onClick={() => { setAspectRatio(16 / 9); SetAspectRationMemoria.Function('16/9', SetAspectRationMemoria.Key, SetAspectRationMemoria.Name); }} aria-label="16/9" size="large">
                <CropFreeIcon sx={{ position: 'absolute', zIndex: 3, width: '90%', height: '90%', color: (theme) => '#F50087' }} />
                <Typography sx={{fontSize:'10px', color: '#D14081',padding:'1px'}}>16:9</Typography>
              </IconButton>
            </>
            : <></>
        }


      </div>
    </div>
  )
}


export default Crop; 