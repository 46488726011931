import React from 'react';

// -----------Importar Componentes de Terceros------------------
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';


const SelectMui = ({ HandleChangeSingle, data, OnChange, name, MensajePersonalizado, defaultselect }) => {

    const handleChange = (event) => {
        if (event.target.value === 50) {
            MensajePersonalizado.function(true, MensajePersonalizado.key);
            OnChange(event);
        }
        else {
            MensajePersonalizado.function(false, MensajePersonalizado.key);
            OnChange(event);
        }

    }

    const onKeyDown = (event) => {
        if (event.key === 'Enter') {
            HandleChangeSingle(true, 'formMemoria', 'MensajeValidate')
        }
    }


    return (
        <>
            <FormControl sx={{ width: { xs: '100%', sm: '100%' } }}>
                <Select sx={{ padding: '0p', "& .MuiSelect-icon": { color: '#D14081' }, '& .MuiSelect-select': { padding: '10px' }, fontSize: { xs: '13px', sm: '16px' } }} width='100%' displayEmpty onChange={handleChange} name={name} value={defaultselect[name] === '' ? '' : defaultselect[name]} inputProps={{ 'aria-label': 'Without label' }}>
                    <MenuItem value="">
                        <em>
                            <Typography color='#717171' fontSize='16px' fontFamily='Itaca ' display="inline" >Selecciona o</Typography> <Typography color='#D14081' fontSize='16px' fontFamily='Itaca ' display="inline">escribe tu mensaje.</Typography>
                        </em>
                    </MenuItem>
                    {
                        data.map((item, index) => (
                            <MenuItem sx={{ fontSize: { xs: '13px', sm: '16px' } }} key={index} value={item.value}>{item.label}</MenuItem>
                        ))
                    }

                </Select>
            </FormControl>

            {
                MensajePersonalizado.MensajePersonalizado === true
                    ?
                    <>
                        <textarea className="form-control mt-2" onKeyDown={onKeyDown} onChange={OnChange} width='100%' name='mensajepersonalizado' defaultValue={defaultselect.mensajepersonalizado} placeholder='Mensaje' id="bootstrap-input1"
                            multiline
                            rows={3}></textarea>
                    </>

                    : <></>
            }
        </>



    );
}


export default SelectMui