import React, { useState } from 'react';

// -----------Importar Componentes de Terceros------------------
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import RadioGroupImagenFormaPago from './Forma_Pago';


export default function RadioGroupImagen({Top='24px', Origen = '', Name, Content, Width, JustifyContent = 'space-between', OnChange, DefaultValue, ReverseSelect = false, WidthImagen = '100%' }) {
  const [value, setValue] = useState('');

  const handleChange = (event) => {
    OnChange(event);
    setValue(parseInt(event.target.value));
  };


  return (

    <Container maxWidth={Width} sx={{ padding: '0px 12px !important' }}>

      <FormControl name={Name} sx={{ marginTop: Top, width: '100%' }}>
        <RadioGroup onChange={handleChange} aria-labelledby="demo-row-radio-buttons-group-label" name={Name} defaultValue={DefaultValue}>
          <Box sx={{ display: Origen === 'Row' ? 'block' : { xs: 'none', sm: 'block' } }}>
            <Box width='100%' sx={{ display: 'flex', justifyContent: JustifyContent }}>
              {
                Content.map((item, index) => {
                  if (Origen === 'FormaPago') {
                    return (<RadioGroupImagenFormaPago item={item} value={value} WidthImagen={WidthImagen} />)
                  }
                  else{
                    return(<FormControlLabel sx={{ margin: '0px' }} disabled={item.Disabled} key={item.Id} value={item.Id} control={<Radio sx={{ padding: '0px' }} checkedIcon={<img src={item.IconActivo} alt="paypal activo" style={{ width: WidthImagen }} />} icon={<img src={ReverseSelect === false ? item.IconPasivo : value === '' ? item.IconActivo : item.IconPasivo} alt="paypal pasivo" style={{ width: WidthImagen }} />} />} label='' />)
                  }
                })
              }
            </Box>
          </Box>
          <Box sx={{ display: Origen === 'Row' ? 'none' : { xs: 'block', sm: 'none' } }}>
            <Box width='100%' sx={{ display: 'flex', flexDirection: 'column' }}>
              {

                Content.map((item, index) => {
                  if (Origen === 'FormaPago') {
                    return (<FormControlLabel disabled={item.Disabled} sx={{ borderRadius: '10px', border: value === item.Id ? ' solid 2px #FFEC00' : 'none', backgroundColor: value === item.Id ? '#ffffff' : '#819B9F', margin: '0px 0px 10px 0px', display: 'flex', justifyContent: 'center' }} key={item.Id} value={item.Id} control={<Radio  checkedIcon={<img src={item.IconActivo} alt="paypal activo" style={{ width: WidthImagen }} />} icon={<img src={ item.IconPasivo} alt="paypal pasivo" style={{ width: WidthImagen }} />} />} label='' />)
                  }
                  else {
                    return (<FormControlLabel disabled={item.Disabled} sx={{ margin: 0, display: 'flex', justifyContent: 'center' }} key={item.Id} value={item.Id} control={<Radio checkedIcon={<img src={item.IconActivo} alt="paypal activo" style={{ Width: WidthImagen }} />} icon={<img src={ReverseSelect === false ? item.IconPasivo : value === '' ? item.IconActivo : item.IconPasivo} alt="paypal pasivo" style={{ width: WidthImagen }} />} />} label='' />)
                  }
                }
                )
              }
            </Box>
          </Box>
        </RadioGroup>
      </FormControl>
    </Container>



  );
}
