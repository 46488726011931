// -----------Importar Componentes de Terceros------------------
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styled from "styled-components";

// -----------Importar Componentes Personalizados------------------
import SelectMui from '../../../components/Select_Mui';
import MemoriaFotografia from '../../../assets/Imagenes/MemoriaFotografia.png'
import MemoriaVideo from '../../../assets/Imagenes/MemoriaVideo.png'

const StepMemoriaTercer = ({ HandleChangeSingle, OnChange, FormActualizar, MensajePersonalizado, Crops, CropVideo, ListMensajes }) => {
    console.log(FormActualizar.Tipo)
    const StyleDragArea = styled.div`
      .center {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .file-upload-content {
        display: none;
        text-align: center;
      }
    
      .file-upload-input {
        position: absolute;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        outline: none;
        opacity: 0;
        cursor: pointer;
      }
    
      .image-upload-wrap {
        position: relative;
        height: 170px;
        border: 4px solid #EBEBEB;
        margin-left: 0px;
        margin-right: 0px;
        border-radius: 10px;
        background-color:#EBEBEB;
      }
    
      .image-upload-wrap:hover {
        background-color: #F1F1F1;
      }
      .text-information {
        margin-top: 20px;
        text-align: center;
        color:#D14081;
      }
    `;

    console.log(FormActualizar);

    return (

        <Box>

            {
                FormActualizar.Tipo === '0'
                    ?
                    <Box sx={{ width: '100%', margin: '5% 0% 10px 0%' }}>
                        <SelectMui HandleChangeSingle={HandleChangeSingle} MensajePersonalizado={MensajePersonalizado} defaultselect={FormActualizar} OnChange={OnChange} name='Mensaje' data={ListMensajes} error='Debe de seleccionar alguno' requiere={true} />
                    </Box>

                    : <></>
            }
            {
                FormActualizar.Tipo === '1'
                    ?
                    FormActualizar.Fotografia === ''
                        ?
                        <div style={{ width: '100%', margin: '2% 0% 0% 0%' }}>
                            <Box textAlign='center' sx={{ marginBottom: '2%' }}>
                                <Typography color='#717171' fontSize='20px' fontFamily='Itaca ' display="inline" >Sube </Typography> <Typography color='#D14081' fontSize='20px' fontFamily='Itaca ' display="inline">la fotografía </Typography> <Typography fontSize='20px' fontFamily='Itaca ' display="inline" >de tu ser querido </Typography>
                            </Box>
                            <StyleDragArea>
                                <div className="image-upload-wrap">
                                    <input
                                        name='Fotografia'
                                        className="file-upload-input"
                                        type="file"
                                        accept="image/png, image/jpeg"
                                        multiple
                                        onChange={OnChange}
                                    />
                                    <div className="center">
                                        <img style={{ marginTop: '5%' }}
                                            src={MemoriaFotografia}
                                            alt="MemoriaFotografia"
                                            height="70px"
                                            width="70px"
                                        />
                                    </div>
                                    <div className="text-information">
                                        <h3 >Explorar o arrastra una foto aquí</h3>
                                    </div>

                                </div>
                            </StyleDragArea>
                        </div>
                        :
                        <div style={{ width: '100%', margin: '10px 0% 0% 0%' }}>
                            <Box textAlign='center' sx={{ marginBottom: '5px' }}>
                                <Typography color='#717171' fontSize='20px' fontFamily='Itaca ' display="inline" >Ajusta la</Typography> <Typography color='#D14081' fontSize='20px' fontFamily='Itaca ' display="inline">Fotografia.</Typography>
                            </Box>
                            {Crops}

                            <Box sx={{ width: '100%', margin: '15px 0% 5px 0%' }}>
                            <SelectMui HandleChangeSingle={HandleChangeSingle}  MensajePersonalizado={MensajePersonalizado} defaultselect={FormActualizar} OnChange={OnChange} name='Mensaje' data={ListMensajes} error='Debe de seleccionar alguno' requiere={true} />
                        </Box>
                        </div>
                    : <></>
            }
            {
                FormActualizar.Tipo === '2'
                    ?
                    (FormActualizar.Mensaje === '' || FormActualizar.Mensaje === 50) && (FormActualizar.mensajepersonalizado === '' || FormActualizar.MensajeValidate === false)
                        ? <Box sx={{ width: '100%', margin: '2% 0% 0% 0%' }}>
                            <Box textAlign='center' sx={{ marginBottom: '2%' }}>
                                <Typography color='#717171' fontSize='20px' fontFamily='Itaca ' display="inline" >Selecciona o</Typography> <Typography color='#D14081' fontSize='20px' fontFamily='Itaca ' display="inline">escribe tu mensaje.</Typography>
                            </Box>
                            <SelectMui HandleChangeSingle={HandleChangeSingle} MensajePersonalizado={MensajePersonalizado} defaultselect={FormActualizar} OnChange={OnChange} name='Mensaje' data={ListMensajes} error='Debe de seleccionar alguno' requiere={true} />
                        </Box>
                        : FormActualizar.Video === ''
                            ?
                            <div style={{ width: '100%', margin: '10px 0% 0% 0%' }}>
                                <Box textAlign='center' >
                                    <Typography color='#717171' fontSize='20px' fontFamily='Itaca ' display="inline" >Sube </Typography> <Typography color='#D14081' fontSize='20px' fontFamily='Itaca ' display="inline">el video </Typography> <Typography fontSize='20px' fontFamily='Itaca ' display="inline" >de tu ser querido </Typography>
                                </Box>
                                <StyleDragArea>
                                    <div className="image-upload-wrap">
                                        <input
                                            name='Video'
                                            className="file-upload-input"
                                            type="file"
                                            accept="video/mp4"
                                            multiple
                                            onChange={OnChange}
                                        />

                                        <div className="center">
                                            <img style={{ marginTop: '5%' }}
                                                src={MemoriaVideo}
                                                alt="MemoriaVideo"
                                                height="70px"
                                                width="70px"
                                            />
                                        </div>

                                        <div className="text-information">
                                            <h3 >Explorar o arrastra un video aquí</h3>
                                        </div>

                                    </div>
                                </StyleDragArea >
                            </div>
                            :
                            <div style={{ marginTop: '2%' }}>
                                <Box textAlign='center' sx={{ marginBottom: '3%' }}>
                                    <Typography color='#717171' fontSize='20px' fontFamily='Itaca ' display="inline" >Recorta tu</Typography> <Typography color='#D14081' fontSize='20px' fontFamily='Itaca ' display="inline">video.</Typography>
                                </Box>
                                {CropVideo}
                            </div>
                    : <></>
            }

        </Box>

    );
}

export default StepMemoriaTercer