import React, { useState, useEffect } from 'react';
import styled from "styled-components";

// Importar componentes y estilos necesarios
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
// import ModalBaseMui from '../../../components/Modal_Mui';
import FormularioResponsable from '../../../components/Form_Reponsable';
import FormularioFinado from '../../../components/Form_Finado';
import AlertMui from '../../../components/Alert_Mui';

// import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

// Importar estilos CSS adicionales
import '../css/datos.css'
import { Typography } from '@mui/material';

// Estilos adicionales con styled-components
const StyleDragArea = styled.div`
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }

  input[type=number] { -moz-appearance:textfield; }

  textarea:focus {
      background-color: white;
      color: black;
      border: 1px solid white !important;
      outline: none !important;
      outline-color: var(--color-amarilo) !important;
      box-shadow: 0 0 0 0.1rem var(--color-amarilo) !important;
    }
`;






// Componente principal de la sección de datos personales
const StepDatosPersonales = ({Token,OnClickGenerarCliente, HandleChangeSingle,HandleNext, Style, ListEstados, ListMunicipios, ListColonias, CodigoPostal, FormFinado, FormResponsable, OnChange, OnChangeCobertura, SwitchHandleChange }) => {
    const InicializacionResponsable =
        [
            { id: 0, item: 'Nombre', label: 'Nombre', error: false },
            { id: 1, item: 'ApellidoPaterno', label: 'Apellido Paterno', error: false },
            { id: 2, item: 'ApellidoMaterno', label: 'Apellido Materno', error: false },
            { id: 3, item: 'TelefonoPrimario', label: 'Telefono', error: false },
            { id: 4, item: 'TelefonoSecundario', label: 'Telefono 2', error: false },
            { id: 5, item: 'Email', label: 'Email', error: false },
            { id: 6, item: 'EmailConfirmar', label: 'Confirmar Email', error: false }]
        ;
    const InicializacionFinado =
        [
            { id: 0, item: 'NombreFinado', label: 'Nombre', error: false },
            { id: 1, item: 'ApellidoPaternoFinado', label: 'Apellido Paterno', error: false },
            { id: 2, item: 'ApellidoMaternoFinado', label: 'Apellido Materno', error: false },
            { id: 3, item: 'Gernero', label: 'Genero', error: false },
            { id: 4, item: 'FechaNacimiento', label: 'Fecha Nacimiento', error: false },
            { id: 5, item: 'NombreLugar', label: 'Nombre Lugar', error: false },
            { id: 6, item: 'Direccion', label: 'Direccion', error: false },
            { id: 7, item: 'Estado', label: 'Estado', error: false },
            { id: 8, item: 'Municipio', label: 'Municipio', error: false },
            { id: 9, item: 'Colonia', label: 'Colonia', error: false },
            { id: 10, item: 'Terminos', label: 'Terminos', error: false },
            { id: 11, item: 'UsoInformacion', label: 'UsoInformacion', error: false },
        ]
        ;
    const [ShowEditar, setShowEditar] = useState(false)
    const [ShowModal, setShowModal] = useState(false)
    const [Error, setError] = useState('')
    const [ListInputResponsable, setListInputResponsable] = useState(InicializacionResponsable)
    const [ListInputFinado, setListInputFinado] = useState(InicializacionFinado);

    const RefreshError = () => {
        setListInputResponsable(InicializacionResponsable);
        setListInputFinado(InicializacionFinado);
        setError([]);
        setShowModal(false);
    }


    


    // Componente para el botón de editar dentro del accordion
    function CustomToggleEdit({ children, eventKey }) {
        const decoratedOnClick = useAccordionButton(eventKey, () =>
            SwitchHandleChange('FormResponsable'),
        );



        return (
            <button
                type="button"
                style={{ fontSize: '10px' }}
                className="btn btn-primary btnAmarilloChicos seleccion editar"
                onClick={decoratedOnClick}
            >
                {children}
            </button>
        );
    }



    
    // Función para validar los datos de la persona responsable
    const Validate = () => {
        let error = []
        let listinputresponsable = ListInputResponsable;
        let listinput = []


        listinputresponsable.forEach((element) => {
            let copyelement = element;
            if (FormResponsable[element.item] === '') {
                error.push(`EL campo ${element.label} deben de ser completado`);
                copyelement.error = true
                listinput.push(copyelement);
            }
            else {
                listinput.push(copyelement);
            }
        })

        if (FormResponsable.TelefonoPrimario !== '' && FormResponsable.TelefonoSecundario !== '') {
            if (FormResponsable.TelefonoPrimario === FormResponsable.TelefonoSecundario) {
                error.push('Telefonos deben de ser diferentes');
                listinputresponsable.find((element)=>element.item==='TelefonoPrimario').error = true;
                listinputresponsable.find((element)=>element.item==='TelefonoSecundario').error = true;
                setListInputResponsable(listinputresponsable);
            }
            else {
                if (FormResponsable.TelefonoPrimario.length < 10) {
                    error.push('El Teléfono no es un numero valido');
                    listinputresponsable.find((element)=>element.item==='TelefonoPrimario').error = true;
                    setListInputResponsable(listinputresponsable);
                }
                if (FormResponsable.TelefonoSecundario.length < 10) {
                    error.push('El Teléfono 2 no es un numero valido');
                    listinputresponsable.find((element)=>element.item==='TelefonoSecundario').error = true;
                    setListInputResponsable(listinputresponsable);
                }
            }

        }

        if (FormResponsable.Email !== FormResponsable.EmailConfirmar) {
            error.push('Email no coincide');
        }

        if (FormResponsable.Email.includes('@') && FormResponsable.Email.includes('.com')) { }
        else { error.push('Ingresa un correo electronico valido'); }
        if (error.length > 0) {
            setError(error);
            setShowModal(true);
            setListInputResponsable(listinput)
            return false
        }
        else {
            return true;
        }

    }

    // Función para validar los datos del finado
    const ValidateFinado = () => {
        let error = []
        let listinput = [];


        ListInputFinado.forEach((element) => {
            let copyelement = element;
            if (FormFinado[element.item] === '' || FormFinado[element.item] === false) {
                error.push(`EL campo ${element.label} deben de ser completado`);
                copyelement.error = true
                listinput.push(copyelement);
            }
            else {
                listinput.push(copyelement);
            }
        })



        if (error.length > 0) {
            setError(error);
            setShowModal(true);
            setListInputFinado(listinput)
            return false
        }
        else {
            return true
        }

    }

    // Componente personalizado para el toggle del accordion
    function CustomToggle({ children, eventKey }) {
        const decoratedOnClick = useAccordionButton(eventKey, () => {
            setShowEditar(true);
            SwitchHandleChange('FormFinado');
            return (console.log('totally custom!'))
        });

        return (
            <div className='alingpago'>
                <button type="button" style={{ fontSize: '15px !important' }} className="btn btn-primary btnAmarilloChicos guardar" onClick={() => { Validate() === true ? decoratedOnClick() : console.log('') }}>
                    {children}
                </button>
            </div>

        );
    }

    const ErrorGenerar = (item) => {
        console.log(item);
        if (item.length > 0) {
            let listinputresponsable = ListInputResponsable;
            listinputresponsable.find((element) => element.item === 'Email').error = true
            listinputresponsable.find((element) => element.item === 'EmailConfirmar').error = true

            setListInputResponsable(listinputresponsable);
            setError(item);  
            setShowModal(true);
            SwitchHandleChange('FormResponsable');

        }
        
    }

    const GenerarCliente = () =>{
        let validatefinado = ValidateFinado();
        if(validatefinado === true)
            {
                OnClickGenerarCliente(FormResponsable,HandleChangeSingle,ErrorGenerar,HandleNext,Token);
            }
            return validatefinado;
    }

    // Componente personalizado para el toggle del botón de siguiente
    function CustomToggles({ children, eventKey }) {
        let validate= false;
        const decoratedOnClick = useAccordionButton(eventKey
            // validate= GenerarCliente(),
        );

        const  validatefinado = () => {
            if(GenerarCliente()===true)
                {
                    decoratedOnClick();
                }
        }
        return (
            <div className='alingpago'>
                <button type="button" style={{ width: '250px', fontWeight: '700 !important' }} className="btn btn-primary btnAmarilloChicos guardar" onClick={validatefinado}>
                    {children}
                </button>
            </div>

        );
    }


    // Efecto para cambiar el formulario a FormResponsable al montar el componente
    useEffect(() => {
        SwitchHandleChange('FormResponsable');
    }, []);

    React.useEffect(() => {

        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        };

        scrollToTop();
    }, []);


    console.log(ListInputResponsable);
    return (
        <Box className="col-sm-12 " marginBottom={{xs:'140px',sm:'0px'}}>

            {
                ShowModal === true
                    ?
                    <Box mt='10px' mb='10px'><AlertMui Text={
                        Error.length > 3
                        ?
                        'Validar los campos'
                        :
                        Error.map((item, index) => (
                            <h5 key={index} style={{ textAlign: 'center' }}>{item}</h5>
                        ))
                    } OnChange={RefreshError} Time={5000} Type='error' /> </Box>
                    :
                    <></>
            }

            {/* Accordion para mostrar los datos */}
            <Accordion defaultActiveKey="0" id="accordionPanelsStayOpenExample">
                {/* Panel para los datos de la persona responsable */}
                <Card style={{ border: 'none', margin: '0px', padding: '0px', }}>
                    <Card.Header style={{ margin: '0px', padding: '0px', backgroundColor: 'white', border: 'none' }} id="panelsStayOpen-headingOne">
                        {/* <button className="accordion-button" type="button" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne" style={{ boxShadow: 'none', backgroundColor: 'white' }} > */}
                        <Box sx={{ display: 'flex' }}>
                            <Typography variant='h6' fontSize='24px' lineHeight='normal' textAlign='justify !important' mr={1} className="datossubtitulo">
                                Datos de la persona responsable
                            </Typography>
                            {
                                ShowEditar === true
                                    ?
                                    <Style>
                                        <CustomToggleEdit eventKey="0">Editar</CustomToggleEdit>
                                    </Style>
                                    :
                                    <></>
                            }
                        </Box>

                        
                        {/* </button> */}
                    </Card.Header>

                    {/* Contenido del panel */}
                    {
                        ListInputResponsable[0]
                            ? <FormularioResponsable ListError={ListInputResponsable} StyleDragArea={StyleDragArea} Style={Style} CustomToggle={CustomToggle} FormResponsable={FormResponsable} OnChange={OnChange} Accordion={Accordion} />

                            : <></>
                    }

                </Card>
                {/* Panel para los datos de la persona finada */}
                <Card style={{ border: 'none', margin: '0px', padding: '0px', marginTop: '10px' }}>
                    <Card.Header style={{ margin: '0px', padding: '0px', backgroundColor: 'white', border: 'none' }} id="panelsStayOpen-headingTwo">
                        {/* <button style={{ backgroundColor: 'white', boxShadow: 'none' }} className="accordion-button" type="button" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo"> */}
                        <Typography variant='h6' fontSize='24px' lineHeight='normal' textAlign='justify !important' className="datossubtitulo">
                            Datos de la persona finada
                            
                        </Typography>
                        {/* </button> */}
                    </Card.Header>
                    {/* Contenido del panel */}
                    {
                        ListInputFinado[0]
                            ? <FormularioFinado ListError={ListInputFinado} OnChange={OnChange} FormFinado={FormFinado} OnChangeCobertura={OnChangeCobertura} ListEstados={ListEstados} ListMunicipios={ListMunicipios} ListColonias={ListColonias} StyleDragArea={StyleDragArea} Style={Style} CustomToggles={CustomToggles} />
                            : <></>
                    }
                </Card>
            </Accordion >
        </Box>
    );
}

export default StepDatosPersonales;