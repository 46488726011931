// -----------Importar Componentes Personalizados------------------
import React, { useEffect } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Button from '@mui/material/Button';

// Importación de componentes de terceros desde Material-UI
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';


// Este componente muestra una lista de tipos de despedida y permite al usuario seleccionar uno.
const StepPersonalizaSet = ({ TypoDespedida, ListTypo, ListGrupos, ListProductos, ListCarrito, SeleccionarDespedida, OnClickShowAgregarCarrito, Style }) => {

    // Log para depuración, muestra la lista de grupos en la consola.
    React.useEffect(() => {
        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        };
        scrollToTop();
    }, []);    

   

    // Retorna el JSX que define la estructura del componente.
    return (
        <Box marginBottom={{xs:ListGrupos.filter((element) => (element.select === false)).length > 0 || ListGrupos.length === 0?TypoDespedida === ''?'0px':'120px':'170px',sm:'0px'}}>
            {/* Sección para seleccionar el tipo de despedida */}
            <div className="row ">
                {/* Título para la sección de selección del tipo de despedida */}
                <Typography mb={1} variant='h4' sx={{ color: '#D14081', fontSize: '25px', fontWeight: '600 !important' }} display="inline">
                   
                    Selecciona el tipo de despedida
                    {/* Icono de marca de verificación, aparece si se ha seleccionado un tipo de despedida */}
                    <CheckCircleIcon  sx={{fontSize:'20px', color: '#b1366e', display: TypoDespedida === '' ? 'none' : 'inline' }} /> 
                    
                </Typography>
                {/* Mapea la lista de tipos de despedida para mostrar cada uno */}
                {ListTypo.map((typo) => (
                    <div style={{ marginBottom: '10px', display: 'flex', flexDirection: 'column' }} key={typo.id} className="col-sm-6">
                        <div style={{ cursor: 'pointer',flex: '0 0 auto' }} onClick={() => (SeleccionarDespedida(typo.id))} className="row">
                            <div className={typo.id === TypoDespedida ? "col-sm-12  " : "col-sm-12  selectimg "}>
                                {/* Imagen representativa del tipo de despedida */}
                                <img style={{objectFit:'cover', aspectRatio: '2', margin: '0px', width: "100%", borderRadius: '20px' }} alt='despedida' src={typo.Imagen} className={typo.id === TypoDespedida ? 'seleccionado' : ''} />
                            </div>
                        </div>
                        <div style={{ marginTop: '10px' , padding:'0% 15%',flex: '1'}} className="col-sm-12 text-center">
                            <Style style={{height:'100%'}}>
                                {/* Botón para seleccionar el tipo de despedida */}
                                <Button sx={{height:'100% !important',textTransform:'none'}} id="btnTipoDos" onClick={() => (SeleccionarDespedida(typo.id))} className={typo.id === TypoDespedida ? 'btn btnAmarilloChicos ' : "btn btnNoSeleccionado "}>
                                    {typo.Nombre} 
                                </Button>
                            </Style>
                        </div>
                    </div>
                ))}
            </div>
            {/* Sección para mostrar los productos disponibles agrupados por categoría */}
            {
                // Si hay grupos y productos disponibles, muestra la lista de productos agrupados por categoría
                ListGrupos.length > 0 && ListProductos.length > 0
                    ? ListGrupos.map((Grupo, index) => (
                        <>

                            {/* Título del grupo de productos */}
                            <div style={{ display: index === 0 ? 'block' : ListGrupos[index - 1].select === true ? 'block' : 'none' }} className="col-sm-12 ">
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                    {/* Título del grupo */}
                                    <Typography variant='h4' sx={{ fontSize: '25px', fontWeight: '600 !important', margin: '0px' }} className="subtitulo">{Grupo.nombre_grupo}</Typography>
                                    {/* Icono de marca de verificación, aparece si el grupo ha sido seleccionado */}
                                    <CheckCircleIcon sx={{fontSize:'20px',  color: '#b1366e', display: Grupo.select === true ? 'block' : 'none' }} />
                                </div>
                            </div>
                            <div style={{height:'100%'}} className='row' key={index}>
                                {/* Mapea la lista de productos del grupo actual */}
                                {ListProductos.filter((element) => (element.grupo === Grupo.grupo)).map((item) => (
                                    <div key={item.producto} style={{ marginBottom: '10px', display: index === 0 ? 'block' : ListGrupos[index - 1].select === true ? 'block' : 'none' }} className="col-sm-4" id={"div_" + item.producto}>
                                        <div className="" style={{height:'100%', display: 'flex', flexDirection: 'column',}}>
                                            <div onClick={() => (OnClickShowAgregarCarrito(item))} style={{ cursor: 'pointer',flex: '0 0 auto' }} className={ListCarrito.find(element => element.producto === item.producto) && Grupo.select === true ? "col-sm-12" : "col-sm-12 selectimg "}>
                                                {/* Imagen del producto */}
                                                <img style={{objectFit:'cover', width: '100%', borderRadius: '20px' }} id={"img_" + item.producto} src={item.img[0]} className={ListCarrito.find(element => element.producto === item.producto) && Grupo.select === true ? 'card-img-top aspectRatio seleccionado ' : "card-img-top aspectRatio"} alt={item.nombre} />
                                            </div>
                                            <div style={{ marginTop: '10px',padding:'0% 10%',flex: '1' }} className="col-sm-12 text-center button-container">
                                                <Style style={{height:'100%'}}>
                                                    {/* Botón para agregar el producto al carrito */}
                                                    <Button sx={{height:'100% !important',textTransform:'none'}} className={ListCarrito.find(element => element.producto === item.producto) && Grupo.select === true ? 'btn btnAmarilloChicos g' : "btn btnNoSeleccionado g"} id={"btn_" + item} onClick={() => (OnClickShowAgregarCarrito(item))}>{item.nombre}</Button>
                                                </Style>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>

                    ))
                    // Si no hay grupos o productos disponibles, no muestra nada
                    : <></>
            }
            
        </Box>
    );
}

// Exporta el componente StepPersonalizaSet
export default StepPersonalizaSet;
