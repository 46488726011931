// Importación de librerías y componentes necesarios para el componente SeguimientoDetalle
import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import * as htmlToImage from 'html-to-image';

// Componentes de terceros
import Box from '@mui/material/Box';
import ScreenShareOutlinedIcon from '@mui/icons-material/ScreenShareOutlined';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

// Componentes personalizados
import ModalBaseMui from "../../../components/Modal_Mui";
import TablaMui from "../../../components/Tabla";
import HorizontalLinearStepper from '../../../components/Stepper'
import SelectCompartirRedes from './Select';

// Estilos CSS
import '../css/style.css'

// Definición del componente funcional SeguimientoDetalle
const SeguimientoDetalle = ({ Origen, OnClickReturn, Host, DataForm, CompartirSeguimiento, DataCaratula, DataSeguimiento, DataConmemoracion, ShowModalCompartir, ModalCompartir, StyleDragArea, ListOperaciones }) => {
  // Definición de estados utilizando hooks
  const [ImagenSeguimiento, setImagenSeguimiento] = useState('');
  const [ModalCompartirSeguimiento, setModalCompartirSeguimiento] = useState(false);
  // const [FotoUrl, setFotoUrl] = useState('');
  const RefTablaSeguimiento = useRef();
  const [DataOperaciones, setDataOperaciones] = useState(0);

  // Función para mostrar el modal de compartir seguimiento
  const ShowModalCompartirSeguimiento = async () => {
    await htmlToImageConvert();
    setModalCompartirSeguimiento(!ModalCompartirSeguimiento);
  }

  // Función para convertir base64 a blob
  const b64ToBlob = async (b64) => {
    const blob = await fetch(b64);
    return await blob.blob();
  };

  // Función para copiar imagen
  const CopyImg = async (imgBlob) => {
    try {
      navigator.clipboard.write([
        new ClipboardItem({
          'image/png': imgBlob,
        })
      ]);
    } catch (error) {
      console.error(error);
    }
  }

  // Función para convertir HTML a imagen
  const htmlToImageConvert = async () => {
    const dataUrl = await b64ToBlob(await htmlToImage.toPng(RefTablaSeguimiento.current, { cacheBust: false }));
    CopyImg(dataUrl);
    setImagenSeguimiento(dataUrl)
  };

  // Efecto para obtener datos de la tabla
  useEffect(() => {
    const GetDataTabla = () => {
      let listoperaciones = ListOperaciones.map((item) => (item.Label === 'Cremacion' ? { id: item.id, tarea_Nombre: item.Label, ListTareas: DataSeguimiento.tareas.filter((element) => (element.operacion.trim() === item.id && element.mostrar_en_seguimiento === true)) } : { id: item.id, tarea_Nombre: item.Label, ListTareas: DataSeguimiento.tareas.filter((word) => (word.operacion.trim() === item.id && word.mostrar_en_seguimiento === true)).sort((x, y) => x.tarea - y.tarea) }));
      let list = listoperaciones.map((operacion, index) => ({ id: operacion.id, tarea_Nombre: operacion.tarea_Nombre, ListTareas: operacion.ListTareas, Visibility: index === 0 ? listoperaciones[index].ListTareas.length !== 0 ? true : false : listoperaciones[index - 1].ListTareas.length === 0 ? false : true }))
      let Indice = list.findIndex(object => object.Visibility === false);
      let listfull = list.map((operacion, index) => ({ id: operacion.id, tarea_Nombre: operacion.tarea_Nombre, ListTareas: operacion.ListTareas, Visibility: Indice < 0 ? true : index < Indice ? list[index].Visibility : false }))
      let ListOperacionesFilter = listfull.filter((element) => (element.ListTareas.length !== 0 && element.Visibility !== false))
      let result = 0

      if (ListOperacionesFilter.length !== 0) {
        result = ListOperacionesFilter.map((item) => { return (item ? { Visibility: item.Visibility, fecha_inicio: (item.ListTareas.at(-1).fecha_inicio).replace('T', ' '), tarea_Nombre: item.tarea_Nombre, notas: item.ListTareas.at(-1).notas, ListTareas: item.ListTareas } : {}) })
      }
      setDataOperaciones(result);
    };

    GetDataTabla();

  }, []);



  // Función para capitalizar una cadena
  const capitalize = str =>
    str.split(' ')
      .map(([first, ...rest]) => [first.toUpperCase(), ...rest].join(''))
      .join(' ');

  // Función para capitalizar cada palabra de una cadena
  const capitalizeEachWord = (text) => {
    const words = text.toLowerCase().split(" ");
    const wordsCapitalized = words.map(word => capitalize(word));
    return wordsCapitalized.join(" ");
  }

  // Navegación
  const navigate = useNavigate()
  const handleClick = (link) => {
    navigate(link)
  }

  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-9">
            <div className="row" style={{ display: 'flex', alignContent: 'space-between', alignItems: 'center', marginTop: '48px', marginBottom: '24px' }}>
              <div className="col-xs-8 col-sm-6">
                <Typography variant='h2' sx={{ fontSize: '40px', color: '#d14081', fontFamily: 'itaca' }}>Seguimiento</Typography>
              </div>
              <Box sx={{ textAlign: { xs: 'left', sm: 'right' } }} className="col-xs-4 col-sm-6">
                {/* <span className="breadcrum"> */}
                <Link fontSize='12px' underline='none' component='button' onClick={() => { OnClickReturn(); navigate('/Seguimiento'); }} sx={{ color: 'black' }} >Seguimiento &gt; </Link>
                <Link underline='none' component='button' onClick={() => { OnClickReturn(); navigate('/Seguimiento'); }}><font className="breadcrum_active">Recolección</font></Link>
                {/* </span> */}
              </Box>
            </div>
            <div className="row ">
              <div className="col-md-4">
                <Typography variant='h5' sx={{ marginBottom: '24px', fontSize: '24px', fontWeight: '400 !important' }} className="color_ros">Recolección</Typography>
              </div>
              <div className="col-md-8">
                <span className="parrafo_der">Estaremos actualizando la información conforme los eventos se programen.</span>
              </div>
            </div>
            <div className="row" style={{ marginTop: '24px' }}>
              {
                DataOperaciones === 0
                  ? <h5>Sin información por el momento</h5>
                  :
                  <div style={{ backgroundColor: 'white' }}  >
                    <div ref={RefTablaSeguimiento}>
                      <Box sx={{ width: 'auto', margin: { sm: '0 15%', xs: '0% 0%' } }}>
                        <HorizontalLinearStepper VisualOnly={true} Steps={ListOperaciones.map(item => ({ Label: item.Label }))} NoLabel={false} ActiveStep={DataOperaciones.length - 1} />
                      </Box>
                    </div>
                    <div className="col-md-12 mt-4 tabla_scroll" style={{ textAlign: 'center' }} >
                      <TablaMui Headers={[
                        { id: 0, name: 'Fecha / Hora', field: 'fecha_inicio', visibility: true },
                        { id: 1, name: 'Accion', field: 'tarea_Nombre', visibility: true },
                        { id: 2, name: 'Descripcion', field: 'notas', visibility: true }
                      ]}
                        Datas={DataOperaciones} Last={ListOperaciones.at(-1).Label} />
                    </div>
                  </div>
              }
              <div className="col-md-12  text-right  mt-4" style={{ display: 'flex', justifyContent: 'right' }}>
                <StyleDragArea >
                  <button onClick={ShowModalCompartirSeguimiento} className="btn  boton_amarillo">Compartir seguimiento</button>
                </StyleDragArea>
              </div>
            </div>
          </div>
          <div className="col lateral">
            <div className="center-block justify-content-center mt-5">
              {
                DataCaratula.Imagen === ''
                  ? <></>
                  : <img style={{ objectFit: 'cover', aspectRatio: '1' }} src={DataCaratula.Imagen} alt='foto finado' className="img1 mx-auto d-block foto" />
              }
            </div>
            <div className="col mt-2 texto_foto" style={{}}>
              <span className="nombre_foto">{capitalizeEachWord(DataSeguimiento.finado.nombreCompleto)}</span><br />
              <span className="fecha_foto">{DataCaratula.Nacimiento} - {DataCaratula.Defuncion}</span>
            </div>
            <div className="col mt-4 texto_foto">
              <div>
                <Link sx={{ '&[disabled]': { color: 'grey', pointerEvents: 'none' }, fontSize: '19px', fontWeight: '700 !important' }} disabled={DataConmemoracion === '' ? true : false} component='button' onClick={() => (handleClick('/Conmemoraciones/' + DataConmemoracion.folio))} className="conmemoracion_seg">Conmemoración</Link>
              </div>
              <div className="mt-2 mb-5">
                <StyleDragArea >
                  <Button disabled={DataConmemoracion === '' ? true : false} onClick={ShowModalCompartir} sx={{ textTransform: 'none', fontSize: '19px !important', fontWeight: '600 !important' }} type="button" className="btn btn-block btn_consultar" endIcon={<ScreenShareOutlinedIcon />}>Compartir</Button>
                </StyleDragArea>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal de compartir */}
      {
        ModalCompartir === true
          ?
          <ModalBaseMui Title='' Open={ModalCompartir} FullScreen={false} OnClickClose={ShowModalCompartir} FullWidth={true} MaxWidth={DataConmemoracion !== '' ? '290px' : '290px'}
            ContentModal={
              <div className="modal-body pt-0">
                {
                  DataConmemoracion !== ''
                    ?
                    <>
                      <div className="center-block justify-content-center ">
                        <img style={{ objectFit: 'cover' }} alt='foto conmemoracion' src={DataCaratula.Imagen} className="img1 mx-auto d-block fotocompartir" />
                      </div>
                      <p className="textofolio">
                        Comparte esta Conmemoración de {capitalizeEachWord(DataSeguimiento.finado.nombreCompleto)}
                      </p>
                    </>
                    :
                    <>
                      <h5 className="color_ros modal-title text-center pb-3" id="exampleModalLabel">Compartir conmemoración</h5>
                      <p className="textofolio">
                        Te invito a conmemorar la vida de ({capitalizeEachWord(DataSeguimiento.finado.nombreCompleto)}), <br /> y generar una memoria en su honor en
                      </p>
                    </>
                }
                <div style={{ padding: '0 16px', width: '100%' }}>
                  <SelectCompartirRedes Host={Host + 'Conmemoraciones/' + DataConmemoracion.folio} OnClickCompartirSeguimiento={CompartirSeguimiento} />
                </div>

              </div>
            }
          />
          :
          <></>
      }

      {/* Modal de compartir seguimiento */}
      {
        ImagenSeguimiento !== ''
          ?
          <ModalBaseMui Title='' Open={ModalCompartirSeguimiento} FullScreen={false} OnClickClose={() => (setModalCompartirSeguimiento(!ModalCompartirSeguimiento))} FullWidth={true} MaxWidth='700px'
            ContentModal={
              <div className="modal-body pt-0">
                <img alt='foto seguimiento' style={{ margin: '5% 0% 5% 0%' }} src={URL.createObjectURL(ImagenSeguimiento)} width='100%' />
                <div style={{ padding: '0 16px', width: '100%' }}>
                  <SelectCompartirRedes Host={Host + 'Seguimiento/' + DataForm.Folio + '/' + DataForm.ApPaterno} OnClickCompartirSeguimiento={CompartirSeguimiento} Url={URL.createObjectURL(ImagenSeguimiento)} />
                </div>

              </div>
            }
          />
          : <></>
      }


    </section>
  )
}

export default SeguimientoDetalle
