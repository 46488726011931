import Conmemoraciones from "../pages/Conmemoraciones";
import Home from "../pages/Home";
import SetsFunerarios from "../pages/Sets_funerarios";
import PlanesFuturos from "../pages/Planes_futuros";
import Seguimiento from "../pages/Seguimiento";



const routes = 
[
    {name:'COLUMBA',path:'/',component:Home},
    {name:'SETS FUNERARIOS',path:'/SetsFunerarios',component:SetsFunerarios},
    {name:'PLANES A FUTURO',path:'/PlanesFuturo',component:PlanesFuturos},
    {name:'CONMEMORACIÓN',path:'/Conmemoraciones',component:Conmemoraciones},
    {name:'SEGUIMIENTO',path:'/Seguimiento',component:Seguimiento},
]

export default routes