import React, { Component } from "react";
// -----------Importar Componentes de Terceros------------------
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

class PlanesFuturos extends Component {
  state = {
    Compartir: false,
    Parametro: '',
    Buscar: '',
    Token: '',
    ShowConsultaFolio: false,
    DataConmemoracion: '',
    InstrumentalFile: '',
    ImagenCropMemoria: '',
    DataNuevaMemoria: '',
    ListCatalogo: { 0: { item: 'Mensaje', itemV: 'VideoMemoriaTexto' }, 1: { item: 'Fotografia', itemV: 'VideoMemoriaFotografia' }, 2: { item: 'Video', itemV: 'VideoMemoriaVideo' } },
    ActiveStep: 0,
    ActiveStepMemoria: 0,
    validated: true,
    OpenModal: false,
    OpenConmemoracion: false,
    OpenModalCompartir: false,
    OpenMemoria: false,
    NuevaMemoria: false,
    MensajePersonalizado: false,
    MensajePersonalizadoMemoria: false,
    ValidatePedidoClienteFolio: false,
    Steps: [],
    result: '',
    data: [],
    form: {
      picture: '',
      mensaje: '',
      mensajepersonalizado: '',
      PedidoClienteFolio: '',
      VideoConmemoracion: '',
    },
    formMemoria: {
      ImagenCropMemoria: '',
      Tipo: '',
      Nombre: '',
      Telefono: '',
      Email: '',
      Mensaje: '',
      mensajepersonalizado: '',
      MensajeValidate: false,
      Video: '',
      VideoCropMemoria: '',
      Fotografia: '',
      AspectRation: '1',
      VideoMemoriaFinal: '',
      ImagenMemoriaFinal: '',
    },
    DataFinado: '',
    formCompartir: {
      Url: '',
      SelectCompartir: '',
    },
    Error: '',
  }


  render() {
    return (
     <Box  sx={{margin:{xs:'100% 0%', sm:'20% 0%'}}}> 
      <Container sx={{padding:'0px !important', maxWidth:'1080px !important'}}>

        <Typography color='#D14081 !important' textAlign='center' variant="h1" fontSize='42px'>
          Disponible proximamente
        </Typography>
     
      </Container>
       </Box>
    );
  }
}

export default PlanesFuturos;

