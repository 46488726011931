import React from "react"

// -----------Importar Componentes de Terceros------------------
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


const Buttonh = ({FontWeight, FontSize, Text, Type, OnClick, Size, Icon, Color, BorderColor,ColorHover, Textcolor, TextcolorHover, Disabled }) =>

(
    <Box sx={{width:Size}}>
        <Button  onClick={OnClick} type={Type} disabled={Disabled} variant='contained'  disableElevation sx={{':disabled':{padding:'8px 16px'},  border:Disabled===true?'none':BorderColor, textTransform:'none', fontFamily: 'Itaca ', height: 'auto', '&:hover': {   border:BorderColor,backgroundColor: ColorHover, color: TextcolorHover }, background: Color, color: Textcolor, width: '100%', fontWeight: 'bold', borderRadius: '5px' }}>
           <Typography fontWeight={FontWeight} fontSize={FontSize}>{Text}</Typography> 
        </Button>
    </Box>
);

export default Buttonh