//-------------Importar librerias----------------------------
import * as React from 'react';
import { styled } from '@mui/material/styles';

// -----------Importar Componentes de Terceros---------------------
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

// ------------------------Valores Constantes----------------------------
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f23e84',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const TablaMui = ({ Headers, Datas, Ref,Last}) => {
  return (
    <Box sx={{ overflow: "auto" }}>
    <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
      <TableContainer ref={Ref} sx={{border:'solid 2px #D14081', borderRadius: '10px' }} component={Paper}>
      <Table sx={{  borderRadius: '50px'}} aria-label="customized table">
        <TableHead>
          <TableRow >

            {Headers.map((Header) => (
              <StyledTableCell sx={{fontSize:{xs:'12px',sm:'15px'}, fontWeight:'700 !important'}} hidden={!Header.visibility} key={Header.id} align="left">{Header.name}</StyledTableCell  >
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {
            Datas.map((Data, index) => (
              <TableRow sx={{ backgroundColor: Data.tarea_Nombre === Last ? '#fffbcb' : index % 2 === 0 ? 'white' : '#ffdae7' }} key={index}>
                {
                  Headers.map((Header) => (
                    <TableCell sx={{fontSize:{xs:'10px',sm:'12px'}, color: Header.name === 'Accion' ? '#fb86b2' : '#404040' }} hidden={!Header.visibility} key={Header.id} align="left">{Data[Header.field]}</TableCell>
                  ))
                }
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
      </Box></Box>
    
  )
}

// #fffbcb #f5598e

export default TablaMui;