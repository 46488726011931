// -----------Importar Componentes de Terceros------------------
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ImageListItem from '@mui/material/ImageListItem';
import Grid from '@mui/material/Unstable_Grid2';

// -----------Importar Componentes Personalizados------------------
import ConmemoracionesHombre from '../../assets/Imagenes/Mask group hombre.png'
import ConmemoracionesMujer from '../../assets/Imagenes/Mask group mujer.png'

const TarjetaMensaje = ({ Nombre, Mensaje, Sexo, Width, ElementRef, OnLoad, FontSize }) => {
    return (
        <div style={{width: '100%', padding: '0% 0% 0% 0%' }} ref={ElementRef}>
        <Box sx={{ flexGrow: 1, background: '#FFFCEC' }}>
            <Grid container>
                <Grid xs={12} height='100%'>
                    <Box sx={{}}>
                        <ImageListItem sx={{}} >
                            <img onLoad={OnLoad}  alt='SexoConmemocion' src={Sexo === 'H' ? ConmemoracionesHombre : ConmemoracionesMujer}>
                            </img>
                            <Box sx={{ position: 'absolute', width: '100%', zIndex: 21, top: '10%'  }}>
                                <Grid container >
                                    <Grid  sx={{marginBottom:'7%'}}  xs={12}>
                                        <Typography sx={{ marginLeft: '2%', marginRight:'2%', color: '#717171', fontFamily: 'Itaca ', fontSize: FontSize + 'px', textAlign: 'center', }}>
                                            {Mensaje}.
                                        </Typography>
                                    </Grid>
                                    <Grid xs={12}>
                                        <Typography sx={{ textAlign: 'right', marginRight:'30%', color: '#717171', fontFamily: 'Itaca ', fontSize: FontSize + 'px', fontWeight: 'bold !important' }}>
                                            {Nombre}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </ImageListItem>
                    </Box>
                </Grid>
            </Grid>
        </Box>
     </div>


    )
}

export default TarjetaMensaje;